import basekick from "./basekick";

const baseFontParameters = {
    typeSizeModifier: 1,
    gridRowHeight: 1.4,
    descenderHeightScale: 0.12,
    capHeight: 0.71,
    // old numbers from David, let's keep these around for now
    // descenderHeightScale: 0.195,
    // capHeight: 0.67,
};

export default function makeFont(fontSize) {
    const fontStyles = basekick({
        baseFontSize: fontSize,
        typeRowSpan: fontSize,
        ...baseFontParameters,
    });
    return fontStyles;
}
