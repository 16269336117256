import {gql} from "@apollo/client";
import anyUserFragment from "@unibuddy/machop/Auth/fragments/anyUserFragment";
import applicantFragment from "@unibuddy/machop/Auth/fragments/applicantFragment";

const updateMarketplaceApplicantMutation = gql`
    mutation UpdateMarketplaceApplicantMutation($marketplace: ApplicantMarketplaceInput!) {
        updateMarketplaceApplicant(marketplace: $marketplace) {
            me {
                anyUser {
                    ...AnyUserFull
                }
                applicant {
                    ...ApplicantFull
                }
            }
        }
    }
    ${anyUserFragment}
    ${applicantFragment}
`;

export default updateMarketplaceApplicantMutation;
