import React from "react";
import PropTypes from "prop-types";
import ColorChecker from "./Color";
import s from "./ColorPanel.pcss";

function ColorPanel({children, title, backgroundColor}) {
    const color = getAccessibleColor(backgroundColor);

    return (
        <div className={s.panel} style={{backgroundColor}}>
            <div className={s.title} style={{color}}>
                {title}
            </div>
            <div className={s.panelBody}>{children}</div>
        </div>
    );
}

ColorPanel.propTypes = {
    title: PropTypes.element,
    backgroundColor: PropTypes.string,
};

export default ColorPanel;

export function getAccessibleColor(backgroundColor = "", fontSize = 14) {
    const cc = new ColorChecker();
    const isValidColor = backgroundColor.startsWith("#") && backgroundColor.length === 7;
    let color;
    try {
        if (isValidColor) {
            if (cc.isLevelAA("#ffffff", backgroundColor, fontSize)) {
                color = "#ffffff";
            } else if (cc.isLevelAA("#000000", backgroundColor, fontSize)) {
                color = "#181d22";
            }
        }
    } catch (error) {
        // pass
    }
    return color;
}
