import React, {useEffect} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router";
import get from "lodash/get";
import useAnalytics from "@unibuddy/machop/AnalyticsNew/hooks/useAnalytics";
import {productSpec} from "@unibuddy/machop/AnalyticsNew/constants/productSpecification";
import ErrorBoundary from "@unibuddy/machop/General/components/ErrorBoundary/ErrorBoundary";
import AnalyticsValue from "@unibuddy/machop/AnalyticsNew/AnalyticsValue/AnalyticsValue";
import SuspenseLoader from "@unibuddy/machop/Shared/components/SuspenseLoader/SuspenseLoader";
import BuddiesRoutes from "./Buddies/components/BuddiesRoutes/BuddiesRoutes";
import ProtectedRoute from "./ProtectedRoute";
import s from "./Buddies/components/Buddies/Buddies.pcss";
import Navigation from "./Navigation/components/Navigation/Navigation";
import {useAuth} from "./Auth/components/AuthProvider/AuthProvider";
import FilterDependentComponent from "./shared/components/FilterDependentComponent/FilterDependentComponent";
import PoweredByUnibuddy from "./shared/components/PoweredByUnibuddy/PoweredByUnibuddy";
import SSOAuth from "./Auth/containers/SSOAuth/SSOAuth";
import SSOCallBack from "./Auth/components/SSOCallBack/SSOCallBack";
import {useMarketplaceConfig} from "./MarketplaceConfigProvider";
import Logout from "./Auth/components/Logout/Logout";

const Auth = React.lazy(() =>
    import(/* webpackChunkName: "auth" */ "./Auth/containers/Auth/Auth"),
);
const InboxRoutes = React.lazy(() =>
    import(/* webpackChunkName: "inbox" */ "./Inbox/InboxRoutes"),
);
const Settings = React.lazy(() =>
    import(/* webpackChunkName: "settings" */ "./Settings/components/Settings/Settings"),
);

export const hideNav = () => {
    if (window.location.href.includes('/callback')) {
        return true;
    }
    return false;
};

export default withRouter(function AppRoutes(props) {
    const {events} = useAnalytics();
    const {authState} = useAuth();
    const {
        marketplace: {isSsoEnabled = false},
    } = useMarketplaceConfig();
    useEffect(() => {
        events.productOpened({name: productSpec.discover.NAME});
        window.addEventListener(
            "message",
            (e) => {
              if (e && get(e, "data.type") === "productVisible") {
                events.productVisible({name: productSpec.discover.NAME});
              }
            },
          );
    }, []); // eslint-disable-line

    return (
        <div className="App__body">
            <FilterDependentComponent filterKey="showBuddiesInCarousel">
                <div className={s.topBarCarousel}>
                    {!hideNav() && <Navigation user={get(authState, "me.anyUser")} />}
                    <div className={s.poweredByUnibuddyWrapper}>
                        <PoweredByUnibuddy />
                    </div>
                </div>
            </FilterDependentComponent>
            <SuspenseLoader>
                <ErrorBoundary key={props.location.pathname}>
                    <AnalyticsValue
                        name={productSpec.discover.properties.DISCOVER_SLUG}
                        value={get(props, "match.params.marketplaceSlug")}
                    />
                    <Switch>
                        <Route
                            path={`${props.match.path}/auth`}
                            render={({match, location}) => {
                                if (isSsoEnabled) {
                                    return <SSOAuth match={match} location={location} />;
                                }
                                    return <Auth match={match} location={location} />;
                            }}
                        />

                        <Route
                            path={`${props.match.path}/callback`}
                            component={SSOCallBack}
                        />

                        <Route
                            path={`${props.match.path}/buddies`}
                            component={BuddiesRoutes}
                        />

                        <ProtectedRoute
                            path={`${props.match.path}/settings`}
                            component={Settings}
                        />

                        <ProtectedRoute
                            path={`${props.match.path}/inbox`}
                            component={InboxRoutes}
                        />

                        <Route path={`${props.match.path}/logout`} component={Logout} />

                        <Route
                            path={props.match.path}
                            render={() => (
                                <Redirect to={`${props.match.url}/buddies`} />
                            )}
                        />

                    </Switch>

                </ErrorBoundary>
            </SuspenseLoader>
        </div>
    );
});
