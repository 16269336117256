import createPropType from "./createResponsivePropType";

const spacePropType = createPropType([
    "none",
    "xxsmall",
    "xsmall",
    "small",
    "medium",
    "large",
    "xlarge",
    "xxlarge",
]);

export default spacePropType;
