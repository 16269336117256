import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import VisuallyHidden from "../../../A11y/components/VisuallyHidden/VisuallyHidden";
import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";
import s from "./LoadingButton.pcss";

const spinnerColor = {
    success: "white",
    primary: "white",
    danger: "white",
};

const LoadingButton = React.forwardRef(({children, loading, className, ...props}, ref) => (
    <Button
        ref={ref}
        {...props}
        className={classNames(className, s.button)}
        aria-disabled={props.disabled}
    >
        <span
            aria-hidden={loading}
            className={classNames(s.innerText, {
                [s.innerTextLoading]: loading,
            })}
            data-test-id="loading-button"
        >
            {children}
        </span>
        <span
            aria-hidden={!loading}
            className={classNames(s.loader, {
                [s.loaderVisible]: loading,
            })}
        >
            <VisuallyHidden> loading</VisuallyHidden>
            <Spinner aria-hidden color={spinnerColor[props.color]} />
        </span>
    </Button>
));

LoadingButton.propTypes = {
    loading: PropTypes.bool,
};

LoadingButton.defaultProps = {
    loading: false,
};

export default LoadingButton;
