import React, {useReducer, useRef, useEffect, useState} from "react";
import PropTypes from "prop-types";
import findIndex from "lodash/findIndex";
import classNames from "classnames";
import TextInput from "@unibuddy/machop/Experimental/Forms/components/TextInput/TextInput";
import {LIST_ITEM_HEIGHT} from "./scroll";
import reducer from "./reducer";
import createDispatchers from "./actionCreators";
import {useScrollSelectedInToView, useScrollHighlightedInToView, useFocusOnChange} from "./effects";
import s from "./ListFilter.pcss";

export const ARROW_UP = 38;
export const ARROW_DOWN = 40;
export const ENTER = 13;
export const RETURN = 8;

ListFilter.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ),
    filter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
    setFilter: PropTypes.func,
    placeholder: PropTypes.string,
    noDropdownOnEmptyText: PropTypes.bool,
};

ListFilter.defaultProps = {
    options: [],
    filter: null,
    setFilter: () => {},
    placeholder: "",
    noDropdownOnEmptyText: false,
};

export default function ListFilter({
    id,
    options,
    filter,
    setFilter,
    placeholder,
    noDropdownOnEmptyText,
    isOpen,
}) {
    const isFirstRun = useRef(true);
    const [_listId] = useState(`${Date.now()}-${filter ? filter.name : ""}`);
    const [{text, highlighted, touched, filteredOptions, realHighlighted}, dispatch] = useReducer(
        reducer,
        {
            options,
            text: filter ? filter.name : "",
            highlighted: filter ? findIndex(options, filter) : -1,
            touched: false,
            filteredOptions: options,
            realHighlighted: filter ? findIndex(options, filter) : -1,
        },
    );

    useEffect(
        () => {
            if (isFirstRun.current) {
                isFirstRun.current = false;
                return;
            }
            dispatch({type: "initialise", payload: {options, filter}});
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options],
    );

    const [setTouched, selectFilter, deselectFilter, setHighlighted, setText] = createDispatchers(
        dispatch,
        setFilter,
    );

    const listRef = useRef(null);
    const inputRef = useRef(null);

    useFocusOnChange(inputRef, isOpen);
    useScrollSelectedInToView(listRef, filter, filteredOptions);
    useScrollHighlightedInToView(listRef, realHighlighted);

    return (
        <div className={s.container} role="combobox" aria-expanded="true">
            <TextInput
                aria-owns={_listId}
                aria-activedescendant={filter ? filter.id : undefined}
                id={id}
                autoComplete="off"
                aria-autocomplete="list"
                data-test-id="filter-input"
                ref={inputRef}
                placeholder={placeholder}
                value={text}
                className={s.input}
                onChange={event => {
                    if (event.target.value.match(/[.*+?^${}()|[\]\\]/g)) {
                        return;
                    }
                    setText(event.target.value);
                }}
                onKeyDown={event => {
                    // we have to destructure here, because
                    // event.preventDefault() throws when desctructured
                    const {key, keyCode} = event;

                    if (keyCode === ARROW_UP && realHighlighted > -1) {
                        const firstIsNotSelected = realHighlighted - 1 > -1;
                        if (firstIsNotSelected) {
                            event.preventDefault();
                            return setHighlighted(realHighlighted - 1);
                        }
                        return undefined;
                    }
                    if (keyCode === ARROW_DOWN && realHighlighted < filteredOptions.length) {
                        const lastIsNotSelected = realHighlighted + 1 < filteredOptions.length;
                        if (lastIsNotSelected) {
                            event.preventDefault();
                            return setHighlighted(realHighlighted + 1);
                        }
                        return undefined;
                    }

                    const currentlyHighlightedItemIsSelected =
                        filter && realHighlighted === findIndex(filteredOptions, filter);

                    if (keyCode === ENTER && currentlyHighlightedItemIsSelected) {
                        return deselectFilter();
                    }

                    if (keyCode === ENTER && realHighlighted > -1) {
                        return selectFilter(filteredOptions[realHighlighted]);
                    }

                    if (!touched) {
                        const isCharacterKey = key && key.length === 1;
                        if (isCharacterKey) {
                            setTouched(true);
                        }
                    }

                    if (keyCode === RETURN) {
                        if (!touched && inputRef.current) {
                            if (
                                inputRef.current.selectionStart === 0 ||
                                inputRef.current.selectionStart === text.length
                            ) {
                                return deselectFilter();
                            }
                        }
                        setTouched(true);
                    }

                    return undefined;
                }}
            />
            <ul
                tabIndex="-1"
                id={_listId}
                className={s.list}
                ref={listRef}
                data-test-id="filter-list"
                role="listbox"
            >
                {(!noDropdownOnEmptyText || text) &&
                    filteredOptions.map((option, index) => {
                        const isSelected = filter && filter.id === option.id;
                        return (
                            <li className={s.listItem} key={option.id}>
                                <button
                                    id={option.id}
                                    aria-selected={isSelected}
                                    role="option"
                                    data-test-id="filter-list-item-button"
                                    style={{height: LIST_ITEM_HEIGHT}}
                                    tabIndex="-1"
                                    onClick={() => {
                                        if (isSelected) {
                                            deselectFilter();
                                        } else {
                                            selectFilter(option);
                                        }
                                    }}
                                    className={classNames(s.element, {
                                        [s.highlight]: highlighted === index,
                                        [s.selected]: isSelected,
                                    })}
                                >
                                    {option.name}
                                </button>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
}
