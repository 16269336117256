import React from "react";
import noop from "lodash/noop";
import {itemNames, pageNames} from "../constants/productSpecification";

const AnalyticsContext = React.createContext({
    events: {pageViewed: noop},
    trackEvent: noop,
    pageNames,
    itemNames,
});

export default AnalyticsContext;
