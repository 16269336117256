import React from "react";
import s from "./FilterIndicator.pcss";

export default function FilterIndicator({filters, totalMentors}) {
    const {country, subCategoryName, subjectName, universityName} = filters;
    let showIndicator = false;
    let subjectOrCategory = '';
    if (country || universityName || subCategoryName || subjectName) {
        showIndicator = true;
        if (subjectName) {
            subjectOrCategory = subjectName;
        }
        if (subCategoryName) {
            subjectOrCategory = subCategoryName;
        }
    }
    return (
        <div data-test-id="filter-indicator" className={s.title}>
            {showIndicator ? (
                <div>
                    We’ve matched you with{" "}
                    <span>{totalMentors === 1 ? 'a' : totalMentors}{" "}</span>
                    {totalMentors === 1 ? "student" : "students"}
                    {country ? (
                        <div style={{display: 'inline'}}>
                            {" "}from <span className={s.filter}>{country}</span>
                        </div>
                    ) : (
                        ""
                    )}{" "}
                    {subjectOrCategory ? (
                        <div style={{display: 'inline'}}>
                            {" "}studying{" "}
                            <span className={s.filter} style={{textTransform: 'capitalize'}}>
                                {subjectOrCategory}
                            </span>
                        </div>
                    ) : (
                        ""
                    )}
                    {universityName ? (
                        <div style={{display: 'inline'}}>
                            {" "}at{" "}
                            <span className={s.filter}>{universityName}</span>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
