import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./MentorPlaceholder.pcss";

const MentorPlaceholder = props => (
    <div
        data-test-id="mentor-placeholder"
        className={classNames(s.container, props.className, {[s.wide]: props.wide})}
    >
        <div className={classNames(s.animatedBackground, s.header)} />
        <div className={s.body}>
            {new Array(props.lines).fill(1).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={i}>
                    <div className={classNames(s.label)} />
                    <div className={classNames(s.text)} />
                </React.Fragment>
            ))}
        </div>
    </div>
);

MentorPlaceholder.propTypes = {
    className: PropTypes.string,
    wide: PropTypes.bool,
    lines: PropTypes.number,
};

MentorPlaceholder.defaultProps = {
    className: "",
    wide: false,
    lines: 5,
};

export default MentorPlaceholder;
