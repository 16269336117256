import React from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

const TextOuter = styled.span`
    display: block;
    padding-top: ${({fontStyles}) => fontStyles.cropFirstLine.paddingTop}px;
    color: ${({color}) => color};
    font-weight: ${({weight}) => weight};
    font-size: ${({fontStyles}) => fontStyles.base.fontSize};
    line-height: ${({fontStyles}) => fontStyles.base.lineHeight};
    transform: ${({fontStyles}) => fontStyles.baseline.transform};

    &:before {
        content: "";
        height: 0;
        display: block;
        margin-top: ${({fontStyles}) => fontStyles.cropFirstLine.before.marginTop}px;
    }

    ${({breakWords}) => {
        if (!breakWords) return "";
        return css`
            /* 
                We're trying to break long words and urls here 
                following this guide https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ 
            */
            overflow-wrap: break-word;
            word-wrap: break-word;

            -ms-word-break: break-all;
            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
            /* Adds a hyphen where the word breaks, if supported (No Blink) */
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
        `;
    }}

    ${({nowrap}) => nowrap && "white-space: nowrap"}
`;

const TextInner = styled.span`
    display: block;
    text-transform: ${({transform}) => transform};

    ${({truncate}) => {
        if (!truncate) return;

        return css`
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        `;
    }}
`;

/**
 * Do not use directly unless you know what you are doing.
 *
 * ./README.md
 *
 * @export
 * @returns
 */
export function __TextImplementation({fontStyles, transform, truncate, children, ...props}) {
    return (
        <TextOuter fontStyles={fontStyles} {...props}>
            <TextInner transform={transform} truncate={truncate}>
                {children}
            </TextInner>
        </TextOuter>
    );
}

__TextImplementation.defaultProps = {
    truncate: false,
    breakWords: true,
    transform: "none",
};

__TextImplementation.propTypes = {
    /* Most of the time you should be using the tone prop instead but this prop is available as a manual override. It supports the use of both brand color names such as "redCould" or "freshLeaves", and as the css color property "var(--theme-primary)" or "#ccc". */
    color: PropTypes.string,

    /* The CSS weight property */
    weight: PropTypes.string,

    /* It will truncate the text on a single line with an ellipsis */
    truncate: PropTypes.bool,

    /* CSS text-transform property */
    transform: PropTypes.string,

    /* Force-breaks long words and urls in to multiline text, with a hyphen at the breaking point. */
    breakWords: PropTypes.bool,

    /* Forces the text to stay on one line but multiline truncate it doesn't cut it off. */
    nowrap: PropTypes.bool,
};
