import React, {useState} from "react";
import classNames from "classnames";
import ButtonGroup from "@unibuddy/machop/Experimental/General/components/ButtonGroup/ButtonGroup";
import Button from "@unibuddy/machop/Experimental/General/components/Button/Button";
import s from "./FilterTabs.pcss";

export const Tabs = ({
    children,
    tabs,
    initialActiveIndex,
    activeIndex,
    className,
    hasSeparator = false,
}) => {
    const [_active, setActive] = useState(initialActiveIndex || 0);
    const active = activeIndex === undefined ? _active : activeIndex;
    const hasTabs = Array.isArray(tabs);

    return (
        <div className={classNames(s.container, className)}>
            {hasTabs && (
                <div className={s.tabs}>
                    <ButtonGroup round justified>
                        {tabs.map((tab, index) => (
                            <Button
                                data-test-id="tab-nav-btn"
                                className={s.button}
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                onClick={() => setActive(index)}
                                color={active === index ? "primary" : "default"}
                            >
                                {tab}
                            </Button>
                        ))}
                    </ButtonGroup>
                </div>
            )}
            {React.Children.map(children, (tab, index) =>
                React.cloneElement(tab, {
                    isVisible: active === index,
                    key: index,
                    className: classNames(tab.props.className, {
                        [s.hasTabs]: hasTabs,
                        [s.hasSeparator]: hasSeparator,
                    }),
                }),
            )}
        </div>
    );
};

export const Tab = ({children, isVisible, className}) => {
    return (
        <div
            data-test-id="tab"
            hidden={!isVisible}
            className={classNames(s.tab, className, {
                [s.hidden]: !isVisible,
            })}
        >
            {children}
        </div>
    );
};
