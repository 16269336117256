/**
 * This has been c/p from braid design system from seek-oss
 *
 * @export
 * @param {*} {
 *     typeSizeModifier,
 *     baseFontSize,
 *     descenderHeightScale,
 *     typeRowSpan,
 *     gridRowHeight,
 *     capHeight,
 * }
 * @returns
 */
export default function basekick({
    typeSizeModifier,
    baseFontSize,
    descenderHeightScale,
    typeRowSpan,
    gridRowHeight,
    capHeight,
}) {
    const fontSize = typeSizeModifier * baseFontSize;

    const calculateTypeOffset = lineHeight => {
        const lineHeightScale = lineHeight / fontSize;
        return (lineHeightScale - 1) / 2 + descenderHeightScale;
    };

    const lineHeight = typeRowSpan * gridRowHeight;
    const typeOffset = calculateTypeOffset(lineHeight);

    const topSpace = lineHeight - capHeight * fontSize;
    const heightCorrection = topSpace > gridRowHeight ? topSpace - (topSpace % gridRowHeight) : 0;

    const preventCollapse = 1;

    return {
        base: {
            fontSize: `${fontSize}px`,
            lineHeight: `${lineHeight}px`,
        },
        baseline: {
            transform: `translateY(${typeOffset}em)`,
        },
        cropFirstLine: {
            paddingTop: preventCollapse,
            before: {
                content: "''",
                marginTop: -(heightCorrection + preventCollapse),
                display: "block",
                height: 0,
            },
        },
    };
}
