import normaliseResponsiveProp from "./normaliseResponsiveProp";

export default function mapResponsiveProp(value, map) {
    if (!value) return value;
    return normaliseResponsiveProp(value).map(val => map[val]);
}

export function mapResponsivePropAndInverse(value, map) {
    if (!value) return value;
    return normaliseResponsiveProp(value).map(val => `-${map[val]}`);
}
