import {gql} from "@apollo/client";

const degreeCategories = gql`
    query DegreeCategoriesQuery {
        degreeCategories(hierarchyRank: 2, categoryType: "hecos") {
            name
            code
            hierarchyRank
            categoryType
            id
        }
    }
`;

export default degreeCategories;
