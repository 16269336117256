import React from "react";

/**
 * Scrolls the widget contents to the top of the widget (a scroll within the widget)
 */
function useScrollWindowToTop() {
    React.useEffect(() => {
        const root = document.querySelector(".App__main");
        if (root) {
            // A: why are we doing this? well this will break in tests.
            // B: why are we not scrolling window? because the window doesnt have a height.
            root.scrollTop = 0;
        }
    }, []);
}

export default useScrollWindowToTop;
