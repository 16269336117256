import React from "react";
import PropTypes from "prop-types";
import ReactSlick from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import "./Carousel.pcss";
import {NextArrow, PrevArrow} from "./Arrows";

const Carousel = props => (
    <ReactSlick
        dots={props.dots}
        rows={props.rows}
        infinite={props.infinite}
        speed={props.speed}
        slidesToShow={props.slidesToShow}
        slidesToScroll={props.slidesToScroll}
        easing="ease"
        responsive={props.responsive}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        className={props.className}
    >
        {props.children}
    </ReactSlick>
);
Carousel.propTypes = {
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
    speed: PropTypes.number,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
    responsive: PropTypes.arrayOf(
        PropTypes.shape({
            breakpoint: PropTypes.number.isRequired,
            settings: PropTypes.shape({}).isRequired,
        }),
    ),
};

Carousel.defaultProps = {
    dots: true,
    rows: 1,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export default Carousel;
