import {gql} from "@apollo/client";
import applicantFragment from "../fragments/applicantFragment";
import anyUserFragment from "../fragments/anyUserFragment";
import universityUserFragment from "../fragments/universityUserFragment";
import mentorFragment from "../fragments/mentorFragment";

const meQuery = gql`
    query MeQuery {
        me {
            anyUser {
                ...AnyUserFull
            }
            mentor {
                ...MentorFull
            }
            applicant {
                ...ApplicantFull
            }
            universityUser {
                ...UniversityUserFull
                groups {
                    type
                }
            }
        }
    }
    ${applicantFragment}
    ${anyUserFragment}
    ${universityUserFragment}
    ${mentorFragment}
`;

export default meQuery;
