import React from "react";
import PropTypes from "prop-types";
import DynamicImage from "../../../General/components/DynamicImage/DynamicImage";

export function getMentorDynamicImageSrc(imageUrl) {
    let profilePhoto = "";
    if (typeof imageUrl === "string") {
        const urlParts = imageUrl.split("/");
        profilePhoto = urlParts[urlParts.length - 1];
    }
    return profilePhoto;
}

export default function MentorDynamicImage({imageUrl, width, height, ...props}) {
    const profilePhoto = getMentorDynamicImageSrc(imageUrl);

    return (
        <DynamicImage
            {...props}
            options={{
                width,
                height,
                smart: true,
            }}
            src={profilePhoto}
        />
    );
}

MentorDynamicImage.propTypes = {
    imageUrl: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

MentorDynamicImage.defaultProps = {
    width: 35,
    height: 35,
};
