import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Button from "../Button/Button";
import buttonStyles from "../Button/Button.pcss";

const ButtonLink = React.forwardRef((allProps, ref) => {
    if (allProps.disabled === true) {
        const {to, ...buttonProps} = allProps;
        return <Button ref={ref} {...buttonProps} />;
    }

    const {
        children,
        color,
        block,
        round,
        iconOnly,
        elevation,
        link,
        inversed,
        clear,
        size,
        to,
        external,
        target,
        rel,
        ...props
    } = allProps;

    const buttonClasses = classNames(buttonStyles.button, props.className, {
        [buttonStyles[color]]: true,
        [buttonStyles[size]]: true,
        [buttonStyles.block]: block,
        [buttonStyles.clear]: clear,
        [buttonStyles.round]: round,
        [buttonStyles.link]: link,
        [buttonStyles.iconOnly]: iconOnly,
        [buttonStyles.elevation1]: elevation === 1,
        [buttonStyles.inversed]: inversed,
    });

    return external ? (
        <a {...props} href={to} className={buttonClasses} target={target} rel={rel}>
            {children}
        </a>
    ) : (
        <Link {...props} innerRef={ref} to={to} className={buttonClasses}>
            {children}
        </Link>
    );
});

ButtonLink.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    id: PropTypes.string,
    color: PropTypes.oneOf(["default", "success", "primary", "danger", "light"]),
    size: PropTypes.oneOf(["md", "sm", "lg"]),
    round: PropTypes.bool,
    iconOnly: PropTypes.bool,
    style: PropTypes.object,
    elevation: PropTypes.number,
    className: PropTypes.string,
    external: PropTypes.bool,
    target: PropTypes.string,
    rel: PropTypes.string,
};

ButtonLink.defaultProps = {
    id: null,
    color: "default",
    size: "md",
    round: false,
    iconOnly: false,
    style: null,
    elevation: 0,
    className: "",
    external: false,
    target: "_self",
    rel: "nofollow",
};

export default ButtonLink;
