import {gql, useQuery} from "@apollo/client";
import React from "react";
import {Route} from "react-router";
import get from "lodash/get";
import {GlobalCssVariable} from "@unibuddy/machop/Styling/hocs/withCSSVariables";
import {DEFAULT_THEME_VALUE} from "@unibuddy/machop/Styling/hocs/withTheme";

export const MarketplaceConfigContext = React.createContext();

export const useMarketplaceConfig = () => {
    return React.useContext(MarketplaceConfigContext);
};

export const GET_MARKETPLACE_QUERY = gql`
    query Marketplace($slug: String) {
        marketplace(slug: $slug) {
            id
            name
            slug
            colour
            created
            active
            archived
            allowUniversityProspectDataAccess
            widgetUrl
            widgetTitle
            widgetDescription
            customBuddyWord
            customFilters {
                name
                filterSlug
            }
            personalDataApiEnabled
            marketplaceMarketingConsentCollectionEnabled
            marketplaceMarketingConsentMessage
            privacyPolicyUrl
            universityMarketingConsentEnabled
            unibuddyMarketingConsentEnabled
            isSsoEnabled
            faAppId
        }
    }
`;

const MarketplaceConfig = ({slug, children, initialValues}) => {
    if (Object.keys(initialValues).length === 0) {
        initialValues = {
            slug,
            isSsoEnabled: false,
        };
    }
    const {loading, data} = useQuery(GET_MARKETPLACE_QUERY, {
        variables: {
            slug,
        },
    });
    return (
        <MarketplaceConfigContext.Provider
            value={{
                marketplace: get(data, "marketplace", {slug, ...initialValues}),
                loading,
            }}
        >
            <GlobalCssVariable
                name="--theme-primary"
                value={`#${get(
                    data,
                    "marketplace.colour",
                    DEFAULT_THEME_VALUE,
                )}`}
            />
            {children}
        </MarketplaceConfigContext.Provider>
    );
};

export default function MarketplaceConfigProvider({children, initialValues = {}}) {
    return (
        <Route
            path="/:marketplaceSlug"
            render={({match}) => {
                const marketplaceSlug = match.params.marketplaceSlug;
                return (
                    <MarketplaceConfig slug={marketplaceSlug} initialValues={initialValues}>
                        {children}
                    </MarketplaceConfig>
                );
            }}
        />
    );
}
