import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ChevronDownIcon from "../../../Icons/ChevronDownIcon";
import s from "./Collapsible.pcss";
import useFocusVisible from "../../../Utils/components/FocusVisibleManager/useFocusVisible";

export const Trigger = ({children, onClick, isExpanded, className, ...props}) => {
    const {onBlur, onFocus, focusVisible} = useFocusVisible();
    return (
        <button
            type="button"
            {...props}
            aria-expanded={isExpanded}
            onFocus={onFocus}
            onBlur={onBlur}
            className={classNames(s.button, className, {
                [s.focus]: focusVisible,
            })}
            onClick={onClick}
        >
            <div className={s.iconContainer}>
                <ChevronDownIcon
                    aria-hidden="true"
                    focusable="false"
                    className={classNames(s.icon, {
                        [s.open]: isExpanded,
                    })}
                />
            </div>

            {children}
        </button>
    );
};

export function CollapsibleSection({children, className, isExpanded}) {
    return (
        <section
            data-test-id="collabsible-section"
            className={`${s.body} ${className}`}
            hidden={!isExpanded}
        >
            {children}
        </section>
    );
}

export default function Collapsible({children, trigger}) {
    const [isExpanded, setExpanded] = useState(false);

    return (
        <React.Fragment>
            <Trigger isExpanded={isExpanded} onClick={() => setExpanded(!isExpanded)}>
                {trigger}
            </Trigger>
            <CollapsibleSection isExpanded={isExpanded}>{children}</CollapsibleSection>
        </React.Fragment>
    );
}

Collapsible.propTypes = {
    trigger: PropTypes.string.isRequired,
};

Collapsible.defaultProps = {
    children: "",
};
