import {useQuery} from "@apollo/client";
import MarketplaceUnreadCountQuery from "../../queries/MarketplaceUnreadCountQuery";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";

export default function InboxUnreadCountQuery({render}) {
    const {marketplace} = useMarketplaceConfig();
    const {loading, error, data} = useQuery(MarketplaceUnreadCountQuery, {
        variables: {
            marketplaceSlug: marketplace.slug,
        },
        pollInterval: 60000,
    });

    if (loading || error) {
        return render({count: 0});
    }
    return render({count: data.marketplaceUnreadMessageCount});
}
