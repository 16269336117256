import {ApolloLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {createUploadLink} from "apollo-upload-client";
import get from "lodash/get";
import {v4 as uid} from "uuid";
import {AUTH_TRANSFER, IS_EMBEDDED} from "@unibuddy/machop/Utils/Constants";
import {clearAuth} from "../Auth/actions/authActions";
import {config} from "../ConfigManager/ConfigManager";
import {configureRequestContext} from "../General/components/ErrorReporting/errorReportingAdaptor";

export const createCustomFetch = metadata => {
    const customFetch = (uri, options) => {
        if (options.body instanceof FormData) return fetch(uri, options);
        const body = JSON.parse(options.body);
        const {operationName} = body;

        if (get(metadata, "app") === "mobile") {
            options.headers.client = metadata.app;
        }
        const paramsArray = [`opname=${encodeURIComponent(operationName)}`];

        /**
         * This is commented out for now because we don't want to leave variables in the log.
         * TODO: we'll come up with a whitelist strategy to see what can be useful.
         */
        // const {variable} = body;
        // Object.keys(variables || {}).forEach(key => {
        //     if (key === "password" || key === "email") {
        //         paramsArray.push(`${key}=***`);
        //     } else {
        //         paramsArray.push(`${key}=${encodeURIComponent(variables[key])}`);
        //     }
        // });

        const filteredVariablesString = paramsArray.join("&");
        return fetch(`${uri}?${filteredVariablesString}`, options);
    };
    return customFetch;
};

export default (store, uri = `${config.API_URL}/graphql`) => {
    const httpLink = createUploadLink({
        uri,
        fetch: createCustomFetch(),
        headers: {
            "keep-alive": "true",
        },
    });

    const middlewareLink = setContext(() => {
        const token = get(store.getState(), "authState.token", false);
        // generate unique request id and setting it as Sentry tag and attaching it to every request
        const requestId = uid();
        configureRequestContext(requestId);
        return {
            headers: {
                authorization: token ? `JWT ${token}` : null,
                "X-Request-Id": requestId,
            },
        };
    });

    const errorLink = onError(({networkError = {}}) => {
        if (networkError.statusCode === 401) {
            store.dispatch(clearAuth());

            // Refresh the page
            window.location = window.location;

            if (IS_EMBEDDED) {
                window.parent.postMessage(
                    {name: AUTH_TRANSFER, value: {token: false, me: false}},
                    HORSEA_URL,
                );
            }
        }
    });

    return ApolloLink.from([errorLink, middlewareLink, httpLink]);
};
