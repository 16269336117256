import {useEffect} from "react";

export const ESCAPE_KEY = 27;

export default function useOnEscape(handler, {root = document} = {}) {
    return useEffect(
        () => {
            if (!handler) return;

            const listener = event => {
                if (event.keyCode === ESCAPE_KEY) {
                    handler(event);
                }
            };
            root.addEventListener("keydown", listener);

            return () => root.removeEventListener("keydown", listener);
        },
        [handler, root],
    );
}
