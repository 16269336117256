import React from "react";
import PropTypes from "prop-types";
import Messenger from "../../Messenger";
import {useAuth} from "../../../Auth/components/AuthProvider/AuthProvider";

const initialState = {status: Messenger.status.INITIALIZED};
const Context = React.createContext(initialState);

/**
 * The purpose of this hook is to provide messenger functionality
 * from MessengerProvider, and the reason for the abstraction
 * is so we can change our implementation of how the messenger
 * is retrieved in the future in once place.
 *
 * @export
 * @returns
 */
export function useMessenger() {
    const messenger = React.useContext(Context);

    return messenger;
}

/**
 * MessengerProvider provides connection status and ability for children
 * subscribe to messges on a chat group using the React context api. We
 * are using the context here so we can use basic dependecy injection pattern
 * and change the implementation later to a different websocket provider or
 * mock websockets in tests. Currently only connection status and listenChatGroup
 * functionality is required by Messenger consumers but we can add more as we need.
 *
 * @param {*} {children, pusher}
 * @returns
 */
function MessengerProvider({children, pusher}) {
    const {authState} = useAuth();
    const token = authState && authState.accessToken;
    const [value, setValue] = React.useState(initialState);

    React.useEffect(
        () => {
            if (token) {
                const messenger = new Messenger(token, pusher);

                messenger.onStatusChange(() => {
                    setValue({
                        listenChatGroup: messenger.listenChatGroup,
                        isConnected: messenger.isConnected(),
                    });
                });
                return () => {
                    messenger.disconnect();
                };
            }
        },
        [token, pusher],
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
}

MessengerProvider.propTypes = {
    pusher: PropTypes.func.isRequired,
};

export default React.memo(MessengerProvider);
