import {Component} from "react";
import PropTypes from "prop-types";
import AnalyticsContext from "../AnalyticsProvider/AnalyticsContext";

/**
 * Component that sets and unsets analytics properties (through the AnalyticsProvider)
 * based on mount and dismount of the component. AnalyticsProvider must be wrapped further
 * up the tree for AnalyticsValue to be utilised successfully.
 */
export default class AnalyticsValue extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.any,
    };

    static defaultProps = {
        value: "",
    };

    static contextType = AnalyticsContext;

    /**
     * On component mount, call the appropriate context function set by AnalyticProvider
     * further up the tree.
     */
    componentDidMount() {
        this.context.setAnalyticsValue(this.props.name, this.props.value);
    }

    componentWillUnmount() {
        this.context.unsetAnalyticsValue(this.props.name);
    }

    render() {
        return null;
    }
}
