import React, {useEffect} from "react";
import PropTypes from "prop-types";
import useLocalStorage from "@unibuddy/machop/functions/useLocalStorage";
import {LOCAL_STORAGE_KEYS} from "../../../constants";

const Context = React.createContext({});

/**
 * The purpose of this hook is to provide authState
 * from AuthProvider, and the reason for the abstraction
 * is so we can change our implementation of how the authState
 * is retrieved in the future in once place.
 *
 * @export
 * @returns authState from the AuthProvider
 */
export function useAuth() {
    const authContext = React.useContext(Context);

    return authContext;
}

/**
 * AuthProvider holds the authentication state internally
 * and passes it to its children through the React context api.
 * It requires a single prop, onAuthChange, which sets up a listener
 * and passes the internal onMessage fn to the listener as a callback.
 * This is done so it can be easily tested. onAuthChange returns
 * an unsubscribe function that is required for cleanup by useEffect.
 *
 * @param {Object} props
 * @param {func} props.onAuthChange - sets up a auth state change listener
 * and passes the internal onMessage fn to the listener as a callback
 */
function AuthProvider({children, onAuthChange, initialAuthState}) {
    const slug = window.location.pathname.split('/')[1];
    const [authState, setAuthState] = useLocalStorage(
        `${LOCAL_STORAGE_KEYS.AUTH_STATE}.${slug}`,
        initialAuthState,
    );
    const isVerified = () => {
        return !!(
            authState &&
            authState.accessToken &&
            authState.me &&
            authState.me.anyUser.verified
        );
    };
    const isLoggedIn = () => {
        return !!(authState && authState.accessToken);
    };

    const onMessage = React.useCallback(authData => {
        setAuthState(authData);
    }, []); // eslint-disable-line
    useEffect(
        () => {
            const unsubscribe = onAuthChange(onMessage);

            return unsubscribe;
        },
        [onMessage, onAuthChange],
    );

    return (
        <Context.Provider value={{authState, setAuthState, isVerified, isLoggedIn}}>
            {children}
        </Context.Provider>
    );
}

AuthProvider.propTypes = {
    onAuthChange: PropTypes.func,
};

AuthProvider.defaultProps = {
    onAuthChange: () => {},
};

AuthProvider.Context = Context;

export default AuthProvider;
