import React from "react";
import classNames from "classnames";
import s from "./Carousel.pcss";

const arrowLeft = (
    <svg width="26" height="22" viewBox="0 0 26 22" xmlns="http://www.w3.org/2000/svg">
        <g id="Homepage" fill="none" fillRule="evenodd">
            <g
                id="[Desktop]-Homepage-"
                transform="translate(-391 -5950)"
                className="slick-arrow-inner-stroke"
                strokeWidth="2"
            >
                <g id="Section-5" transform="translate(378 5637)">
                    <g id="tail-left" transform="translate(15 315)">
                        <path d="M24,9 L0,9" id="Shape" />
                        <polyline id="Shape" strokeLinecap="square" points="9 18 0 9 9 0" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const PrevArrow = ({className, currentSlide, slideCount, ...props}) => (
    <a
        {...props}
        className={classNames(className, {
            [s.arrowDisabled]: !props.onClick,
        })}
    >
        {arrowLeft}
    </a>
);

export const NextArrow = props => (
    <PrevArrow {...props} className={classNames(props.className, s.rotate)} />
);
