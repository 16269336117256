import {Children} from "react";

const isNullOrUndefined = child => child === null || child === undefined;

/**
 * We use a forEach here for perf reasons so we avoid doing multiple loops.
 * Layout components are a hoth path in our app code so we should strive to have
 * this part as optimised as possible.
 *
 * @export
 * @param {import("react").ReactChildren} children
 * @param {Function} callback
 * @returns {Array<import("react").ReactChild>} render
 */
export default function mapRenderableChildren(children, callback) {
    const render = [];

    Children.forEach(children, (child, index, array) => {
        if (isNullOrUndefined(child) === true) return;

        render.push(callback(child, index, array));
    });

    return render;
}
