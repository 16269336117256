import {useContext} from "react";
import {ErrorReportingContext} from "./ErrorReportingProvider";

/**
 * The purpose of this hook is to provide reportError
 * from ErrorReportingProvider, and the reason for the abstraction
 * is so we can change our implementation of how the error
 * is reported in the future in once place.
 *
 * @export
 * @returns reportError from the ErrorReportingProvider
 */
export default function useErrorReporting() {
    const errorReportingContext = useContext(ErrorReportingContext);

    return errorReportingContext;
}
