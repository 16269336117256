import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import IoIosAlert from "react-icons/lib/io/android-alert";
import capitalize from "lodash/capitalize";
import {ErrorMessage as FormikErrorMessage} from "formik";
import s from "./ErrorMessage.pcss";

const ErrorListContainer = ({children}) => {
    return (
        <div className={s.listContainer}>
            <span className={s.icon}>
                <IoIosAlert />
            </span>
            <div className={s.listBody}>{children}</div>
        </div>
    );
};

export const ErrorList = ({errors, title}) => {
    return (
        <ErrorListContainer data-test-id="error-list-container">
            {title && <div className={s.errorListTitle}>{title}</div>}
            <ul className={s.errorList}>
                {errors.map(err => (
                    <li key={`${err.name}-${err.code}`} className={s.errorItem}>
                        {`${capitalize(err.name)}: ${capitalize(err.message)}`}
                    </li>
                ))}
            </ul>
        </ErrorListContainer>
    );
};

ErrorList.propTypes = {
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
            code: PropTypes.number.isRequired,
        }),
    ).isRequired,
    title: PropTypes.string,
};

const ErrorMessage = ({name, className, ...props}) => (
    <FormikErrorMessage name={name}>
        {message => {
            if (Array.isArray(message)) {
                return <ErrorList {...props} errors={message} />;
            }
            if (typeof message === "string") {
                return (
                    <div
                        data-test-id="error-message-text"
                        className={classNames(s.container, className)}
                        {...props}
                    >
                        {message}
                    </div>
                );
            }
            return null;
        }}
    </FormikErrorMessage>
);

export default ErrorMessage;
