import React, {useRef, useState} from "react";
import classNames from "classnames";
import Button from "@unibuddy/machop/Experimental/General/components/Button/Button";
import useOnClickOutside from "@unibuddy/machop/Experimental/hooks/useOnClickOutside";
import useOnEscape from "@unibuddy/machop/Experimental/hooks/useOnEscape";
import CountryFilter from "../BuddyFilters/CountryFilter";
import SubCategoryFilter from "../BuddyFilters/SubCategoryFilter";
import SubjectFilter from "../BuddyFilters/SubjectFilter";
import UniversityFilter from "../BuddyFilters/UniversityFilter";
import UniversityCountryRegionBox from "../BuddyFilters/UniversityCountryRegionBox";
import {FilterButton} from "../Filter/Filter";
import {Tab, Tabs} from "../FilterTabs/FilterTabs";
import FilterContainer from "../FilterContainer/FilterContainer";
import s from "./BuddiesFilterSection.pcss";

const stopEventBubbling = e => {
    e.nativeEvent.stopImmediatePropagation();
};

export default function BuddiesFilterSection({
    filters,
    setFilters,
    labels = [],
}) {
    const [openedFilter, _setOpenedFilter] = useState(null);
    const setOpenedFilter = val => {
        const root = document.querySelector(".App__main");
        if (val) {
            root.style.overflow = "hidden";
        } else {
            root.style.overflow = "auto";
        }
        _setOpenedFilter(val);
    };

    const resetFilters = keys => {
        if (keys) {
            const newKeys = {...filters};
            keys.map(key => {
                return delete newKeys[key];
            });
            return setFilters(newKeys);
        }
        return setFilters({});
    };

    // Function to handle disable state of reset filters
    // Disable when no filter is applied or disable when filter is hidden and pre-filter is applied though url
    const handleDisableState = () => {
        const defaultConditionToDisable =
            !filters.universityId &&
            !filters.domesticRegion &&
            !filters.universityCountry &&
            !filters.degreeLevel;

        const overrideHideDegreeFilter =
            filters.hideDegreeFilter ||
            (!filters.subCategory && !filters.subject);

        const overrideHideRegionFilter =
            filters.hideRegionFilter || !filters.countryId;

        return (
            overrideHideDegreeFilter &&
            overrideHideRegionFilter &&
            defaultConditionToDisable
        );
    };

    // Function handle the reset behavior, reset everything except the pre-filters
    const overRideResetOfPreFilters = () => {
        const preFilter = {};
        if (filters.hideDegreeFilter) {
            if (filters.subject) {
                preFilter.subject = filters.subject;
            }
            if (filters.subCategory) {
                preFilter.subCategory = filters.subCategory;
            }
        }
        if (filters.hideRegionFilter) {
            if (filters.countryId) {
                preFilter.countryId = filters.countryId;
            }
        }
        return preFilter;
    };

    const onDismiss = () => setOpenedFilter(null);
    const universityButtonRef = useRef(null);
    const subjectButtonRef = useRef(null);
    const countryButtonRef = useRef(null);
    const filterProps = {
        filters,
        setFilters,
    };

    const labelsMap = labels.reduce(
        (agg, label) => {
            if (label.filterSlug && label.name) {
                agg[label.filterSlug] = label.name;
            }
            return agg;
        },
        {
            filter1: "Course",
            filter2: "Universities",
            filter3: "Place of Origin",
        },
    );

    useOnClickOutside(
        () => {
            if (!openedFilter) return;
            setOpenedFilter(null);
        },
        [universityButtonRef, subjectButtonRef, countryButtonRef],
    );
    useOnEscape(() => {
        if (!openedFilter) return;
        setOpenedFilter(null);
    });

    return (
        <div>
            <div className={s.buttons}>
                {filters.hideDegreeFilter ? null : (
                    <FilterButton
                        hasFilters={[filters.subject, filters.subCategory]}
                        ref={subjectButtonRef}
                        name={labelsMap.filter1}
                        value="education"
                        setFilter={setOpenedFilter}
                        activeFilter={openedFilter}
                    />
                )}

                {filters.hideInstitutionFilter ||
                filters.showBuddiesFrom ? null : (
                    <FilterButton
                        hasFilters={[
                            filters.universityId,
                            filters.domesticRegion,
                            filters.universityCountry,
                        ]}
                        ref={universityButtonRef}
                        name={labelsMap.filter2}
                        value="university"
                        setFilter={setOpenedFilter}
                        activeFilter={openedFilter}
                    />
                )}

                {filters.hideRegionFilter ? null : (
                    <FilterButton
                        data-test-id="open-filter-country"
                        hasFilters={[filters.countryId]}
                        ref={countryButtonRef}
                        name={labelsMap.filter3}
                        value="country"
                        setFilter={setOpenedFilter}
                        activeFilter={openedFilter}
                    />
                )}
                <Button
                    className={s.resetButton}
                    onClick={() =>
                        setFilters({
                            showBuddiesFrom: filters.showBuddiesFrom,
                            hideRegionFilter: filters.hideRegionFilter,
                            showBuddiesInCarousel:
                                filters.showBuddiesInCarousel,
                            hideBuddiesNavItem: filters.hideBuddiesNavItem,
                            hideInstitutionFilter:
                                filters.hideInstitutionFilter,
                            hideDegreeFilter: filters.hideDegreeFilter,
                            ...(overRideResetOfPreFilters()),
                        })
                    }
                    disabled={handleDisableState()}
                >
                    Reset Filters
                </Button>
            </div>
            <div
                className={classNames(s.layer, {
                    [s.isVisible]: openedFilter,
                })}
            />

            <div onClick={stopEventBubbling}>
                <FilterContainer
                    mobileTitle="Buddies"
                    onDismiss={onDismiss}
                    onReset={resetFilters}
                    resetKeys={["countryId", "country"]}
                    isOpen={openedFilter === "country"}
                    className={s.singleFilter}
                >
                    <CountryFilter
                        isOpen={openedFilter === "country"}
                        {...filterProps}
                    />
                </FilterContainer>

                <FilterContainer
                    mobileTitle="Universities"
                    onDismiss={onDismiss}
                    isOpen={openedFilter === "university"}
                    onReset={resetFilters}
                    resetKeys={[
                        "domesticRegion",
                        "universityId",
                        "universityCountry",
                        "universityCountryName",
                        "universityName",
                    ]}
                >
                    <Tabs
                        className={s.universityTabs}
                        initialActiveIndex={filters.universityCountry ? 1 : 0}
                        tabs={["Country", "University"]}
                        hasSeparator
                    >
                        <Tab>
                            <UniversityCountryRegionBox {...filterProps} />
                        </Tab>
                        <Tab>
                            <UniversityFilter {...filterProps} />
                        </Tab>
                    </Tabs>
                </FilterContainer>

                <FilterContainer
                    mobileTitle="Subjects"
                    onDismiss={onDismiss}
                    isOpen={openedFilter === "education"}
                    onReset={resetFilters}
                    resetKeys={[
                        "subject",
                        "subCategory",
                        "subjectName",
                        "subCategoryName",
                    ]}
                >
                    <Tabs
                        activeIndex={filters.subject ? 1 : 0}
                        className={s.subjectContainer}
                    >
                        <Tab>
                            <SubjectFilter
                                isOpen={openedFilter === "education"}
                                {...filterProps}
                            />
                        </Tab>
                        <Tab>
                            <SubCategoryFilter
                                subject={filters.subjectName}
                                {...filterProps}
                            />
                        </Tab>
                    </Tabs>
                </FilterContainer>
            </div>
        </div>
    );
}
