import React, {useState, useEffect} from "react";
import RegionFilter from "../BuddyFilters/RegionFilter";
import UniversityCountryFilter from "../BuddyFilters/UniversityCountryFilter";
import {useIsUcas} from "../../../utils";

const UniversityCountryRegionBox = props => {
    const {filters} = props;
    const [currentTab, setCurrentTab] = useState(!!filters.universityCountry);
    const {isUcas} = useIsUcas();

    useEffect(
        () => {
            setCurrentTab(!!filters.universityCountry);
        },
        [filters.universityCountry],
    );

    if (isUcas) {
        return <RegionFilter {...props} onBack={null} />;
    }

    return currentTab ? (
        <RegionFilter {...props} onBack={() => setCurrentTab(false)} />
    ) : (
        <UniversityCountryFilter {...props} />
    );
};

export default UniversityCountryRegionBox;
