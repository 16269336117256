import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {v4 as uid} from "uuid";
import Label from "../Label/Label";
import s from "./FormField.pcss";

const generateId = () => uid();

function FormField({children, id, className, inline, label, rightAddon, ...props}) {
    const input = children;
    const [controlId] = useState(id || generateId);
    return (
        <div
            className={classNames(
                s.formField,
                {
                    [s.inline]: inline,
                },
                className,
            )}
            {...props}
        >
            <Label required={input.props.required} id={controlId} inline={inline}>
                {label}
            </Label>
            <div className={s.inner}>
                <div className={s.input}>
                    {React.cloneElement(input, {
                        id: controlId,
                        className: classNames(input.props.className, {
                            [s.hasRightAddon]: !!rightAddon,
                        }),
                    })}
                </div>
                {rightAddon
                    ? React.cloneElement(rightAddon, {
                          className: classNames(rightAddon.props.className, s.rightAddon),
                      })
                    : null}
            </div>
        </div>
    );
}

FormField.propTypes = {
    inline: PropTypes.bool,
};

FormField.defaultProps = {
    inline: false,
};

export default FormField;
