import React from "react";
import {FiltersContext} from "@unibuddy/machop/Buddies/components/BuddiesFiltersProvider/BuddiesFiltersProvider";

const FilterDependentComponent = ({
    children,
    filterKey,
    truthyCondition = value => value === "true",
    hideComponentWhenConditionTrue = false,
    alternative = null,
}) => {
    return (
        <FiltersContext.Consumer>
            {({filters}) => {
                const isConditionTrue = truthyCondition(filters[filterKey]);
                const shouldDisplayChildComponent = hideComponentWhenConditionTrue
                    ? !isConditionTrue
                    : isConditionTrue;
                return shouldDisplayChildComponent ? children : alternative;
            }}
        </FiltersContext.Consumer>
    );
};

export default FilterDependentComponent;
