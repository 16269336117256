import React from "react";
import {useHistory} from "react-router-dom";
import {usePopup, useRedirectUrl} from "./SSOProvider";

export function SSOEventsWrapper({setAuthState, children}) {
    const {redirectUrl} = useRedirectUrl();
    const history = useHistory();
    const {popup} = usePopup();

    // event listener for handling postmessages from popup
    React.useEffect(
        () => {
            const handleMessageEvent = e => {
                if (typeof e.data === "string") {
                    const msg = JSON.parse(e.data);
                    if (msg && !msg.error && msg.accessToken) {
                        setAuthState(msg);

                        if (msg.me && redirectUrl) {
                            history.push({
                                pathname: redirectUrl,
                            });
                        }
                    }
                }
            };

            window.addEventListener("message", handleMessageEvent);

            return () => {
                window.removeEventListener("message", handleMessageEvent);
            };
        },
        [setAuthState, redirectUrl, history],
    );

    // Event listener to handle the closing of parent window(SSO implementation)
    // When parent window is closed it should close the popup as well
    React.useEffect(
        () => {
            const handlePopupClose = () => {
                if (popup && !popup.closed) {
                    popup.close();
                }
            };

            window.addEventListener("beforeunload", handlePopupClose);
            return () => {
                window.removeEventListener("beforeunload", handlePopupClose);
            };
        },
        [popup],
    );
    return children;
}
