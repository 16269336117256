import React from "react";
import find from "lodash/find";
import classNames from "classnames";
import get from "lodash/get";
import Button from "@unibuddy/machop/Experimental/General/components/Button/Button";
import useAnalytics from "@unibuddy/machop/AnalyticsNew/hooks/useAnalytics";
import {toTitleCase} from "@unibuddy/machop/Utils/StringUtils";
import ListFilter from "../ListFilter/ListFilter";
import marketplaceMentorDegreeCategories from "../../queries/marketplaceMentorDegreeCategories";
import {EmptyFilter, Filter, FilterBody, FilterQuery, FilterTitle} from "../Filter/Filter";
import s from "./BuddyFilters.pcss";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";

const SubCategoryFilter = ({filters, setFilters, subject}) => {
    const {
        events: {buddiesFiltered},
    } = useAnalytics();
    const {marketplace} = useMarketplaceConfig();
    return (
        <Filter id="sub-category-filter-wrapper">
            {subject && (
                <FilterTitle id="subject-filter" className={s.parentSubjectFilter}>
                    Subject:{" "}
                    <Button
                        id="subject-filter"
                        color="primary"
                        onClick={() => setFilters({...filters, subject: undefined})}
                    >
                        {subject}
                    </Button>
                </FilterTitle>
            )}
            <FilterTitle
                id="sub-category-filter"
                className={classNames({
                    [s.disabledTitle]: !filters.subject,
                })}
            >
                Sub-category
            </FilterTitle>
            {filters.subject && (
                <FilterQuery
                    query={marketplaceMentorDegreeCategories}
                    variables={{
                        ...filters,
                        marketplaceSlug: marketplace.slug,
                        universitySlug: filters.showBuddiesFrom,
                        degreeCategoryRank: 1,
                        parentDegreeCategory: filters.subject,
                    }}
                >
                    {({data}) => {
                        if (
                            data.marketplaceMentorDegreeCategories &&
                            !data.marketplaceMentorDegreeCategories.length
                        ) {
                            return (
                                <EmptyFilter>
                                    There is no sub-category with ambassador for that subject.
                                </EmptyFilter>
                            );
                        }
                        return (
                            <FilterBody className={s.subCategoryBody}>
                                <ListFilter
                                    id="sub-category-filter"
                                    placeholder={"Choose a sub-category"}
                                    options={data.marketplaceMentorDegreeCategories.map(x => ({
                                        ...x,
                                        name: toTitleCase(x.name),
                                    }))}
                                    filter={find(data.marketplaceMentorDegreeCategories, {
                                        id: filters.subCategory,
                                    })}
                                    setFilter={degreeCategory => {
                                        if (degreeCategory) {
                                            buddiesFiltered({
                                                filterKey: "subCategory",
                                                filterValue: degreeCategory.name,
                                                buddyType: "mentor",
                                            });
                                        }
                                        setFilters({
                                            ...filters,
                                            subCategory: get(degreeCategory, "id", undefined),
                                            subCategoryName: get(degreeCategory, "name", undefined),
                                        });
                                    }}
                                />
                            </FilterBody>
                        );
                    }}
                </FilterQuery>
            )}
            {!filters.subject && <EmptyFilter>Pick a subject</EmptyFilter>}
        </Filter>
    );
};

export default SubCategoryFilter;
