/**
 * Converts "responsive" value that can be an array or a simple value to an
 * array of an exact length needed to match our mediaQueries.
 *
 * @export
 * @param {Array<Number | String> | Number | String} value - responsive value
 * @returns Array<String>
 */
export default function normaliseResponsiveProp(value) {
    if (!value) return value;
    if (typeof value === "string" || typeof value === "number") {
        return [value, value, value, value];
    }

    if ("length" in value) {
        const {length} = value;

        if (length === 5) {
            return value;
        }

        if (length === 4) {
            const [mobileValue, tabletValue, smallDesktop, largeDesktop] = value;
            return [mobileValue, tabletValue, smallDesktop, largeDesktop, largeDesktop];
        }

        if (length === 3) {
            const [mobileValue, tabletValue, smallDesktop] = value;
            return [mobileValue, tabletValue, smallDesktop, smallDesktop, smallDesktop];
        }

        if (length === 2) {
            const [mobileValue, tabletValue] = value;
            return [mobileValue, tabletValue, tabletValue, tabletValue, tabletValue];
        }

        if (length === 1) {
            const [mobileValue] = value;
            return [mobileValue, mobileValue, mobileValue, mobileValue, mobileValue];
        }

        throw new Error(`Invalid responsive prop length: ${JSON.stringify(value)}`);
    }

    throw new Error(`Invalid responsive prop value: ${JSON.stringify(value)}`);
}
