export class UserData {
    constructor(data) {
        const {
            yearOfEntry = null,
            custom = null,
            degreeCategories = [],
            marketingConsentAccepted = null,
            personalDataCollectionAccepted = null,
        } = data;
        this.yearOfEntry = yearOfEntry;
        this.custom = custom;
        this.degreeCategories = degreeCategories;
        this.marketingConsentAccepted = marketingConsentAccepted;
        this.personalDataCollectionAccepted = personalDataCollectionAccepted;
    }
}
