import {Component} from "react";
import PropTypes from "prop-types";
import {ErrorReportingContext} from "../ErrorReporting/ErrorReportingProvider";

class ErrorBoundary extends Component {
    static propTypes = {
        renderError: PropTypes.func,
        boundaryName: PropTypes.string,
    };
    static contextType = ErrorReportingContext;

    state = {
        errorMessage: "",
        error: null,
    };

    componentDidCatch(err) {
        if (this.context && this.context.reportError) {
            this.context.reportError(err, {
                tags: {
                    framework: "react",
                    errorBoundaryName: this.props.boundaryName,
                },
                level: "fatal",
            });
        }

        this.setState({
            errorMessage: err.message,
            error: err,
        });
    }

    render() {
        if (this.state.errorMessage !== "") {
            return this.props.renderError
                ? this.props.renderError({error: this.state.error})
                : `Error: ${this.state.errorMessage}`;
        }

        return this.props.children === undefined ? null : this.props.children;
    }
}

export default ErrorBoundary;
