import React from "react";
import find from "lodash/find";
import get from "lodash/get";
import useAnalytics from "@unibuddy/machop/AnalyticsNew/hooks/useAnalytics";
import ListFilter from "../ListFilter/ListFilter";
import marketplaceMentorCountries from "../../queries/marketplaceMentorCountries";
import {Filter, FilterBody, FilterQuery, FilterTitle} from "../Filter/Filter";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";

const CountryFilter = ({filters, setFilters, isOpen}) => {
    const {
        events: {buddiesFiltered},
    } = useAnalytics();
    const {marketplace} = useMarketplaceConfig();
    return (
        <Filter id="country-filter-wrapper">
            <FilterTitle id="country-filter">Country of Origin</FilterTitle>
            <FilterQuery
                query={marketplaceMentorCountries}
                variables={{
                    ...filters,
                    universitySlug: filters.showBuddiesFrom,
                    marketplaceSlug: marketplace.slug,
                    degreeCategoryId: filters.subCategory || filters.subject || undefined,
                }}
            >
                {({data}) => {
                    return (
                        <FilterBody>
                            <ListFilter
                                id="country-filter"
                                isOpen={isOpen}
                                placeholder={"Choose a country"}
                                options={data.marketplaceMentorCountries}
                                filter={find(data.marketplaceMentorCountries, {
                                    id: filters.countryId,
                                })}
                                setFilter={country => {
                                    if (country) {
                                        buddiesFiltered({
                                            filterKey: "country",
                                            filterValue: country.name,
                                            buddyType: "mentor",
                                        });
                                    }
                                    setFilters({
                                        ...filters,
                                        countryId: get(country, "id", undefined),
                                        country: get(country, "name", undefined),
                                    });
                                }}
                            />
                        </FilterBody>
                    );
                }}
            </FilterQuery>
        </Filter>
    );
};

export default CountryFilter;
