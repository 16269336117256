import React from "react";
import PropTypes from "prop-types";
import RequiredFormIndicator from "../../../../Forms/components/RequiredFormIndicator/RequiredFormIndicator";
import s from "./LabelText.pcss";

const getErrorMessage = (error, name = "") =>
    error.startsWith(name) ? error.replace(name, "") : error;

const LabelText = ({touched, error, name, children, required}) => {
    const showError = touched && error;
    const formattedErrorMessage = showError ? getErrorMessage(error, name) : "";

    return (
        <React.Fragment>
            {children}
            {showError && (
                <span className={s.errorMessage} data-test-id="errorMessage">
                    {formattedErrorMessage}
                </span>
            )}
            {required === true && !showError && <RequiredFormIndicator />}
        </React.Fragment>
    );
};

LabelText.propTypes = {
    name: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    required: PropTypes.bool,
};

LabelText.defaultProps = {
    error: "",
    touched: false,
    required: false,
};

export default LabelText;
