import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "@unibuddy/intl";
import BuddyCard from "@unibuddy/machop/Buddies/components/BuddyCard/BuddyCard";
import {usePopup} from "@unibuddy/ssomodule";
import useAnalytics from '@unibuddy/machop/AnalyticsNew/hooks/useAnalytics';
import buddyCardStyle from "./BuddyCard.pcss";
import s from "./BuddyListCard.pcss";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";

function BuddyListCard({mentor, position}) {
    const {t} = useTranslation("mentorCard");
    const {marketplace} = useMarketplaceConfig();
    const {handleClick: ssoHandleClick} = usePopup();
    const {
        events: {mentorCardChatNowClicked},
    } = useAnalytics();

    const handleClick = () => {
        mentorCardChatNowClicked();
        ssoHandleClick();
    };

    return (
        <BuddyCard>
            <BuddyCard.Split>
                <BuddyCard.Left>
                    <Link
                        className={s.avatarLink}
                        tabIndex="-1"
                        title={`${mentor.firstName}'s profile`}
                        to={`/${marketplace.slug}/buddies/profile/${
                            mentor.id
                        }?buddyPosition=${position}`}
                    >
                        <BuddyCard.Avatar mentor={mentor} />
                    </Link>
                    <BuddyCard.Name>{mentor.firstName}</BuddyCard.Name>
                </BuddyCard.Left>
                <BuddyCard.Right>
                    <BuddyCard.Logo mentor={mentor} />
                    <BuddyCard.Degree mentor={mentor} />
                </BuddyCard.Right>
            </BuddyCard.Split>

            <BuddyCard.Separator />

            <BuddyCard.Section title={t("aboutMe", "About Me")}>{`${mentor.shortBio}...`}</BuddyCard.Section>

            <BuddyCard.ReadMore
                to={`/${marketplace.slug}/buddies/profile/${mentor.id}?buddyPosition=${position}`}
                mentor={mentor}
                className={buddyCardStyle.readMore}
            />

            <BuddyCard.CTA
                to={`/${marketplace.slug}/inbox/chatwith/${mentor.id}?buddyPosition=${position}`}
                mentor={mentor}
                className={buddyCardStyle.cta}
                onClick={handleClick}
            />
        </BuddyCard>
    );
}

export default React.memo(BuddyListCard);
