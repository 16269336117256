import React from "react";
import {SSOEventsWrapper} from "./SSOEventsWrapper";

// This context is to save redirect url into the global state
// from localStorage. Useful in cases where localStorage is not available
export const RedirectUrlContext = React.createContext();

// This context is to set the popup reference so that it could accessed from elsewhere
export const PopupContext = React.createContext();

export const useRedirectUrl = () => {
    const context = React.useContext(RedirectUrlContext);
    if (context === undefined) {
        throw new Error(
            "useRedirectUrl must be used within a RedirectUrlProvider",
        );
    }
    return context;
};

/**
 * React context provider for SSO functionalities
 * @param {{options: {popupUrl: string,enablePopup: boolean ,setAuthState: function}, children}}
 * @returns React Context Provider
 */
export const SSOProvider = ({options = {}, children}) => {
    const {
        popupUrl = "",
        enablePopup = false,
        setAuthState = () => {},
    } = options;
    const [popup, setPopup] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);

    const handleClick = () => {
        if (enablePopup) {
            const popupRef = window.open(
                popupUrl,
                "sso",
                `width=500,height=550,left=${(screen.width - 500) /
                    2},top=${(screen.height - 550) / 2}`,
            );
            setPopup(popupRef);
        }
    };

    return (
        <PopupContext.Provider value={{popup, handleClick}}>
            <RedirectUrlContext.Provider
                value={{redirectUrl, setRedirectUrl}}
            >
                <SSOEventsWrapper setAuthState={setAuthState}>
                    {children}
                </SSOEventsWrapper>
            </RedirectUrlContext.Provider>
        </PopupContext.Provider>
    );
};

export const usePopup = () => {
    const context = React.useContext(PopupContext);
    if (context === undefined) {
        throw new Error("usePopup must be used within a PopupProviderProvider");
    }
    return context;
};

/**
 * Logout user from Fusion auth and unibuddy using bearer token
 * @param {*} logoutUrl API url
 * @param {*} data API body params
 */
export const logoutFromAcvl = (logoutUrl, data) => {
    fetch(logoutUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then(response => response.json())
        .then(() => {})
        .catch(error => {
            console.error("Error:", error);
        });
};
