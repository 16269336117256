/** libraries * */
import React from "react";
import {v4 as uid} from "uuid";
import PropTypes from "prop-types";
import classNames from "classnames";
import compose from "lodash/flowRight";
import {Field, connect} from "formik";

/** Custom * */
import {withForm} from "../Form/Form";
import LabelText from "../LabelText/LabelText";

/** Styles */
import s from "./CheckBox.pcss";

class CheckBox extends React.Component {
    state = {
        controlId: this.props.id || uid(),
    };

    componentDidMount() {
        if (this.props.validation) {
            this.props.setFieldOverride(this.props.name, {
                schema: this.props.validation,
            });
        }

        if (this.props.initialValue !== undefined) {
            setTimeout(() => {
                this.props.formik.setFieldValue(this.props.name, this.props.initialValue);
            }, 1);
        }
    }

    componentWillUnmount() {
        if (this.props.validation) {
            this.props.removeFieldOverride(this.props.name);
        }
    }

    render() {
        return (
            <Field name={this.props.name}>
                {({field, form}) => {
                    const error = form.errors[field.name];
                    const touched = form.touched[field.name];
                    return (
                        <label
                            htmlFor={this.state.controlId}
                            data-test-id={`label-${this.state.controlId}`}
                            className={classNames(s.container, this.props.className)}
                        >
                            <input
                                id={this.state.controlId}
                                type="checkbox"
                                checked={!!field.value}
                                name={field.name}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                required={this.props.required}
                                disabled={this.props.disabled}
                            />
                            <span
                                className={classNames(s.checkmark, {
                                    [s.disabled]: this.props.disabled,
                                })}
                            />
                            <span
                                className={classNames(s.label, {[s.disabled]: this.props.disabled})}
                                data-test-id="label-content"
                            >
                                <LabelText
                                    name={field.name}
                                    error={error}
                                    touched={touched}
                                    required={this.props.required}
                                >
                                    {this.props.children}
                                </LabelText>
                            </span>
                        </label>
                    );
                }}
            </Field>
        );
    }
}

CheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

CheckBox.defaultProps = {
    required: false,
};

export default compose(
    withForm,
    connect,
)(CheckBox);
