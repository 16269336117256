import React from 'react';
import {
    Logo,
    Heading,
    Button,
    Box,
    Stack,
} from '@unibuddy/patron';
import ThemedLink from '@unibuddy/machop/Navigation/components/ThemedLink/ThemedLink';
import {usePopup} from "@unibuddy/ssomodule";
import useAnalytics from '@unibuddy/machop/AnalyticsNew/hooks/useAnalytics';

export const SSOLandingPage = () => {
    const {handleClick} = usePopup();
    const {
        events: {prospectInteractWithIframe},
    } = useAnalytics();

    const onClick = e => {
        prospectInteractWithIframe();
        handleClick(e);
    };

    return (
        <Box
            padding="medium"
            paddingTop="large"
            data-testid="sso-landing-page"
        >
            <Heading level="1">Signin to Unibuddy</Heading>
            <Box
                padding="medium"
                paddingTop="xlarge"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                minH="initial"
            >
                <Stack align="center" space="medium">
                    <Box
                        padding="medium"
                        borderColor="solitude"
                        borderWidth={1}
                        borderRadius="xsmall"
                        w={['100%', 680]}
                        onClick={onClick}
                        data-testid="sso-landing-page-form"
                        as="button"
                    >
                        <Stack align="center" space="medium">
                            <Logo width="150" />
                            <Stack align="center" space="xlarge">
                                <Heading level="2" size="small" weight="600" align="center">
                                    Click anywhere to continue
                                </Heading>
                                <Box paddingLeft="large" paddingRight="large">
                                    Interact with the page to proceed to log in or registration - you can do this by clicking continue below.
                                </Box>
                                <Button data-testid="continue" color="primary">
                                    Continue
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box
                        padding="small"
                        display="flex"
                        minH="initial"
                        w={['100%', 680]}
                    >
                        <ThemedLink
                            external
                            to="mailto:support@unibuddy.com"
                            target="_blank"
                        >
                            Contact Unibuddy
                        </ThemedLink>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};
