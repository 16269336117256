export const commonPrepositions = [
    "of",
    "in",
    "to",
    "for",
    "with",
    "on",
    "at",
    "from",
    "by",
    "about",
    "as",
    "into",
    "like",
    "through",
    "after",
    "over",
    "between",
    "out",
    "against",
    "during",
    "without",
    "before",
    "under",
    "around",
    "among",
];
export const commonArticles = ["a", "an", "the"];
export const commonConjunctions = [
    "and",
    "that",
    "but",
    "or",
    "as",
    "if",
    "when",
    "than",
    "because",
    "while",
    "where",
    "after",
    "so",
    "though",
    "since",
    "until",
    "whether",
    "before",
    "although",
    "nor",
    "like",
    "once",
    "unless",
    "now",
    "except",
];

/**
 * Temporary titleCase function until we find a good library. Essentially need to capitalize proper nouns.
 * @param inputStr
 * @param compulsoryUpper
 * @param compulsoryLower
 * @returns {*}
 */
export const toTitleCase = (inputStr, compulsoryUpper = [], compulsoryLower = []) => {
    const lowers = [
        ...commonPrepositions,
        ...commonArticles,
        ...commonConjunctions,
        ...compulsoryLower,
    ].map(x =>
        x.replace(/([^\W_]+[^\s-]*) */g, txt => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }),
    );
    let str = inputStr.replace(/([^\W_]+[^\s-]*) */g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

    lowers.forEach(lower => {
        str = str.replace(new RegExp(`\\s${lower}\\s`, "g"), txt => {
            return txt.toLowerCase();
        });
    });

    const uppers = [...compulsoryUpper].map(x =>
        x.replace(/([^\W_]+[^\s-]*) */g, txt => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }),
    );
    uppers.forEach(upper => {
        str = str.replace(new RegExp(`\\b${upper}\\b`, "g"), upper.toUpperCase());
    });

    return str;
};
