import React from "react";
import {NavLink as RRNavLink} from "react-router-dom";
import Badge from "@unibuddy/machop/Experimental/General/components/Badge/Badge";
import "./NavLink.css";

export default function NavLink(props) {
    return (
        <RRNavLink
            {...props}
            className="Navigation__link"
            activeClassName="Navigation__link--active"
        >
            {props.children}
            {props.notification ? (
                <div className="Navigation__link__notification-wrapper">
                    <Badge color="danger" className="Navigation__link__notification-text">
                        {props.notification}
                    </Badge>
                </div>
            ) : null}
        </RRNavLink>
    );
}
