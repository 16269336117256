import PropTypes from "prop-types";
import React, {Fragment} from "react";
import classNames from "classnames";
import get from "lodash/get";
import {withTranslation} from "@unibuddy/intl";
import ChatNowButton from "../../../../Blog/components/ChatNowButton/ChatNowButton";
import MentorCardBanner from "../../../../Mentor/components/MentorCardBanner/MentorCardBanner";
import MentorCardDegree from "../../../../Mentor/components/MentorCardDegree/MentorCardDegree";
import MentorCardName from "../../../../Mentor/components/MentorCardName/MentorCardName";
import MentorCardSectionItem from "../../../../Mentor/components/MentorCardSectionItem/MentorCardSectionItem";
import MentorCardSeparator from "../../../../Mentor/components/MentorCardSeparator/MentorCardSeparator";
import MentorCardContainer from "../MentorCardContainer/MentorCardContainer";
import s from "./MentorCarouselCard.pcss";

const MentorCarouselCard = props => {
    const {t} = props;
    const Country = (
        <MentorCardSectionItem
            label={t("iComeFrom", "I come from")}
            className={props.isExternal ? s.left : {}}
        >
            {props.placeLabel}
        </MentorCardSectionItem>
    );
    const Seperator = (
        <MentorCardSeparator
            className={classNames(s.separator, {
                [s.separatorExternal]: props.isExternal,
            })}
        />
    );
    const ChatButton = (
        <div className={classNames(s.chatNow, {[s.chatNowExternal]: props.isExternal})}>
            <ChatNowButton
                large
                onChatNow={() => props.onChatNow(props.mentorId)}
                name={props.firstName}
            />
        </div>
    );
    return (
        <MentorCardContainer className={props.className}>
            <MentorCardBanner
                name={props.firstName}
                profilePhoto={props.profilePhoto}
                lastSeen={props.lastSeen}
                countryCode={props.countryCode}
                primaryFlag={props.primaryFlag}
                secondaryFlag={props.secondaryFlag}
                imageClickable={false}
                isShowCountryFlagEnabled={props.isShowCountryFlagEnabled}
            />

            <MentorCardName name={props.firstName} />

            <div className={s.degreeName}>
                <MentorCardDegree degree={props.degreeName} />
            </div>

            {props.isExternal ? null : (
                <Fragment>
                    {Country}
                    {Seperator}
                    {ChatButton}
                </Fragment>
            )}

            {props.isExternal ? (
                <Fragment>
                    {ChatButton}
                    {Seperator}
                    <div className={s.left}>
                        {Country}
                        <MentorCardSectionItem label={t("iSpeak", "I speak")}>
                            {get(props, "languages", [])
                                .map(l => l.name)
                                .join(", ")}
                        </MentorCardSectionItem>
                        {props.degreeLevel !== "Alumni" ? (
                            <MentorCardSectionItem label={t("currentlyIn", "I'm currently in")}>
                                {props.year}
                            </MentorCardSectionItem>
                        ) : null}
                    </div>
                </Fragment>
            ) : null}
        </MentorCardContainer>
    );
};

MentorCarouselCard.propTypes = {
    mentorId: PropTypes.string.isRequired,
    profilePhoto: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    placeLabel: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    primaryFlag: PropTypes.string,
    secondaryFlag: PropTypes.string,
    lastSeen: PropTypes.string,
    degreeName: PropTypes.string,
    onChatNow: PropTypes.func,
    isExternal: PropTypes.bool,
};

MentorCarouselCard.defaultProps = {
    lastSeen: null,
    degreeName: "",
    onChatNow: () => true,
    isExternal: false,
    primaryFlag: "",
    secondaryFlag: "",
};

export default withTranslation("mentorCard")(MentorCarouselCard);
