import {gql} from "@apollo/client";

const marketplaceMentorRegions = gql`
    query MarketplaceMentorRegions(
        $marketplaceSlug: String
        $universityId: String
        $degreeCategoryId: String
        $degreeLevel: String
        $countryId: String
        $universityCountry: String
    ) {
        marketplaceMentorRegions(
            marketplaceSlug: $marketplaceSlug
            degreeCategoryId: $degreeCategoryId
            universityId: $universityId
            degreeLevel: $degreeLevel
            countryId: $countryId
            universityCountry: $universityCountry
        ) {
            id
            name
        }
    }
`;

export default marketplaceMentorRegions;
