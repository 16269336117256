import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./Container.pcss";

export const NarrowContainer = ({className, children, ...rest}) => (
    <div className={classNames(s.narrow, className)} {...rest}>
        {children}
    </div>
);

export const WideContainer = ({className, children, ...rest}) => (
    <div className={classNames(s.wide, className)} {...rest}>
        {children}
    </div>
);

export const FullWidthContainer = ({className, children, ...rest}) => (
    <div className={classNames(s.fullWidth, className)} {...rest}>
        {children}
    </div>
);

const Container = ({fluid, children, center, className, ...rest}) => (
    <div
        className={classNames(className, {
            container: !fluid,
            "container-fluid": fluid,
            "center-block": center,
        })}
        {...rest}
    >
        {children}
    </div>
);

Container.propTypes = {
    fluid: PropTypes.bool,
    className: PropTypes.string,
};

Container.defaultProps = {
    fluid: false,
    className: "",
};

export default Container;
