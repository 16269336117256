import {useEffect, useRef} from "react";
import createFocusTrap from "focus-trap";

export default function useFocusTrap({enabled, element, fallbackFocus}) {
    const focusTrap = useRef(null);

    useEffect(
        () => {
            if (enabled && element.current && fallbackFocus.current) {
                try {
                    focusTrap.current = createFocusTrap(element.current, {
                        clickOutsideDeactivates: true,
                        fallbackFocus: fallbackFocus.current,
                    });
                    focusTrap.current.activate();
                } catch (error) {
                    console.log(error);
                }
            }
            return () => {
                if (focusTrap.current) {
                    focusTrap.current.deactivate();
                }
            };
        },
        [element, enabled, fallbackFocus],
    );
}
