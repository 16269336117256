import React, {memo} from "react";
import PropTypes from "prop-types";
import makeFont from "../../makeFont";
import colorMap from "../../../Layout/utils/colorMap";
import useBackgroundColor from "../../../Utils/components/BackgroundColorProvider/useBackgroundColor";
import {__TextImplementation as TextImplementation} from "../TextImplementation/TextImplementation";

const sizeMap = {
    xsmall: makeFont(13),
    small: makeFont(14),
    large: makeFont(18),
    standard: makeFont(16),
};

const toneMap = {
    normal: colorMap.bodyColour,
    secondary: colorMap.smartGrey,
};

const TextBlock = memo(Text);

TextBlock.defaultProps = {
    ...TextImplementation.defaultProps,
    size: "standard",
    tone: "normal",
};

TextBlock.propTypes = {
    ...TextImplementation.propTypes,
    size: PropTypes.oneOf(Object.keys(sizeMap)),

    /* Affects text color */
    tone: PropTypes.oneOf(Object.keys(toneMap)),
};

export default TextBlock;

function Text({children, size, tone, color, weight, transform, truncate, breakWords, nowrap}) {
    const fontStyles = sizeMap[size];
    const {status} = useBackgroundColor();
    const preferredTextColor = color ? colorMap[color] || color : toneMap[tone];

    return (
        <TextImplementation
            transform={transform}
            truncate={truncate}
            nowrap={nowrap}
            breakWords={breakWords}
            weight={weight}
            color={status === "dark" ? "#fff" : preferredTextColor}
            fontStyles={fontStyles}
        >
            {children}
        </TextImplementation>
    );
}
