import {useQuery, useMutation} from "@apollo/client";
import React, {Fragment, useContext} from "react";
import get from "lodash/get";
import find from "lodash/find";
import {array, boolean, string} from "yup";
import ReactMarkdown from "react-markdown";
import DateOfEntrySelect from "@unibuddy/machop/Register/components/DateOfEntrySelect/DateOfEntrySelect";
import Stack from "@unibuddy/machop/Experimental/Layout/components/Stack/Stack";
import {NarrowContainer} from "@unibuddy/machop/Experimental/Layout/components/Container/Container";
import Button from "@unibuddy/machop/Experimental/General/components/Button/Button";
import FormGroup from "@unibuddy/machop/Experimental/Forms/components/FormGroup/FormGroup";
import Autocomplete from "@unibuddy/machop/Experimental/Forms/components/Autocomplete/Autocomplete";
import ErrorMessage from "@unibuddy/machop/Experimental/Forms/components/ErrorMessage/ErrorMessage";
import Form from "@unibuddy/machop/Experimental/Forms/components/Form/Form";
import Collapsible from "@unibuddy/machop/Experimental/General/components/Collapsible/Collapsible";
import ThemedLink from "@unibuddy/machop/Navigation/components/ThemedLink/ThemedLink";
import useErrorReporting from "@unibuddy/machop/General/components/ErrorReporting/useErrorReporting";
import SubmitButton from "@unibuddy/machop/Experimental/Forms/components/SubmitButton/SubmitButton";
import CheckBox from "@unibuddy/machop/Experimental/Forms/components/CheckBox/CheckBox";
import Box from "@unibuddy/machop/Experimental/Layout/components/Box/Box";
import SuspenseLoader from "@unibuddy/machop/Shared/components/SuspenseLoader/SuspenseLoader";
import {PRIVACY_POLICY, TERMS_OF_USE} from "@unibuddy/machop/Shared/links/links";
import {useMarketplaceConfig} from "../../../../src/MarketplaceConfigProvider";
import AuthProvider from "../../../Auth/components/AuthProvider/AuthProvider";
import updateMarketplaceApplicantMutation from "../../mutations/updateMarketplaceApplicantMutation";
import {PRIVACY_FRAGMENT} from "../../../constants";
import degreeCategories from "../../../Auth/queries/degreeCategories";
import s from "./MarketplaceTermsRequired.pcss";
import {UserData} from "../../../dataModels/user";

const SignUpIcon = React.lazy(() => import(/* webpackChunkName: "SignupIcon" */ "@unibuddy/machop/Register/components/SignupIcon/SignupIcon"));

export const formValidation = dontKnowYet => {
    const validation = {
        marketingConsentAccepted: boolean(),
        yearOfEntry: string(),
    };
    if (!dontKnowYet) {
        validation.degreeCategories = array()
            .min(1)
            .required();
    }
    return validation;
};

export const submitMarketplaceTermsHandler = ({
    updateMarketplaceApplicant,
    authState,
    setAuthState,
    reportError,
    marketplace,
    applicantMarketplace = {},
}) => async (values, formikActions) => {
    try {
        const {data} = await updateMarketplaceApplicant({
            variables: {
                marketplace: {
                    ...applicantMarketplace,
                    slug: marketplace.slug,
                    userData: {
                        ...applicantMarketplace.userData,
                        yearOfEntry: values.yearOfEntry,
                        degreeCategories: (values.degreeCategories || []).map(({id}) => id),
                        marketingConsentAccepted: get(
                            marketplace,
                            "marketplaceMarketingConsentCollectionEnabled",
                            false,
                        )
                            ? values.marketingConsentAccepted
                            : null,
                        personalDataCollectionAccepted:
                            get(marketplace, "personalDataApiEnabled", false) || null,
                    },
                },
            },
        });
        const {error, me} = data.updateMarketplaceApplicant;
        formikActions.setSubmitting(false);
        formikActions.resetForm(values);
        if (error) {
            reportError(error);
            return formikActions.setFieldError("serverError", "Sorry an error occured");
        }
        return setAuthState({
            ...authState,
            me,
        });
    } catch (error) {
        reportError(error, {tags: ["critical"]});
        formikActions.setSubmitting(false);
        formikActions.setFieldError("serverError", "Sorry an error occured");
    }
};

export const MarketplaceTermsRequired = props => {
    const {reportError} = useErrorReporting();
    const {marketplace} = useMarketplaceConfig();
    const {authState, setAuthState} = useContext(AuthProvider.Context);
    const applicantMarketplaces = get(authState, "me.applicant.marketplaces", []);
    const applicantMarketplace = find(applicantMarketplaces, {slug: marketplace.slug});
    const initialValues = {
        yearOfEntry: get(applicantMarketplace, "userData.yearOfEntry", ""),
        marketingConsentAccepted:
            get(applicantMarketplace, "userData.marketingConsentAccepted", false) || false,
        degreeCategories: [],
        serverError: "",
    };
    const [updateMarketplaceApplicant] = useMutation(updateMarketplaceApplicantMutation);
    const {data: degreeCategoriesData} = useQuery(degreeCategories);
    const submitMarketplaceTerms = React.useCallback(
        submitMarketplaceTermsHandler({
            updateMarketplaceApplicant,
            authState,
            setAuthState,
            reportError,
            marketplace,
            applicantMarketplace,
        }),
        [
            updateMarketplaceApplicant,
            authState,
            setAuthState,
            reportError,
            marketplace,
            applicantMarketplace,
        ],
    );
    const linkApplicantToMarketPlace = async () => {
            const {data} = await updateMarketplaceApplicant({
                variables: {
                    marketplace: {
                        ...applicantMarketplace,
                        slug: marketplace.slug,
                        userData: new UserData({
                            ...(applicantMarketplace && applicantMarketplace.userData),
                            marketingConsentAccepted: get(
                                marketplace,
                                "marketplaceMarketingConsentCollectionEnabled",
                                false,
                            ) || null,
                            personalDataCollectionAccepted:
                                get(marketplace, "personalDataApiEnabled", false) || null,
                        }),
                    },
                },
            });
            const {error, me} = data.updateMarketplaceApplicant;
            if (error) {
                reportError(error);
                return;
            }
            return setAuthState({
                ...authState,
                me,
            });
    };

    React.useEffect(
        () => {
            if (!get(marketplace, "personalDataApiEnabled", false) && !applicantMarketplace) {
                linkApplicantToMarketPlace();
            }
        },
        [], // eslint-disable-line
    );
    const [dontKnowYet, setDontKnowYet] = React.useState(false);
    const validation = React.useMemo(() => formValidation(dontKnowYet), [dontKnowYet]);
    if (
        get(marketplace, "personalDataApiEnabled", false) &&
        (!applicantMarketplace ||
            !get(applicantMarketplace, "userData.personalDataCollectionAccepted", false))
    ) {
        const firstName = get(authState, "me.anyUser.firstName", "");
        return (
            <NarrowContainer>
                <Stack align="center" space="medium">
                    <SuspenseLoader>
                    <SignUpIcon showDesktopText />
                    </SuspenseLoader>
                    <h1 className={s.title}>{firstName}, tell us a bit about you</h1>
                    <p className={s.subtitleText}>
                        We’ve noticed you already have a Unibuddy account. Please tell us about your
                        interests and accept {marketplace.name}&apos;s{" "}
                        <ThemedLink
                            external
                            to={get(marketplace, "privacyPolicyUrl")}
                            target="_blank"
                        >
                            Privacy Policy
                        </ThemedLink>
                    </p>
                </Stack>
                <Form
                    name="Marketplace Terms"
                    validationSchema={validation}
                    onSubmit={submitMarketplaceTerms}
                    initialValues={initialValues}
                    className={s.preferencesForm}
                >
                    {() => (
                        <React.Fragment>
                            {dontKnowYet ? (
                                <div className={s.missingDegreeContainer}>
                                    <Button onClick={() => setDontKnowYet(!dontKnowYet)} block>
                                        Add a subject of interest
                                    </Button>
                                </div>
                            ) : (
                                <FormGroup
                                    label={
                                        <React.Fragment>
                                            Subject(s) of interest
                                            <Button
                                                className={s.dontKnowYet}
                                                onClick={() => setDontKnowYet(!dontKnowYet)}
                                                link
                                            >
                                                {"I don't know yet"}
                                            </Button>
                                        </React.Fragment>
                                    }
                                >
                                    <Autocomplete
                                        id="degreeCategories"
                                        name="degreeCategories"
                                        labelKey="name"
                                        options={
                                            (degreeCategoriesData &&
                                                degreeCategoriesData.degreeCategories) ||
                                            []
                                        }
                                        placeholder="Select one or more subjects of interest"
                                        multiple
                                    />
                                </FormGroup>
                            )}
                            <FormGroup label="Year Of Entry">
                                <DateOfEntrySelect
                                    entryMonths={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                                    placeholder="Select a date"
                                    name="yearOfEntry"
                                />
                            </FormGroup>
                            <CollapsiblePrivacyText marketplace={marketplace} />
                            {get(
                                marketplace,
                                "marketplaceMarketingConsentCollectionEnabled",
                                false,
                            ) ? (
                                <CheckBox
                                    name="marketingConsentAccepted"
                                    data-test-id="consent"
                                    id="consent"
                                    className={s.consent}
                                >
                                    {getMarketingText(marketplace)}
                                </CheckBox>
                            ) : null}
                            <Stack align="center">
                                <ErrorMessage name="serverError" />
                                <SubmitButton color="primary">Continue</SubmitButton>
                            </Stack>
                        </React.Fragment>
                    )}
                </Form>
            </NarrowContainer>
        );
    }
    return props.render();
};

export const CollapsiblePrivacyText = ({marketplace}) => (
    <div className={s.collapsible}>
        <Collapsible trigger="How do we use your personal information?">
            <p>
                This messaging service is provided by Unibuddy Ltd, which is working with{" "}
                <b>{marketplace.name}</b>
            </p>
            <p>
                You will be registering directly with Unibuddy and any information you provide,
                through the registration process or in your chats, will be held and managed by
                Unibuddy.
                {get(marketplace, "personalDataApiEnabled", false)
                    ? `As part of the chat service your details will be shared with ${
                          marketplace.name
                      }`
                    : null}
            </p>
            <p>
                Unibuddy&apos; full service{" "}
                <ThemedLink external to={TERMS_OF_USE} target="_blank">
                    Terms of Use
                </ThemedLink>{" "}
                and{" "}
                <ThemedLink external to={PRIVACY_POLICY} target="_blank">
                    Privacy Policy
                </ThemedLink>
                {get(marketplace, "personalDataApiEnabled", false) ? (
                    <Fragment>
                        , {marketplace.name}&apos;s{" "}
                        <ThemedLink
                            external
                            to={`${get(marketplace, "privacyPolicyUrl")}${PRIVACY_FRAGMENT}`}
                            target="_blank"
                        >
                            Privacy Policy
                        </ThemedLink>
                    </Fragment>
                ) : null}
            </p>
        </Collapsible>
    </div>
);

export function getMarketingText(marketplace) {
    const defaultConsentContent = (
        <Fragment>
            <p>
                I would like to receive marketing communications from {marketplace.name} via email.
                Any communications will containe information that supports progression to Higher
                Education
            </p>
            <p>
                You can withdraw consent at any time by changing your settings at Unibuddy and by
                the institution&apos;s unsubscribe process.
            </p>
        </Fragment>
    );
    return get(marketplace, "marketplaceMarketingConsentMessage", "") ? (
        <ReactMarkdown
            source={get(marketplace, "marketplaceMarketingConsentMessage", "")}
            renderers={{
                link: ({href, children, nodeKey}) => (
                    <ThemedLink key={nodeKey} external to={href} target="_blank">
                        {children}
                    </ThemedLink>
                ),
            }}
        />
    ) : (
        defaultConsentContent
    );
}

export const defaultSignUpMarketingText = marketplaceName => (
    <Box>
        <Box paddingBottom="small">{`This messaging service is provided by Unibuddy Ltd, which is working with ${marketplaceName}`}</Box>
        <Box>{`You will be registering directly with Unibuddy and any information you provide, through the registration process or
            in your chats, will be held and managed by Unibuddy`}</Box>
    </Box>
);

export const CollapsibleDiscoverPrivacyText = ({marketplace}) => (
    <Box>
        <Collapsible trigger="How do we use your personal information?">
            <Box paddingBottom="medium">
                Unibuddy collects personal data on behalf on institutions that you chat with to
                operate effectively and provide you with the best experience from our services.
            </Box>
            <Box paddingBottom="medium">
                You provide some of the data directly, e.g. when you create an account, fill out
                forms, chat with student ambassadors or contact us for support.
            </Box>
            <Box paddingBottom="medium">
                We get some of it by recording how you interact with our services by, e.g. using
                technologies similar to cookies.
            </Box>
            {get(marketplace, "personalDataApiEnabled", false) ? (
                <Box paddingBottom="medium">
                    In addition we share your name and email address with{" "}
                    {get(marketplace, "name", "")} so they can send a follow up email to you on
                    behalf of education providers that you have messaged.
                </Box>
            ) : null}
            {get(marketplace, "unibuddyMarketingConsentEnabled", false) ? (
                <Box paddingBottom="medium">
                    Additionally if you tick to &apos;learn more about other services from
                    Unibuddy&apos; we will contact you via email to make you aware of other Unibuddy
                    services that support successful education progression.
                </Box>
            ) : null}
            {get(marketplace, "marketplaceMarketingConsentCollectionEnabled", false) ? (
                <Box paddingBottom="medium">
                    If you tick to &apos;receive marketing consent&apos; from{" "}
                    {get(marketplace, "name", "")} you will receive communications from{" "}
                    {get(marketplace, "name", "")} via email containing information linked with
                    Higher Education.
                </Box>
            ) : null}
            {get(marketplace, "marketplaceMarketingConsentCollectionEnabled", false) &&
            get(marketplace, "marketplaceMarketingConsentMessage", false) ? (
                <Box paddingBottom="medium">
                    {get(marketplace, "marketplaceMarketingConsentMessage", "")}
                </Box>
            ) : null}
            <Box paddingBottom="medium">
                You can withdraw the consent at anytime by updating your Unibuddy Account Settings.
                {get(marketplace, "marketplaceMarketingConsentCollectionEnabled", false) ? (
                    <React.Fragment>
                        {" "}
                        Or follow the unsubscribe process linked to from{" "}
                        {get(marketplace, "name", "")} communications.
                    </React.Fragment>
                ) : null}
            </Box>
            <Box>
                {"For more information on how Unibuddy uses your data, please read our "}
                <ThemedLink external to={PRIVACY_POLICY} target="_blank">
                    Privacy Policy
                </ThemedLink>
            </Box>
        </Collapsible>
    </Box>
);

export const AcceptPolicyCheckboxes = ({marketplace}) => (
    <Box>
        {get(marketplace, "unibuddyMarketingConsentEnabled", false) ? (
            <CheckBox
                name="unibuddyServicesConsentAccepted"
                data-test-id="unibuddy-services-consent"
                id="unibuddy-services-consent"
                className={s.consent}
            >
                <Box>I would like to learn about other services from Unibuddy.</Box>
            </CheckBox>
        ) : null}
        {get(marketplace, "marketplaceMarketingConsentCollectionEnabled", false) ? (
            <CheckBox
                name="marketingConsentAccepted"
                data-test-id="consent"
                id="consent"
                className={s.consent}
            >
                I would like to receive marketing communications from {get(marketplace, "name", "")}{" "}
                via email. {marketplace.name}&apos;s{" "}
                <ThemedLink external to={get(marketplace, "privacyPolicyUrl")} target="_blank">
                    Privacy Policy
                </ThemedLink>
            </CheckBox>
        ) : null}
    </Box>
);

export const UbDiscoverTermsConditionText = ({marketplace}) => (
    <Box>
        By clicking continue you agree to Unibuddy&apos; full service{" "}
        <ThemedLink external to={TERMS_OF_USE} target="_blank">
            Terms of Use
        </ThemedLink>{" "}
        and{" "}
        <ThemedLink external to={PRIVACY_POLICY} target="_blank">
            Privacy Policy
        </ThemedLink>
        {get(marketplace, "personalDataApiEnabled", false) ? (
            <Fragment>
                , {marketplace.name}&apos;s{" "}
                <ThemedLink external to={get(marketplace, "privacyPolicyUrl")} target="_blank">
                    Privacy Policy
                </ThemedLink>
            </Fragment>
        ) : null}
    </Box>
);
