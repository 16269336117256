import {useEffect} from "react";
import formatDistance from "date-fns/formatDistance";
import "url-search-params-polyfill";
import {useMarketplaceConfig} from "./MarketplaceConfigProvider";

// eslint-disable-next-line import/prefer-default-export
export function useWindowTitle(title) {
    useEffect(
        () => {
            window.document.title = title;
        },
        [title],
    );

    return title;
}

export function distanceFormattedDate(rawDate) {
    // Safari is not supporting date format with spaces
    // TODO: tests needs to be written for this usecase
    // We need to add the 'Z' at the end because javascript is
    // assuming this to be a local time instead of utc,
    // need to fix the api instead
    return formatDistance(new Date(`${rawDate.replace(' ', 'T')}Z`), new Date(), {addSuffix: true, includeSeconds: true});
}

export function getBuddyPosition(queryString = window.location.search) {
    const searchParams = new URLSearchParams(queryString);
    return searchParams.get("buddyPosition");
}

export function createCallbackUrl(redirectUrl, callbackUrl) {
    if (redirectUrl && !redirectUrl.includes('redirect_uri=')) {
        redirectUrl += `&redirect_uri=${encodeURIComponent(callbackUrl)}`;
    }
    return redirectUrl;
}

// temporary till this ticket is completed
// https://unibuddy.atlassian.net/browse/XS-2023
export function useIsUcas() {
    const {marketplace} = useMarketplaceConfig();
    return {
        isUcas: marketplace.slug === "ucas",
    };
}
