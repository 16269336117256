import React from "react";
import {useLocation, useHistory} from "react-router";
import isEqual from "lodash/isEqual";
import {useQuery as useGraphQuery} from "@apollo/client";
import filerNameQuery from "../../../../discover/src/Buddies/queries/filterNamesQuery";

export const FiltersContext = React.createContext();

export const useBuddiesFilters = () => {
    return React.useContext(FiltersContext);
};
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const location = useLocation();
    return new URLSearchParams(location.search);
}

function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

export function useFetchNamesForFilterIds(filters, setFilters) {
    const oldFilters = usePrevious(filters);
    const variables = {
        degreeCategoryId: filters.subCategory || filters.subject,
        countryId: filters.countryId,
        slug: filters.showBuddiesFrom,
        includeCountry: !!filters.countryId,
        includeUniversity: !!filters.showBuddiesFrom,
        includeDegreeCategory: !!filters.subCategory || !!filters.subject,
    };
    const {data} = useGraphQuery(filerNameQuery, {
        variables,
        notifyOnNetworkStatusChange: true,
    });

    React.useEffect(() => {
        if (data) {
            const {university, country, degreeCategory} = data;
            const newFilters = {
                ...filters,
                ...(university ? {universityName: university.name} : {}),
                ...(filters.countryId && country ? {country: country.name} : {}),
            };
            if (filters.subCategory && degreeCategory) {
                newFilters.subCategoryName = degreeCategory.name;
            } else if (filters.subject && degreeCategory) {
                newFilters.subjectName = degreeCategory.name;
            }
            setFilters(newFilters);
        }
    }, [data, !isEqual(oldFilters, filters)]); //eslint-disable-line
}

export function objectToParams(obj) {
    const params = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
        if (obj[key]) {
            params.set(key, obj[key]);
        }
    });
    return params;
}
export function paramsToObject(entries) {
    const result = {};

    if (!entries || !entries[Symbol.iterator]) {
        return {};
    }
    for (const entry of entries) {
        // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        result[key] = value;
    }
    return result;
}

/**\
 * Function to init the filter from the url
 * used only once in the app
 *  (that's why the use effect has not deps)
 */
export function useInitFilters(setFilters) {
    const query = useQuery();
    React.useEffect(() => {
        const entries = query.entries();
        setFilters(paramsToObject(entries));
    }, []) //eslint-disable-line
}

export function syncUrl(filters, history) {
    const castedFilters = objectToParams(filters).toString();
    history.replace({
        search: castedFilters,
    });
}

/**\
 * Function to sync the url with the filters
 * onMount (that's why the use effect has not deps)
 */
export function useSyncUrlOnce(filters) {
    const history = useHistory();
    React.useEffect(() => {
        syncUrl(filters, history);
    }, []) //eslint-disable-line
}

export function useSyncUrl(filters) {
    const history = useHistory();
    React.useEffect(() => {
        syncUrl(filters, history);
    }, [filters, history]);
}

export default function BuddiesFiltersProvider({children}) {
    const [filters, setFilters] = React.useState({});
    useInitFilters(setFilters);
    useSyncUrl(filters);

    return (
        <FiltersContext.Provider
            value={{
                filters,
                setFilters,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
}
