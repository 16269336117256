import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import get from "lodash/get";
import noop from "lodash/noop";
import classNames from "classnames";
import {Trans, useTranslation} from "@unibuddy/intl";
import Carousel from "../../../../General/components/Carousel/Carousel";
import MentorCarouselCard from "../../components/MentorCarouselCard/MentorCarouselCard";
import MentorCardContainer from "../MentorCardContainer/MentorCardContainer";
import s from "./MentorCarousel.pcss";
import PillButton from "../../../../General/components/PillButton/PillButton";
import MentorPlaceholder from "../../../../Mentor/components/MentorPlaceholder/MentorPlaceholder";

export const Slide = ({children, ...props}) => (
    <div {...props} className={s.slide}>
        {children}
    </div>
);

export const getNumberOfSlides = ({isSmall, isMedium, isLarge}) => {
    if (isLarge) return 3;
    if (isMedium || isSmall) return 2;
    return 1;
};

function MentorCarousel({mentors, onChatNow, onSeeMore, loading, isExternal, ...props}) {
    const {t} = useTranslation("mentorCard");
    /* Users of MentorCarousel may choose to customize media queries by supplying their own
     * values through props. For example, discover carousel defines large screen width as 1280px.
     */
    const largeScreenWidth = get(props, "largeScreenWidth", "1176px");

    return (
        <MediaQuery query={`(min-width: ${largeScreenWidth})`}>
            {isLarge => (
                <MediaQuery query="(min-width: 768px)">
                    {isMedium => (
                        <MediaQuery query="(min-width: 650px)">
                            {isSmall => {
                                if (loading) {
                                    const loadingItems = new Array(
                                        getNumberOfSlides({isSmall, isMedium, isLarge}),
                                    ).fill(1);
                                    return (
                                        <div
                                            id="carousel-loading"
                                            className={classNames(s.carousel, s.carouselLoading)}
                                        >
                                            {loadingItems.map((_, i) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <Slide key={i}>
                                                    <MentorPlaceholder lines={3} />
                                                </Slide>
                                            ))}
                                        </div>
                                    );
                                }

                                return (
                                    <div
                                        id="carousel-container"
                                        className={s.carousel}
                                        data-test-id="carousel-container"
                                    >
                                        <Carousel
                                            {...props}
                                            dots={false}
                                            slidesToScroll={1}
                                            slidesToShow={getNumberOfSlides({
                                                isSmall,
                                                isMedium,
                                                isLarge,
                                            })}
                                            arrowColor="#626262"
                                        >
                                            {mentors.map(mentor => (
                                                <Slide key={mentor.id}>
                                                    {props.renderMentorCard ? (
                                                        props.renderMentorCard(mentor)
                                                    ) : (
                                                        <MentorCarouselCard
                                                            className={
                                                                isSmall && !isMedium && !isLarge
                                                                    ? s.carouselContainer
                                                                    : {}
                                                            }
                                                            onChatNow={onChatNow}
                                                            profilePhoto={mentor.profilePhoto}
                                                            mentorId={mentor.id}
                                                            firstName={mentor.firstName}
                                                            degreeName={get(
                                                                mentor,
                                                                "degree.name",
                                                                "",
                                                            )}
                                                            degreeLevel={get(
                                                                mentor,
                                                                "degreeLevel",
                                                                "",
                                                            )}
                                                            placeLabel={get(
                                                                mentor,
                                                                "placeName",
                                                                "",
                                                            )}
                                                            countryCode={get(
                                                                mentor,
                                                                "country.code",
                                                                "",
                                                            )}
                                                            primaryFlag={
                                                                get(
                                                                    mentor,
                                                                    "mentorFields.primaryFlag.code",
                                                                ) || ""
                                                            }
                                                            secondaryFlag={
                                                                get(
                                                                    mentor,
                                                                    "mentorFields.secondaryFlag.code",
                                                                ) || ""
                                                            }
                                                            languages={get(mentor, "languages", [])}
                                                            year={get(mentor, "year", "")}
                                                            lastSeen={mentor.lastSeen}
                                                            isExternal={isExternal}
                                                            isShowCountryFlagEnabled={
                                                                props.isShowCountryFlagEnabled
                                                            }
                                                        />
                                                    )}
                                                </Slide>
                                            ))}
                                            {props.showSeeMore ? (
                                                <Slide>
                                                    <MentorCardContainer className={s.lastSlide}>
                                                        <PillButton large onClick={onSeeMore}>
                                                            {t("seeMore", "See more students")}
                                                        </PillButton>
                                                        <p className={s.seeMoreDescription}>
                                                            <Trans
                                                                t={t}
                                                                i18nKey={"seeMoreDescription"}
                                                            >
                                                                Read their profiles <br />
                                                                Ask them anything about their
                                                                experience <br />
                                                            </Trans>
                                                            {t("happyChatting", "Happy chatting!")}
                                                        </p>
                                                    </MentorCardContainer>
                                                </Slide>
                                            ) : null}
                                        </Carousel>
                                    </div>
                                );
                            }}
                        </MediaQuery>
                    )}
                </MediaQuery>
            )}
        </MediaQuery>
    );
}

MentorCarousel.propTypes = {
    mentors: PropTypes.array.isRequired,
    onChatNow: PropTypes.func,
    onSeeMore: PropTypes.func,
    loading: PropTypes.bool,
    showSeeMore: PropTypes.bool,
    isExternal: PropTypes.bool,
};

MentorCarousel.defaultProps = {
    onChatNow: noop,
    onSeeMore: noop,
    loading: true,
    showSeeMore: true,
    isExternal: false,
};

export default MentorCarousel;
