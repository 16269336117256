import React from "react";
import get from "lodash/get";
import IoIosPeople from "react-icons/lib/io/ios-people";
import IoIosChatboxes from "react-icons/lib/io/ios-chatboxes";
import IoIosSettings from "react-icons/lib/io/android-settings";
import {useTranslation} from "@unibuddy/intl";
import {usePopup} from "@unibuddy/ssomodule";
import Nav from "../Nav/Nav";
import NavLink from "../NavLink/NavLink";
import InboxUnreadCountQuery from "../../../Inbox/components/InboxUnreadCountQuery/InboxUnreadCountQuery";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";
import FilterDependentComponent from "../../../shared/components/FilterDependentComponent/FilterDependentComponent";

export default function Navigation(props) {
    const {t} = useTranslation("common");
    const {marketplace} = useMarketplaceConfig();
    const customBuddyWord = get(marketplace, "customBuddyWord", "Buddies");
    const {handleClick} = usePopup();

    return (
        <Nav>
            <FilterDependentComponent filterKey="hideBuddiesNavItem" hideComponentWhenConditionTrue>
                <NavLink to={`/${marketplace.slug}/buddies`}>
                    <IoIosPeople /> {t(customBuddyWord, customBuddyWord)}
                </NavLink>
            </FilterDependentComponent>
            {props.user ? (
                <InboxUnreadCountQuery
                    render={({count}) => (
                        <NavLink to={`/${marketplace.slug}/inbox`} notification={count} onClick={handleClick}>
                            <IoIosChatboxes /> {t("inbox", "Inbox")}
                        </NavLink>
                    )}
                />
            ) : (
                <NavLink to={`/${marketplace.slug}/inbox`} notification={0} onClick={handleClick}>
                    <IoIosChatboxes /> {t("inbox", "Inbox")}
                </NavLink>
            )}
            <NavLink to={`/${marketplace.slug}/settings`} onClick={handleClick}>
                <IoIosSettings /> {t("settings", "Settings")}
            </NavLink>
        </Nav>
    );
}
