import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/** Custom Components */
import Flag from "../../../Buddies/components/Flag/Flag";

/** CSS Styles */
import s from "./MentorFlags.pcss";

//--------------------------------------------------------------------------------

function MentorFlags(props) {
    return (
        <div className={s.flagContainer}>
            {props.countryCode && (
                <Flag
                    aria-label={`Primary flag ${props.primaryFlag}`}
                    className={classNames(s.flag, props.secondaryFlag ? s.leftFlag : s.rightFlag)}
                    countryCode={props.countryCode}
                    primaryFlag={props.primaryFlag}
                    position={props.secondaryFlag ? "left" : "right"}
                />
            )}
            {props.countryCode && props.secondaryFlag && (
                <Flag
                    aria-label={`Secondary flag ${props.secondaryFlag}`}
                    className={s.flag}
                    countryCode={props.countryCode}
                    secondaryFlag={props.secondaryFlag}
                />
            )}
        </div>
    );
}

MentorFlags.propTypes = {
    countryCode: PropTypes.string,
    primaryFlag: PropTypes.string,
    secondaryFlag: PropTypes.string,
};

MentorFlags.defaultProps = {
    countryCode: "",
    primaryFlag: "",
    secondaryFlag: "",
};

export default MentorFlags;
