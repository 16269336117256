import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./Badge.pcss";
import {getAccessibleColor} from "../ColorPanel/ColorPanel";

function Badge({color, className, children, size, elevation, ...htmlSafeProps}) {
    if (["default", "violet", "success", "primary", "danger", "light"].includes(color)) {
        return (
            <div
                {...htmlSafeProps}
                className={classNames(
                    s.text,
                    s[`${size}Size`],
                    s[color],
                    s[`elevation${elevation}`],
                    className,
                )}
            >
                {children}
            </div>
        );
    }
    return (
        <div
            {...htmlSafeProps}
            className={classNames(s.text, s[`elevation${elevation}`], s[`${size}Size`], className)}
            style={{
                color: getAccessibleColor(color),
                backgroundColor: color,
            }}
        >
            {children}
        </div>
    );
}

Badge.propTypes = {
    color: PropTypes.oneOf(["default", "violet", "success", "primary", "danger", "light"]),
    size: PropTypes.oneOf(["standard", "small"]),
    elevation: PropTypes.number,
};

Badge.defaultProps = {
    color: "default",
    size: "standard",
    elevation: 0,
};

export default Badge;
