import React, {useContext} from "react";
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    ApolloLink,
    HttpLink,
    from,
} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import get from "lodash/get";
import {createCustomFetch} from "@unibuddy/machop/Core/defaultLink";
import {config} from "@unibuddy/machop/ConfigManager/ConfigManager";
import AuthProvider from "./Auth/components/AuthProvider/AuthProvider";

const httpLink = new HttpLink({
    uri: `${config.GATEWAY_URL}/graphql`,
    credentials: "same-origin",
    fetch: createCustomFetch(),
});
const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                marketplaceMentorList: {
                    keyArgs: (args) => {
                        const {skip, mentorSeed, universitySeed, ...rest} = args;
                        return JSON.stringify(rest);
                    },
                    merge(existing, incoming) {
                        if (!incoming) return existing;
                        if (!existing) return incoming;

                        const {mentors, ...rest} = incoming;

                        const result = rest;
                        result.mentors = [...existing.mentors, ...mentors];

                        return result;
                    },
                },
            },
        },
        ApplicantMarketplaceObjectField: {
            keyFields: false,
        },
    },
});

const errorMiddleware = (setAuthState) =>
    onError(({graphQLErrors, networkError}) => {
        if (
            networkError &&
            "statusCode" in networkError &&
            networkError.statusCode === 401
        ) {
            console.log(`[Unauthorized]: The user token is invalid.`);
            setAuthState(undefined);
        }

        if (graphQLErrors)
            graphQLErrors.map(({message, locations, path}) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
            );
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

const authMiddleware = (token, scheme) =>
    new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext(({headers = {}}) => ({
            headers: {
                ...headers,
                authorization: token ? `${scheme} ${token}` : null,
            },
        }));

        return forward(operation);
    });

const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key, value) =>
            key === "__typename" ? undefined : value;
        operation.variables = JSON.parse(
            JSON.stringify(operation.variables),
            omitTypename,
        );
    }
    return forward(operation).map((data) => {
        return data;
    });
});

export default function ApiProvider(props) {
    const {authState, setAuthState} = useContext(AuthProvider.Context);
    const token = get(authState, "accessToken", false);
    const scheme = get(authState, "scheme", "JWT");
    const authMiddlewareLink = authMiddleware(token, scheme);
    // We have to "bootstrap" apollo provider client inside a react component because
    // the middlewareLink function needs access to the authState
    // (in order to add the auth token to every request)

    const client = new ApolloClient({
        link: from([
            cleanTypeName,
            errorMiddleware(setAuthState),
            authMiddlewareLink,
            httpLink,
        ]),
        cache,
    });
    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
