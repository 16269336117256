const createDispatchers = (dispatch, setFilter) => {
    const setTouched = isTouched => {
        dispatch({
            type: "setTouched",
            payload: isTouched,
        });
    };

    const selectFilter = option => {
        setFilter(option);
        dispatch({
            type: "selectFilter",
            payload: option,
        });
    };

    const deselectFilter = () => {
        setFilter(null);
        dispatch({
            type: "deselectFilter",
        });
    };

    const setHighlighted = index => {
        dispatch({
            type: "setHighlighted",
            payload: index,
        });
    };

    const setText = val => {
        dispatch({
            type: "setText",
            payload: val,
        });
    };

    return [setTouched, selectFilter, deselectFilter, setHighlighted, setText];
};

export default createDispatchers;
