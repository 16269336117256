import React from "react";
import find from "lodash/find";
import get from "lodash/get";
import {toTitleCase} from "@unibuddy/machop/Utils/StringUtils";
import useAnalytics from "@unibuddy/machop/AnalyticsNew/hooks/useAnalytics";
import Button from "@unibuddy/machop/Experimental/General/components/Button/Button";
import ArrowLeft from "@unibuddy/machop/Experimental/Icons/ArrowLeft";
import Box from "@unibuddy/machop/Experimental/Layout/components/Box/Box";
import ListFilter from "../ListFilter/ListFilter";
import marketplaceMentorRegions from "../../queries/marketplaceMentorRegions";
import {Filter, FilterBody, FilterQuery, FilterTitle} from "../Filter/Filter";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";
import s from "./RegionFilter.pcss";

const RegionFilter = ({filters, setFilters, onBack}) => {
    const {
        events: {buddiesFiltered},
    } = useAnalytics();
    const {marketplace} = useMarketplaceConfig();
    return (
        <Filter id="region-filter-wrapper">
            <FilterTitle id="region-filter" className={s.title}>
                {onBack ? (
                    <Button
                        size="sm"
                        clear
                        iconOnly
                        onClick={() => {
                            setFilters({
                                ...filters,
                                domesticRegion: undefined,
                            });
                            onBack();
                        }}
                        data-test-id="back-btn"
                    >
                        <ArrowLeft />
                    </Button>
                ) : null}
                Region
                <span className={s.country}>{filters.universityCountryName}</span>
            </FilterTitle>
            <FilterQuery
                query={marketplaceMentorRegions}
                variables={{
                    ...filters,
                    marketplaceSlug: marketplace.slug,
                    degreeCategoryId: filters.subCategory || filters.subject || undefined,
                }}
            >
                {({data}) => {
                    return get(data, "marketplaceMentorRegions", []).length > 0 ? (
                        <FilterBody>
                            <ListFilter
                                id="region-filter"
                                placeholder={"Choose a region"}
                                options={data.marketplaceMentorRegions.map(x => ({
                                    ...x,
                                    name: toTitleCase(x.name),
                                }))}
                                filter={find(data.marketplaceMentorRegions, {
                                    id: filters.domesticRegion,
                                })}
                                setFilter={region => {
                                    if (region) {
                                        buddiesFiltered({
                                            filterKey: "domesticRegion",
                                            filterValue: region.name,
                                            buddyType: "mentor",
                                        });
                                    }
                                    setFilters({
                                        ...filters,
                                        degreeCategoryId:
                                            filters.subCategory || filters.subject || undefined,
                                        domesticRegion: get(region, "id", undefined),
                                    });
                                }}
                            />
                        </FilterBody>
                    ) : (
                        <Box padding="xxlarge">No regions found</Box>
                    );
                }}
            </FilterQuery>
        </Filter>
    );
};

export default RegionFilter;
