import React, {PureComponent} from "react";
import get from "lodash/get";
import noop from "lodash/noop";
import {sourceTracking} from "@unibuddy/machop/AnalyticsNew/constants/productSpecification";
import AnalyticsValue from "@unibuddy/machop/AnalyticsNew/AnalyticsValue/AnalyticsValue";
import {injectSourceTrackingParamsInUrl} from "@unibuddy/machop/Utils/UrlUtils";
import PropTypes from "prop-types";
import engine from "store";
import localStorage from "store/storages/localStorage";

let localStore = engine.createStore(localStorage);
if (!localStore.storage) {
    localStore = {
        store: {},
        // eslint-disable-next-line
        get: function(key) {
            return this.store[key];
        },
        // eslint-disable-next-line
        set: function(key, value) {
            this.store[key] = value;
        },
    };
}

export const SourceTrackingContext = React.createContext({
    updateSourceTrackingParamLocally: noop,
    saveSourceTrackingParams: noop,
    redirectWithSourceTracking: noop,
});

/**
 * This class allows components to store source tracking params and use them when doing a redirect
 */
export default class SourceTrackingProvider extends PureComponent {
    static propTypes = {
        params: PropTypes.object.isRequired,
    };

    /**
     * Retrieve saved source tracking params
     * Source tracking params are ub_medium, ub_source, ub_campaign & ub_content
     */
    static getSourceTrackingParams() {
        return {
            //eslint-disable-next-line dot-notation
            ub_medium: localStore.get(sourceTracking.sourceTrackingParams.properties.UB_MEDIUM),
            //eslint-disable-next-line dot-notation
            ub_source: localStore.get(sourceTracking.sourceTrackingParams.properties.UB_SOURCE),
            //eslint-disable-next-line dot-notation
            ub_campaign: localStore.get(sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN),
            //eslint-disable-next-line dot-notation
            ub_content: localStore.get(sourceTracking.sourceTrackingParams.properties.UB_CONTENT),
        };
    }

    /**
     * Adds source tracking params to Analytics
     * @params - the source tracking params to save. Source tracking params are ub_medium, ub_source,
     * ub_campaign & ub_content
     */
    setSourceTrackingInAnalytics = params => {
        return (
            <React.Fragment>
                <AnalyticsValue
                    name={sourceTracking.sourceTrackingParams.properties.UB_MEDIUM}
                    value={get(
                        params,
                        sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
                        "",
                    )}
                />
                <AnalyticsValue
                    name={sourceTracking.sourceTrackingParams.properties.UB_SOURCE}
                    value={get(
                        params,
                        sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
                        "",
                    )}
                />
                <AnalyticsValue
                    name={sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN}
                    value={get(
                        params,
                        sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN,
                        "",
                    )}
                />
                <AnalyticsValue
                    name={sourceTracking.sourceTrackingParams.properties.UB_CONTENT}
                    value={get(
                        params,
                        sourceTracking.sourceTrackingParams.properties.UB_CONTENT,
                        "",
                    )}
                />
            </React.Fragment>
        );
    };

    /**
     * Stores the source tracking params locally and also adds it to Analytics
     * @params - the source tracking params to save. Source tracking params are ub_medium, ub_source,
     * ub_campaign & ub_content
     */
    saveSourceTrackingParams = params => {
        localStore.set(
            sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
            get(params, sourceTracking.sourceTrackingParams.properties.UB_MEDIUM, ""),
        );
        localStore.set(
            sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
            get(params, sourceTracking.sourceTrackingParams.properties.UB_SOURCE, ""),
        );
        localStore.set(
            sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN,
            get(params, sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN, ""),
        );
        localStore.set(
            sourceTracking.sourceTrackingParams.properties.UB_CONTENT,
            get(params, sourceTracking.sourceTrackingParams.properties.UB_CONTENT, ""),
        );

        return this.setSourceTrackingInAnalytics(params);
    };

    /**
     * Updates the source tracking params locally
     * @params - the source tracking params to save. Source tracking params are ub_medium, ub_source,
     * ub_campaign & ub_content
     */
    updateSourceTrackingParamLocally = (name, value) => {
        localStore.set(name, value);
    };

    /**
     * Injects the stored source tracking params into toUrl and returns it
     * Use this to propagate source tracking params when redirecting the user to a new component
     * @toUrl - the url to redirect to
     */
    redirectWithSourceTracking = toUrl => {
        const sourceTrackingParams = {
            //eslint-disable-next-line dot-notation
            ub_medium: localStore.get(sourceTracking.sourceTrackingParams.properties.UB_MEDIUM),
            //eslint-disable-next-line dot-notation
            ub_source: localStore.get(sourceTracking.sourceTrackingParams.properties.UB_SOURCE),
            //eslint-disable-next-line dot-notation
            ub_campaign: localStore.get(sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN),
            //eslint-disable-next-line dot-notation
            ub_content: localStore.get(sourceTracking.sourceTrackingParams.properties.UB_CONTENT),
        };

        return injectSourceTrackingParamsInUrl(toUrl, sourceTrackingParams);
    };

    render() {
        return (
            <React.Fragment>
                {this.saveSourceTrackingParams(this.props.params)}
                <SourceTrackingContext.Provider
                    value={{
                        updateSourceTrackingParamLocally: this.updateSourceTrackingParamLocally,
                        saveSourceTrackingParams: this.saveSourceTrackingParams,
                        redirectWithSourceTracking: this.redirectWithSourceTracking,
                        getSourceTrackingParams: SourceTrackingProvider.getSourceTrackingParams,
                    }}
                >
                    {this.props.children}
                </SourceTrackingContext.Provider>
            </React.Fragment>
        );
    }
}
