import {gql} from "@apollo/client";

const buddiesQuery = gql`
    query MentorListQuery(
        $marketplaceSlug: String
        $skip: Int
        $mentorSeed: Int
        $universitySeed: Int
        $domesticRegion: String
        $universityId: String
        $universitySlug: String
        $degreeCategoryId: String
        $degreeLevel: String
        $countryId: String
        $universityCountry: String
    ) {
        marketplaceMentorList(
            marketplaceSlug: $marketplaceSlug
            safeLimit: 18
            skip: $skip
            mentorSeed: $mentorSeed
            universitySeed: $universitySeed
            domesticRegion: $domesticRegion
            degreeCategoryId: $degreeCategoryId
            universityId: $universityId
            universitySlug: $universitySlug
            degreeLevel: $degreeLevel
            countryId: $countryId
            universityCountry: $universityCountry
        ) {
            mentors {
                id
                firstName
                profilePhoto
                university {
                    id
                    name
                    logo
                }
                degree {
                    id
                    name
                }
                country {
                    id
                    code
                    name
                }
                shortBio
                mentorFields {
                    primaryFlag {
                        name
                        code
                    }
                    secondaryFlag {
                        name
                        code
                    }
                }
            }
            mentorSeed
            universitySeed
            totalMentors
        }
    }
`;

export default buddiesQuery;
