/** Libraries * */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/** Custom * */
import LabelText from "../LabelText/LabelText";

/** Styles * */
import s from "./Label.pcss";

const Label = ({children, id, inline, ...props}) => (
    <label
        htmlFor={id}
        className={classNames(s.container, {[s.inline]: inline})}
        data-test-id={`label-${id}`}
    >
        <LabelText {...props}>{children}</LabelText>
    </label>
);

Label.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    required: PropTypes.bool,
};

Label.defaultProps = {
    error: "",
    touched: false,
    required: false,
};

export default Label;
