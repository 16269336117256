import React from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import omit from "lodash/omit";
import classNames from "classnames";
import s from "./TextInput.pcss";

const TextInput = React.forwardRef(({className, hasError, type = "text", ...props}, ref) => (
    <input
        {...omit(props, ["setFieldValue"])}
        type={type}
        ref={ref}
        className={classNames(s.input, className, {
            [s.error]: hasError,
        })}
    />
));

TextInput.propTypes = {
    hasError: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

TextInput.defaultProps = {
    hasError: false,
    onChange: noop,
    className: "",
};

export default TextInput;
