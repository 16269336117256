import React, {Component} from "react";
import get from "lodash/get";
import {isFunction} from "util";
import PropTypes from "prop-types";
import {Formik, Form as FormikForm} from "formik";
import {object} from "yup";
import omit from "lodash/omit";

export const FormContext = React.createContext();

export function withForm(WrappedComponent) {
    // eslint-disable-next-line func-names
    return function WithFormWrapper(props) {
        return (
            <FormContext.Consumer>
                {({setFieldOverride, removeFieldOverride}) => (
                    <WrappedComponent
                        setFieldOverride={setFieldOverride}
                        removeFieldOverride={removeFieldOverride}
                        {...props}
                    />
                )}
            </FormContext.Consumer>
        );
    };
}

export default class Form extends Component {
    static propTypes = {
        initialValues: PropTypes.object,
        validationSchema: PropTypes.object,
    };

    static defaultProps = {
        initialValues: {},
        validationSchema: null,
    };

    state = {
        validationSchemaOverrides: null,
    };

    setFieldOverride = (name, {schema}) => {
        this.setState(state => ({
            validationSchemaOverrides: {
                ...state.validationSchemaOverrides,
                [name]: schema,
            },
        }));
    };

    removeFieldOverride = name => {
        this.setState(state => ({
            validationSchemaOverrides: omit(state.validationSchemaOverrides, [name]),
        }));
    };

    render() {
        const {children, initialValues, validationSchema, onSubmit, ...props} = this.props;
        const {validationSchemaOverrides} = this.state;

        return (
            <FormContext.Provider
                value={{
                    setFieldOverride: this.setFieldOverride,
                    removeFieldOverride: this.removeFieldOverride,
                }}
            >
                <Formik
                    validationSchema={object({
                        ...validationSchema,
                        ...validationSchemaOverrides,
                    })}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={get(props, "enablereinitialize", false)}
                >
                    {isFunction(children) ? (
                        ({handleChange, handleBlur, ...formik}) => (
                            <FormikForm noValidate {...props}>
                                {children({
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    ...formik,
                                })}
                            </FormikForm>
                        )
                    ) : (
                        <FormikForm noValidate {...props}>
                            {children}
                        </FormikForm>
                    )}
                </Formik>
            </FormContext.Provider>
        );
    }
}
