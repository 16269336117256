import {isValid, isSameDay, intervalToDuration, differenceInMilliseconds, getUnixTime, parseJSON} from 'date-fns';
import PropTypes from "prop-types";

export const ISOStringFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";
export const ISOStringFormatNew = "yyyy-MM-dd'T'HH:mm:ss";
export const SECONDS = 1000;
export const MINUTES = 60 * SECONDS;
export const HOURS = 60 * MINUTES;
export const DAYS = 24 * HOURS;

export const UNITS = {
    SECONDS: "SECONDS",
    MINUTES: "MINUTES",
    HOURS: "HOURS",
    DAYS: "DAYS",
    MONTHS: "MONTHS",
    YEARS: "YEARS",
};

export const datePropTypes = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
]);


/**
 * Converts a given UTC time to the local time.
 *
 * We need this to convert the time we get from the server (UTC time) to the correct format on
 * all clients.
 *
 * For tips on how to test this behaviour in a real browser check out:
 * https://stackoverflow.com/a/28398154/8362805
 *
 * @param utcTime {string} The UTC time to convert as a string(i.e. server time)
 * @returns {Date}
 */
export function getLocalTime(utcTime) {
    return utcTime ? new Date(parseJSON(utcTime).getTime()) : new Date();
}

/***
 * Check if two times are from same day
 * @param time1
 * @param time2
 * @return {boolean|*}
 */
export function areSameDayTimes(time1, time2) {
    if (!time1 || !time2) {
        return false;
    }

    const time1Locale = getLocalTime(time1);
    const time2Locale = getLocalTime(time2);

    if (!isValid(time1Locale) || !isValid(time2Locale)) {
        return false;
    }

    return isSameDay(time1Locale, time2Locale);
}


/**
 * Calculates the time between the later time and earlier time, rounded down to the nearest unit
 * @param laterTime {string}
 * @param earlierTime {string|Date}
 * @returns {{value: number, units: string}}
 */
export function getTimeSince(laterTime, earlierTime) {
    const diff = intervalToDuration({start: getLocalTime(earlierTime), end: getLocalTime(laterTime)});
    let value;
    let units;

    if (diff.years > 0) {
        value = diff.years;
        units = UNITS.YEARS;
    } else if (diff.months > 0) {
        value = diff.months;
        units = UNITS.MONTHS;
    } else if (diff.days > 0) {
        value = diff.days;
        units = UNITS.DAYS;
    } else if (diff.hours > 0) {
        value = diff.hours;
        units = UNITS.HOURS;
    } else if (diff.minutes > 0) {
        value = diff.minutes;
        units = UNITS.MINUTES;
    } else {
        value = diff.seconds;
        units = UNITS.SECONDS;
    }
    return {value: Math.floor(value), units};
}

/**
 * Converts a UNIT into a short name for displaying
 * @param unit
 * @returns {string}
 */
export function getShortName(unit) {
    switch (unit) {
        case UNITS.SECONDS:
            return "s";
        case UNITS.MINUTES:
            return "m";
        case UNITS.HOURS:
            return "h";
        default:
            return "d";
    }
}

export function isInPast(date, now = new Date()) {
    return differenceInMilliseconds(date, now) < 0;
}

export function isInFuture(date, now = new Date()) {
    return differenceInMilliseconds(date, now) > 0;
}

export function isCurrentlyBetween(start, end, now = new Date()) {
    return isInPast(start, now) && isInFuture(end, now);
}

export function getTimestamp(date) {
    return getUnixTime(date);
}
