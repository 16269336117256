import React from "react";
import s from "./Layout.pcss";

export const Container = ({id, children, dataTestId}) => (
    <div id={id} data-test-id={dataTestId} className={s.container}>
        {children}
    </div>
);

export const Header = ({id, children}) => (
    <div id={id} className={s.header}>
        {children}
    </div>
);

export const SubHeader = ({id, children}) => (
    <div id={id} className={s.subHeader}>
        {children}
    </div>
);

export const Body = ({id, children}) => (
    <div id={id} className={s.body}>
        {children}
    </div>
);
