import head from "lodash/head";
import isArray from "lodash/isArray";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {Typeahead} from "../../../../Forms/components/Typeahead/Typeahead";
import s from "./Autocomplete.pcss";

class Autocomplete extends Component {
    static dynamicFields = [
        "options",
        "value",
        "placeholder",
        "disabled",
        "emptyLabel",
        "hasError",
    ];

    shouldComponentUpdate(nextProps) {
        return (
            Autocomplete.dynamicFields.filter(name => nextProps[name] !== this.props[name]).length >
            0
        );
    }

    onChange = selected => {
        const value = this.props.multiple ? selected : head(selected);
        this.props.onChange({
            target: {
                value,
                type: "autocomplete",
                id: this.props.id,
                name: this.props.name,
            },
        });
    };

    getValue() {
        if (this.props.defaultSelected !== undefined || !this.props.value) return undefined;
        const {value, multiple} = this.props;

        if (multiple || isArray(value)) {
            return value;
        }
        return [value];
    }

    render() {
        return (
            <div
                className={classNames(s.container, {
                    [s.hasError]: this.props.hasError,
                    [s.variableHeight]: this.props.multiple,
                })}
            >
                <Typeahead
                    inputProps={{
                        name: this.props.name,
                        id: this.props.id,
                        autoComplete: "off",
                    }}
                    placeholder={this.props.placeholder}
                    multiple={this.props.multiple}
                    disabled={this.props.disabled}
                    labelKey={this.props.labelKey}
                    options={this.props.options}
                    selected={this.getValue()}
                    defaultSelected={this.props.defaultSelected}
                    renderMenuItemChildren={this.props.renderMenuItemChildren}
                    emptyLabel={this.props.emptyLabel}
                    onChange={this.onChange}
                    onBlur={this.props.onBlur}
                    allowNew={this.props.allowNew}
                    newSelectionPrefix={this.props.newSelectionPrefix}
                />
            </div>
        );
    }
}

Autocomplete.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    multiple: PropTypes.bool,
    allowNew: PropTypes.bool,
    newSelectionPrefix: PropTypes.string,
    emptyLabel: PropTypes.string,
};

Autocomplete.defaultProps = {
    placeholder: "",
    labelKey: null,
    disabled: false,
    hasError: false,
    multiple: false,
    allowNew: false,
    newSelectionPrefix: "",
    value: [],
};

export default Autocomplete;
