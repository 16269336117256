import {gql} from "@apollo/client";

const marketplaceMentorDegreeCategories = gql`
    query MarketplaceMentorDegreeCategories(
        $marketplaceSlug: String
        $universityId: String
        $parentDegreeCategory: String
        $degreeCategoryRank: Int
        $degreeLevel: String
        $countryId: String
        $domesticRegion: String
        $universityCountry: String
        $universitySlug: String
    ) {
        marketplaceMentorDegreeCategories(
            marketplaceSlug: $marketplaceSlug
            universityId: $universityId
            degreeLevel: $degreeLevel
            degreeCategoryType: "hecos"
            countryId: $countryId
            domesticRegion: $domesticRegion
            degreeCategoryRank: $degreeCategoryRank
            parentDegreeCategory: $parentDegreeCategory
            universityCountry: $universityCountry
            universitySlug: $universitySlug
        ) {
            id
            name
        }
    }
`;

export default marketplaceMentorDegreeCategories;
