import React from "react";
import styled from "styled-components";
import mediaQueries from "../../utils/mediaQueries";
import spaceMap from "../../utils/spaceMap";
import alignMap from "../../utils/alignMap";
import spacePropType from "../../utils/spacePropType";
import alignPropType from "../../utils/alignPropType";
import mapResponsiveProp from "../../utils/mapResponsiveProp";
import mapRenderableChildren from "../../utils/mapRenderableChildren";

const StackWrapper = styled.div`
    display: ${({align}) => (align !== "left" ? "flex" : "block")};
    flex-direction: column;
    ${({align}) =>
        mediaQueries({
            alignItems: mapResponsiveProp(align, alignMap),
        })}
`;

/**
 * The Stack layout primitive injects margin between elements via their common parent
 * Read ./README.md for more details and example use cases.
 *
 * We use an attribute selector [data-ub-layout-stack] for higher specificity, so we
 * can easily overwrite margins for children.
 *
 * @export
 * @param {React.Props} {props}
 * @param {String} {props.align}
 * @param {String} {props.space}
 * @param {import("react").ReactElement} {props.children}
 * @returns
 */
const StackItem = styled.div`
    border-top: ${({dividers, isFirst}) =>
        isFirst === false && dividers === true ? "1px solid var(--solitude)" : "none"};

    &:not(:last-child) {
        ${({space}) =>
            mediaQueries({
                paddingBottom: mapResponsiveProp(space, spaceMap),
            })}
    }

    [data-ub-layout-stack]& > * {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

export default function Stack({children, align, space, dividers}) {
    return (
        <StackWrapper align={align} data-test-id="layout-stack-wrapper">
            {mapRenderableChildren(children, (child, index) => {
                return (
                    <StackItem
                        key={index} // eslint-disable-line
                        space={space}
                        data-ub-layout-stack
                        data-test-id="layout-stack-item"
                        isFirst={index === 0}
                        dividers={dividers}
                    >
                        {child}
                    </StackItem>
                );
            })}
        </StackWrapper>
    );
}

Stack.propTypes = {
    align: alignPropType,
    space: spacePropType,
};

Stack.defaultProps = {
    space: "none",
    align: "left",
};
