import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./Flag.pcss";

const getFlag = (position = "right", code, className = "", props = []) => {
    if (code === "gb-nir" || code === "nir") return null;
    return (
        <span
            {...props}
            data-test-id={`${position}-test-flag-id-${code}`}
            className={classNames(
                {[`flag-icon flag-icon-${code}`]: code !== "gb-nir" && code !== "nir"},
                className,
            )}
        />
    );
};

export default function Flag({
    countryCode,
    primaryFlag,
    secondaryFlag,
    className,
    position,
    ...props
}) {
    if (typeof countryCode !== "string" && !primaryFlag && !secondaryFlag) return null;
    if (primaryFlag && secondaryFlag) {
        // Dual Citizenship
        return (
            <div {...props} className={classNames(s.dualFlags, className)}>
                <div className={s.primaryFlag}>{getFlag(position, primaryFlag.toLowerCase())}</div>
                <div className={s.secondaryFlag}>
                    {getFlag(position, secondaryFlag.toLowerCase())}
                </div>
            </div>
        );
    } else if (primaryFlag) {
        // Single citizenship
        return getFlag(position, primaryFlag.toLowerCase(), className, props);
    } else if (secondaryFlag) {
        // Single citizenship
        return getFlag(position, secondaryFlag.toLowerCase(), className, props);
    }
    // Backwards compatibility
    return getFlag(position, countryCode.toLowerCase(), className, props);
}

Flag.propTypes = {
    countryCode: PropTypes.string.isRequired,
};
