import {getConfigFromWindow} from "../../tools/utils";

class ConfigurationFromWindow {
    API_URL = getConfigFromWindow("API_URL", "http://localhost:5000");
    LAUNCHDARKLY_CLIENT_ID = getConfigFromWindow("LAUNCHDARKLY_CLIENT_ID", "618a36f64edbc614fcd12e93");
    ASSETS_URL = getConfigFromWindow("ASSETS_URL", "https://images-dev.unibuddy.co");
    GATEWAY_URL = getConfigFromWindow("GATEWAY_URL", "https://gateway-qa-9.unibuddy.co");
    MIXPANEL_ID = getConfigFromWindow("MIXPANEL_ID", "27f68c73044b2fb3f950b44bbdb38ea2");
    MIXPANEL_PROXY_HOST = getConfigFromWindow("MIXPANEL_PROXY_HOST", "https://tukey.unibuddy.co")
    ENV_NAME = getConfigFromWindow("ENV_NAME")
    TRANSLATIONS_URL = getConfigFromWindow("TRANSLATIONS_URL", "https://translations-dev.unibuddy.co")
    PUSHER_APP_KEY = getConfigFromWindow("PUSHER_APP_KEY", "29543470517ba526ec5a");
    PUSHER_APP_CLUSTER = getConfigFromWindow("PUSHER_APP_CLUSTER", "eu");
    MEDIA_URL = getConfigFromWindow(
        "MEDIA_URL",
        "https://s3-eu-west-1.amazonaws.com/unibuddy-images-dev-eu-west-1/",
    );
    DEFAULT_PROFILE_PHOTO = getConfigFromWindow(
        "DEFAULT_PROFILE_PHOTO",
        "https://s3-eu-west-1.amazonaws.com/unibudy-prod/StaticAssets/default_profile.png",
    );
}

export const config = new ConfigurationFromWindow();
