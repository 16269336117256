import React, {Suspense} from "react";
import Spinner from "@unibuddy/machop/Experimental/General/components/Spinner/Spinner";
import s from "./SuspenseLoader.pcss";

export const Fallback = () => (
    <div className={s.container} data-test-id="loader">
        <Spinner className={s.spinner} />
    </div>
);

/**
 * SuspenseLoader is wrapping its children with React's Suspense component and
 * using a Spinner component as a fallback while loading.
 * Since we are using React.lazy for code splitting, it requires at least one
 * Suspense component to be rendered somewhere up in the render tree. This
 * component abstracts away adding a custom Spinner component every time
 * we use Suspense. It also adds some styling so the spinner appears
 * in the center of the space that is supposed to be occupied by
 * SuspensELoader's children.
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function SuspenseLoader(props) {
    return <Suspense fallback={<Fallback />}>{props.children}</Suspense>;
}
