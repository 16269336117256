import React from "react";
import {Field} from "formik";
import ButtonLoader from "../../../General/components/LoadingButton/LoadingButton";

const SubmitButton = React.forwardRef((props, ref) => (
    <Field>
        {({form: {isSubmitting}}) => (
            <ButtonLoader
                ref={ref}
                loading={isSubmitting}
                disabled={isSubmitting}
                type="submit"
                {...props}
            >
                {props.children}
            </ButtonLoader>
        )}
    </Field>
));

export default SubmitButton;
