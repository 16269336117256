import React from 'react';
import {useMutation} from '@apollo/client';
import {Redirect} from 'react-router';
import {logoutMutationQuery} from '@unibuddy/machop/Auth/hooks/useLogout';
import {Box, Spinner} from '@unibuddy/patron';
import {logoutFromAcvl} from '@unibuddy/ssomodule';
import {config} from '@unibuddy/machop/ConfigManager/ConfigManager';

import {useMarketplaceConfig} from '../../../MarketplaceConfigProvider';
import {useAuth} from '../AuthProvider/AuthProvider';

const LOGOUT_REDIRECT_MAP = new Map([
    ['ucas', 'https://ucas.com/chat-to-students/'],
]);

export default function Logout() {
    const {marketplace} = useMarketplaceConfig();
    const {authState, setAuthState} = useAuth();

    const [logout, {data, error}] = useMutation(logoutMutationQuery);

    React.useEffect(() => {
        if (authState) {
            if (marketplace.isSsoEnabled) {
                logoutFromAcvl(`${config.GATEWAY_URL}/acvl/logout`, {bearerToken: authState.accessToken});
            } else {
                logout();
            }
            setAuthState(undefined);
        }
    }, [logout, setAuthState, authState, marketplace.isSsoEnabled]);

    if (data || !authState) {
        if (LOGOUT_REDIRECT_MAP.has(marketplace.slug)) {
            const redirectUrl = LOGOUT_REDIRECT_MAP.get(marketplace.slug);
            parent.location.href = redirectUrl;
            return null;
        }
        return <Redirect to={`/${marketplace.slug}/buddies`} />;
    }

    if (error) {
        return <div>An error occurred on logout, please try again</div>;
    }

    return (
        <Box display="flex" height="100%" justifyContent="center" alignItems="center">
            <Spinner />
        </Box>
    );
}
