import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import {boolean} from 'yup';
import {useMutation} from '@apollo/client';
import {
    CheckBox,
    Form,
    Logo,
    Heading,
    SubmitButton,
    Box,
    TextBlock,
    Stack,
    Inline,
} from '@unibuddy/patron';
import {
    ACCEPTABLE_USE_POLICY,
    PRIVACY_POLICY,
    TERMS_OF_USE,
} from '@unibuddy/machop/Shared/links/links';
import ThemedLink from '@unibuddy/machop/Navigation/components/ThemedLink/ThemedLink';
import useErrorReporting from '@unibuddy/machop/General/components/ErrorReporting/useErrorReporting';

import {CollapsibleDiscoverPrivacyText} from '../MarketplaceTermsRequired/MarketplaceTermsRequired';
import {useAuth} from '../../../Auth/components/AuthProvider/AuthProvider';
import {useMarketplaceConfig} from '../../../MarketplaceConfigProvider';
import updateMarketplaceApplicantMutation from '../../mutations/updateMarketplaceApplicantMutation';
import {UserData} from '../../../dataModels/user';

export const validation = {
        acceptTermsAndConditions: boolean()
            .oneOf([true], 'You must accept the terms and conditions')
            .required(),
        acceptPrivacyPolicy: boolean()
            .oneOf([true], 'You must accept the privacy policy')
            .required(),
};

export const ConsentForm = props => {
    const {reportError} = useErrorReporting();
    const {authState, setAuthState} = useAuth();
    const user = get(authState, 'me.anyUser', 'Buddy');
    const {marketplace} = useMarketplaceConfig();
    const applicantMarketplaces = get(
        authState,
        'me.applicant.marketplaces',
        [],
    );
    const applicantMarketplace = find(applicantMarketplaces, {
        slug: marketplace.slug,
    });

    const [updateMarketplaceApplicant] = useMutation(
        updateMarketplaceApplicantMutation,
    );
    const handleSubmit = async () => {
        const {data} = await updateMarketplaceApplicant({
            variables: {
                marketplace: {
                    ...applicantMarketplace,
                    slug: marketplace.slug,
                    userData: new UserData({
                        ...(applicantMarketplace &&
                            applicantMarketplace.userData),
                        marketingConsentAccepted:
                            get(
                                marketplace,
                                'marketplaceMarketingConsentCollectionEnabled',
                                false,
                            ) || null,
                        personalDataCollectionAccepted:
                            get(marketplace, 'personalDataApiEnabled', false) ||
                            null,
                    }),
                },
            },
        });
        const {error, me} = data.updateMarketplaceApplicant;
        if (error) {
            reportError(error);
            return;
        }
        return setAuthState({
            ...authState,
            me,
        });
    };

    if (!applicantMarketplace) {
        return (
            <Box
                padding="medium"
                paddingTop="large"
                data-test-id="sso-consent-form"
            >
                <Heading level="1">Signup to Unibuddy</Heading>
                <Box
                    padding="medium"
                    paddingTop="xxlarge"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    minH="initial"
                >
                    <Stack align="center" space="medium">
                        <Heading level="2">One Last Step...</Heading>
                        <TextBlock>
                            Register your account with Unibuddy to start
                            chatting
                        </TextBlock>
                        <Box
                            padding="medium"
                            borderColor="solitude"
                            borderWidth={1}
                            borderRadius="xsmall"
                            w={['100%', 680]}
                        >
                            <Stack space="medium" dividers>
                                <Stack align="center" space="medium">
                                    <Logo width="150" />
                                    <Heading level="1" size="small">
                                        {`Hey ${
                                            user.firstName
                                        }, welcome to Unibuddy`}
                                    </Heading>
                                    <Box paddingLeft="large" paddingRight="large">
                                        Unibuddy is a peer support platform that helps you find the perfect university or college by speaking to students that have been in your shoes,
                                        so you can get the most authentic insights about the experience, and make the right choice for you.
                                    </Box>
                                </Stack>
                                <Stack space="medium">
                                    <Form
                                        initialValues={{
                                            acceptTermsAndConditions: false,
                                            acceptPrivacyPolicy: false,
                                        }}
                                        onSubmit={() => handleSubmit()}
                                        validationSchema={validation}
                                    >
                                        {({values: {acceptTermsAndConditions, acceptPrivacyPolicy}}) =>
                                        <React.Fragment>
                                            <CollapsibleDiscoverPrivacyText />
                                        <Box paddingTop="medium">
                                            <CheckBox name="acceptTermsAndConditions">
                                                {"I agree to Unibuddy's "}
                                                <ThemedLink
                                                    external
                                                    to={TERMS_OF_USE}
                                                    target="_blank"
                                                >
                                                    Terms and Conditions
                                                </ThemedLink>{' '}
                                                and{' '}
                                                <ThemedLink
                                                    external
                                                    to={ACCEPTABLE_USE_POLICY}
                                                    target="_blank"
                                                >
                                                    Acceptable User Policy
                                                </ThemedLink>
                                            </CheckBox>
                                            <br />
                                            <CheckBox name="acceptPrivacyPolicy">
                                                {"I agree to Unibuddy's "}
                                                <ThemedLink
                                                    external
                                                    to={PRIVACY_POLICY}
                                                    target="_blank"
                                                >
                                                    Privacy Policy
                                                </ThemedLink>{' '}
                                            </CheckBox>
                                            <br />
                                            <Inline align="center">
                                                <SubmitButton disabled={!(acceptTermsAndConditions && acceptPrivacyPolicy)} color="primary">
                                                    Continue
                                                </SubmitButton>
                                            </Inline>
                                        </Box> </React.Fragment>}
                                    </Form>
                                </Stack>
                            </Stack>
                        </Box>
                        <Box
                            padding="small"
                            display="flex"
                            minH="initial"
                            w={['100%', 680]}
                        >
                            <ThemedLink
                                external
                                to="mailto:support@unibuddy.com"
                                target="_blank"
                            >
                                Contact Unibuddy
                            </ThemedLink>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        );
    }
    return props.render();
};
