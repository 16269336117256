import {useEffect} from "react";
import {scrollSelectedInToView, scrollHighlightedInToView} from "./scroll";

export const useScrollSelectedInToView = (listRef, filter, filteredOptions) => {
    useEffect(
        () => {
            scrollSelectedInToView(listRef, filter, filteredOptions);
        },
        [listRef, filter, filteredOptions],
    );
};

export const useScrollHighlightedInToView = (listRef, realHighlighted) => {
    useEffect(
        () => {
            scrollHighlightedInToView(listRef, realHighlighted);
        },
        [listRef, realHighlighted],
    );
};

export const useFocusOnChange = (inputRef, isOpen) => {
    useEffect(
        () => {
            if (isOpen) {
                inputRef.current.focus();
            }
        },
        [inputRef, isOpen],
    );
};
