import React from "react";
import {hot} from "react-hot-loader/root";
import "whatwg-fetch";
import {BrowserRouter, Route} from "react-router-dom";
import get from "lodash/get";
import "normalize.css";
import Pusher from "pusher-js";
import ErrorBoundary from "@unibuddy/machop/General/components/ErrorBoundary/ErrorBoundary";
import ErrorReportingProvider from "@unibuddy/machop/General/components/ErrorReporting/ErrorReportingProvider";
import {
    apps,
    errorReportingAdaptor,
    initErrorReportingService,
} from "@unibuddy/machop/General/components/ErrorReporting/errorReportingAdaptor";
import {COOKIE_CATEGORIES, MIXPANEL} from "@unibuddy/machop/Utils/Constants";
import {config} from "@unibuddy/machop/ConfigManager/ConfigManager";
import mixpanel from "mixpanel-browser";
import {createFeatureFlaggingProvider, User} from "ub-feature-flagging-react";
import * as URLParamHelpers from "@unibuddy/machop/Utils/UrlUtils";
import mixpanelAdaptor from "@unibuddy/machop/AnalyticsNew/AnalyticsProvider/Adaptors/mixpanelAdaptor";
import AnalyticsValue from "@unibuddy/machop/AnalyticsNew/AnalyticsValue/AnalyticsValue";
import UniversalAnalyticsProvider from "@unibuddy/machop/AnalyticsNew/AnalyticsProvider/UniversalAnalyticsProvider";
import SourceTrackingProvider from "@unibuddy/machop/SourceTracking/SourceTrackingProvider/SourceTrackingProvider";
import {productSpec} from "@unibuddy/machop/AnalyticsNew/constants/productSpecification";
import BuddiesFiltersProvider from "@unibuddy/machop/Buddies/components/BuddiesFiltersProvider/BuddiesFiltersProvider";
import {DesignSystemProvider} from "@unibuddy/patron";
import Intl, {Constants, I18nextEngine} from "@unibuddy/intl";
import {SSOProvider} from "@unibuddy/ssomodule";
import Navigation from "./Navigation/components/Navigation/Navigation";
import AuthProvider from "./Auth/components/AuthProvider/AuthProvider";
import MessengerProvider from "./Messenger/components/MessengerProvider/MessengerProvider";
import onAuthChange from "./Auth/functions/onAuthChange";
import AppRoutes, {hideNav} from "./AppRoutes";
import ApiProvider from "./ApiProvider";
import analyticsProductSpecification from "./analyticsProductSpecification";
import "./App.css";
import MarketplaceConfigProvider, {MarketplaceConfigContext} from "./MarketplaceConfigProvider";

import FilterDependentComponent from "./shared/components/FilterDependentComponent/FilterDependentComponent";
// Initialise Sentry
initErrorReportingService({app: apps.DISCOVER});

// Initialise i18next
const options = {
    appName: Constants.APP_NAMES.DISCOVER,
    lng: URLParamHelpers.getParameterByName(window.location.href, "ub_lang"),
    translationsUrl: config.TRANSLATIONS_URL,
};

const i18nextEngine = new I18nextEngine(options);

const analyticsAdaptors = [];

export const SetMixpanel = (url, adaptor) => {
    const path = URLParamHelpers.getParameterByName(url, "ub_cookie_consent");
    if (path) {
        const preferences = path.split(" ");
        let mixpanelInit = false;
        preferences.forEach((preference) => {
            if (preference) {
                const key = preference.trim().toLowerCase();
                if (
                    !mixpanelInit &&
                    COOKIE_CATEGORIES.has(key) &&
                    COOKIE_CATEGORIES.get(key).includes(MIXPANEL)
                ) {
                    mixpanel.init(config.MIXPANEL_ID, {
                        api_host: config.MIXPANEL_PROXY_HOST,
                    });
                    adaptor.push(mixpanelAdaptor);
                    mixpanelInit = true;
                }
            }
        });
    } else {
        //Enable mixpanel anyway (regardless of user consent) for discover partners who have not implemented
        //cookie consent capture in their websites.

        mixpanel.init(config.MIXPANEL_ID, {
            api_host: config.MIXPANEL_PROXY_HOST,
        });
        adaptor.push(mixpanelAdaptor);
    }
};

const SetProduct = () => {
    return (
        <React.Fragment>
            <AnalyticsValue
                name={productSpec.discover.properties.PRODUCT}
                value={productSpec.discover.NAME}
            />
        </React.Fragment>
    );
};

const initSSO = (authState, marketplace, setAuthState) => {
    return {
        popupUrl: `/${marketplace.slug}/callback`,
        enablePopup: !authState && marketplace.isSsoEnabled,
        setAuthState,
    };
};

// Get launchdarkly user for feature flagging
function getUser() {
    const slug = window.location.pathname.split("/")[1];
    const user = new User(`marketplace_${slug}`, {slug});
    return user;
}
const FeatureFlaggingProvider = createFeatureFlaggingProvider(config.LAUNCHDARKLY_CLIENT_ID);

// eslint-disable-next-line react/prefer-stateless-function
export class AppComponent extends React.Component {
    componentWillUnmount = () => {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }
    componentWillMount = () => {
        SetMixpanel(window.location.toString(), analyticsAdaptors);
    }

    render() {
        return (
            <ErrorReportingProvider adaptor={errorReportingAdaptor}>
                <ErrorBoundary>
                    <BrowserRouter>
                        <FeatureFlaggingProvider user={getUser()}>
                            <AuthProvider onAuthChange={onAuthChange}>
                                <AuthProvider.Context.Consumer>
                                    {({authState, setAuthState}) => (
                                        <UniversalAnalyticsProvider
                                            productEvents={
                                                analyticsProductSpecification.events
                                            }
                                            adaptors={analyticsAdaptors}
                                            user={get(authState, "me.anyUser")}
                                        >
                                            <SourceTrackingProvider
                                                params={{
                                                    ub_medium: "product",
                                                    ub_source:
                                                        productSpec.discover
                                                            .NAME,
                                                }}
                                            >
                                                <ApiProvider>
                                                    <Intl
                                                        engine={i18nextEngine}
                                                        crowdinProjectName={
                                                            Constants
                                                                .CROWDIN_PROJECT_NAMES
                                                                .DISCOVER
                                                        }
                                                    >
                                                        <MessengerProvider
                                                            pusher={Pusher}
                                                        >
                                                            <BuddiesFiltersProvider>
                                                                <Route
                                                                    exact
                                                                    path="/"
                                                                    render={() => (
                                                                        <div data-test-id="title" />
                                                                    )}
                                                                />
                                                                <MarketplaceConfigProvider>
                                                                <MarketplaceConfigContext.Consumer>
                                                                    {({marketplace}) => (
                                                                    <SSOProvider options={initSSO(authState, marketplace, setAuthState)}>
                                                                        <main className="App__main">
                                                                            <SetProduct />
                                                                            <FilterDependentComponent
                                                                                filterKey="showBuddiesInCarousel"
                                                                                hideComponentWhenConditionTrue
                                                                            >
                                                                                {!hideNav() && <Navigation
                                                                                    user={get(
                                                                                        authState,
                                                                                        "me.anyUser",
                                                                                    )}
                                                                                />}
                                                                            </FilterDependentComponent>
                                                                            <DesignSystemProvider>
                                                                                    <AppRoutes />
                                                                            </DesignSystemProvider>
                                                                        </main>
                                                                    </SSOProvider>
                                                                    )}
                                                                    </MarketplaceConfigContext.Consumer>
                                                                </MarketplaceConfigProvider>
                                                            </BuddiesFiltersProvider>
                                                        </MessengerProvider>
                                                    </Intl>
                                                </ApiProvider>
                                            </SourceTrackingProvider>
                                        </UniversalAnalyticsProvider>
                                    )}
                                </AuthProvider.Context.Consumer>
                            </AuthProvider>
                        </FeatureFlaggingProvider>
                    </BrowserRouter>
                </ErrorBoundary>
            </ErrorReportingProvider>
        );
    }
}

export default hot(AppComponent);
