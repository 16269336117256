import {useEffect} from "react";

export default function useOnClickOutside(handler, excludedRefs = []) {
    useEffect(
        () => {
            const listener = event => {
                /** Do nothing if we touch an excluded ref **/
                for (const excludedRef of excludedRefs) {
                    if (excludedRef.current && excludedRef.current.contains(event.target)) {
                        return;
                    }
                }

                handler(event);
            };

            document.addEventListener("click", listener);

            return () => {
                document.removeEventListener("click", listener);
            };
        },
        [handler, excludedRefs],
    );
}
