export default {
    NAME: "Discover Widget",
    events: {
        productOpened: {
            name: "Product Opened",
            requiredFields: ["name"],
        },
        productVisible: {
            name: "Product Visible",
            requiredFields: ["name"],
        },
        prospectLoggedIn: {
            name: "Prospect Logged In",
        },
        prospectConsentAgreed: {
            name: "Prospect Discover Consent Agreed",
        },
        prospectAccountVerified: {
            name: "Prospect Account Verified",
        },
        prospectEmailChanged: {
            name: "Prospect Email Changed",
        },
        prospectRequestedVerificationCode: {
            name: "Prospect Requested Verification Code",
        },
        prospectUniversityAccepted: {
            name: "Prospect University Accepted",
            requiredFields: ["ub_slug"],
        },
        prospectReturnFromPopup: {
            name: "Prospect Returned From Popup",
        },
        prospectInteractWithIframe: {
            name: "Interact with iframe - intermediary page",
        },
        mentorCardChatNowClicked: {
            name: "Mentor Card Chat Now Clicked",
        },
    },
};
