import React from "react";
import get from "lodash/get";
import {Redirect} from "react-router";

import {Box, Spinner} from '@unibuddy/patron';
import {usePopup, useRedirectUrl} from "@unibuddy/ssomodule";
import {config} from "@unibuddy/machop/ConfigManager/ConfigManager";

import {useAuth} from "../../components/AuthProvider/AuthProvider";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";
import {SSOLandingPage} from "../../../shared/components/SSOLandingPage/SSOLandingPage";
import {createCallbackUrl} from "../../../utils";

const SSOAuth = ({match, location}) => {
    const {isLoggedIn, isVerified} = useAuth();
    const {marketplace} = useMarketplaceConfig();
    const clientId = marketplace.faAppId;
    const callbackUrl = `${window.location.origin}/${marketplace.slug}/callback`;

    const [fusionAuthUrl, setFusionAuthUrl] = React.useState(null);
    const {redirectUrl, setRedirectUrl} = useRedirectUrl();
    const {popup} = usePopup();
    // Write the redirectUrl to localStorage
    React.useState(() => {
        setRedirectUrl(get(location, "state.referrer"));
    }, []);

    // Call acvl to get the idp url
    React.useEffect(() => {
        fetch(`${config.GATEWAY_URL}/acvl/clients/${clientId}/redirect-url`)
            .then(resp => resp.json())
            .then(json => {
                let faUrl = json.redirect_url;
                faUrl = createCallbackUrl(faUrl, callbackUrl);
                setFusionAuthUrl(faUrl);
            });
    }, [clientId, callbackUrl]);

    // If the user is verified he needs to be redirected to the previous saved redirectUrl
    if (isVerified()) {
        if (redirectUrl) {
            return <Redirect to={redirectUrl} />;
        }
        return <Redirect to={`/${match.params.marketplaceSlug}/buddies`} />;
    }

    // render the intermediate page if user is trying to access the url directly.
    if (!popup && !isLoggedIn()) {
        return <SSOLandingPage/>;
    }

    // If the user is not logged in then redirect him to wherever he came from
    // (which is always buddies route)
    // and populate the popup url if the popup reference exists
    if (!isLoggedIn() && fusionAuthUrl) {
        if (popup) {
            popup.location.href = fusionAuthUrl;
        }

        return <Redirect
            to={{
                pathname: `/${match.params.marketplaceSlug}/buddies`,
            }}
        />;
    }

    /*
        * If the user is logged in and the user is not verified then he needs to be redirected to the
        * intermediate page. This here is a temporary solution.
    */
    if (isLoggedIn() && !isVerified()) {
        return <Redirect to={{pathname: `/${match.params.marketplaceSlug}/logout`}} />;
    }

    // A placeholder to return for now(need to think about this)
    return (
        <Box display="flex" height="100%" justifyContent="center" alignItems="center">
            <Spinner />
        </Box>
    );
};

export default SSOAuth;
