import React from "react";
import find from "lodash/find";
import get from "lodash/get";
import useAnalytics from "@unibuddy/machop/AnalyticsNew/hooks/useAnalytics";
import ListFilter from "../ListFilter/ListFilter";
import marketplaceMentorUniversities from "../../queries/marketplaceMentorUniversities";
import {EmptyFilter, Filter, FilterBody, FilterQuery, FilterTitle} from "../Filter/Filter";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";

const UniversityFilter = ({filters, setFilters, isOpen}) => {
    const {
        events: {buddiesFiltered},
    } = useAnalytics();
    const {marketplace} = useMarketplaceConfig();
    return (
        <Filter id="university-filter-wrapper">
            <FilterTitle id="university-filter">University</FilterTitle>
            <FilterQuery
                query={marketplaceMentorUniversities}
                variables={{
                    ...filters,
                    marketplaceSlug: marketplace.slug,
                    degreeCategoryId: filters.subCategory || filters.subject || undefined,
                }}
            >
                {({data}) => {
                    if (
                        data.marketplaceMentorUniversities &&
                        !data.marketplaceMentorUniversities.length
                    ) {
                        return (
                            <EmptyFilter>There is no university in that region yet.</EmptyFilter>
                        );
                    }
                    return (
                        <FilterBody>
                            <ListFilter
                                id="university-filter"
                                isOpen={isOpen}
                                placeholder={"Search for universities"}
                                options={data.marketplaceMentorUniversities}
                                filter={find(data.marketplaceMentorUniversities, {
                                    id: filters.universityId,
                                })}
                                setFilter={university => {
                                    if (university) {
                                        buddiesFiltered({
                                            filterKey: "university",
                                            filterValue: university.name || "",
                                            buddyType: "mentor",
                                        });
                                    }

                                    setFilters({
                                        ...filters,
                                        universityId: get(university, "id", undefined),
                                        universityName: get(university, "name", undefined),
                                    });
                                }}
                                noDropdownOnEmptyText={
                                    !filters.domesticRegion && !filters.universityCountry
                                }
                            />
                        </FilterBody>
                    );
                }}
            </FilterQuery>
        </Filter>
    );
};

export default UniversityFilter;
