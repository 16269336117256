import React, {useContext} from "react";
import {Redirect, Route} from "react-router";
import get from "lodash/get";
import {Box, Spinner} from '@unibuddy/patron';
import AuthProvider from "./Auth/components/AuthProvider/AuthProvider";
import {MarketplaceTermsRequired} from "./shared/components/MarketplaceTermsRequired/MarketplaceTermsRequired";
import {ConsentForm} from "./shared/components/ConsentForm/ConsentForm";
import {useMarketplaceConfig} from "./MarketplaceConfigProvider";

/**
 * ProtectedRoute renders nothing while authState is loading,
 * and if the user is logged out it redirects to the /marketplace/auth route group.
 * Otherwise, it renders the route that require authentication such as inbox,
 * chat and settings pages.
 */
const ProtectedRoute = ({path, component: Component}) => {
    const {isVerified} = useContext(AuthProvider.Context);
    const {loading, marketplace} = useMarketplaceConfig();

    if (loading) {
        return (
            <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                <Spinner />
            </Box>
        );
    }

    return (
        <Route
            path={path}
            render={p => {
                if (!isVerified()) {
                    return (
                        <Redirect
                            to={{
                                pathname: `/${p.match.params.marketplaceSlug}/auth`,
                                state: {referrer: get(p, "location.pathname")},
                            }}
                        />
                    );
                } else if (marketplace.isSsoEnabled) {
                    return <ConsentForm render={() => <Component {...p}/>}/>;
                }
                return <MarketplaceTermsRequired render={() => <Component {...p} />} />;
            }}
        />
    );
};

export default ProtectedRoute;
