const getEnv = (name, defaultVal) =>
    typeof process.env[name] === "undefined" || process.env[name] === ""
        ? defaultVal
        : process.env[name];

// Get configuration from window.env
const getConfigFromWindow = (name, defaultVal) =>
    typeof window.env === "undefined" || typeof window.env[name] === "undefined" || window.env[name] === ""
        ? defaultVal
        : window.env[name];

module.exports = {
    getEnv,
    getConfigFromWindow,
};
