import React from "react";
import {Route, Switch} from "react-router-dom";
import Buddies from "../Buddies/Buddies";

const BuddyProfile = React.lazy(() => import(/* webpackChunkName: "BuddyProfile" */ "../BuddyProfile/BuddyProfile"));

export default function BuddiesRoutes(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/profile/:id`} component={BuddyProfile} />
            <Route path={props.match.path} component={Buddies} />
        </Switch>
    );
}
