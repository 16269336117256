const features = {
    clipboard: () => {
        if (!navigator) return false;
        return "clipboard" in navigator;
    },

    localStorage: () => {
        try {
            const storage = window.localStorage;
            const testValue = "__storage_test__";
            storage.setItem(testValue, testValue);
            storage.removeItem(testValue);
            return true;
        } catch (e) {
            return false;
        }
    },
};

/**
 * Checks if the current environment supports a particular feature
 * @param {string} feature name of the platform feature to check
 * @returns boolean
 */
export default function isAvailable(feature) {
    if (!feature || typeof feature !== "string" || !features[feature]) return;
    return features[feature]();
}
