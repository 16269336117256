import React from "react";
import PropTypes from "prop-types";
import s from "./MentorCardName.pcss";

const MentorCardName = props => <h2 className={s.name}>{props.name}</h2>;

MentorCardName.propTypes = {
    name: PropTypes.string.isRequired,
};

export default MentorCardName;
