import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import get from "lodash/get";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import {useTranslation} from "@unibuddy/intl";
import s from "./ThemedLink.pcss";
import VisuallyHidden from "../../../Experimental/A11y/components/VisuallyHidden/VisuallyHidden";

const ThemedLink = ({external, ...props}) => {
    const className = classNames(s.link, get(props, "className", ""));
    const {t} = useTranslation("common");

    return external ? (
        <a
            {...omit(props, "external")}
            href={props.to}
            className={className}
            rel="noreferrer noopener"
            target="_blank"
        >
            {props.children}
            <VisuallyHidden>{t("opensInANewWindow", "Opens in a new window")}</VisuallyHidden>
        </a>
    ) : (
        <Link {...omit(props, "external")} to={props.to || ""} className={className}>
            {props.children}
        </Link>
    );
};

ThemedLink.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    external: PropTypes.bool,
};

ThemedLink.defaultProps = {
    external: false,
};

export default ThemedLink;
