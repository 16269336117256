import React from "react";
import DynamicImage from "@unibuddy/machop/General/components/DynamicImage/DynamicImage";

const DynamicUniLogo = ({imageUrl, ...props}) => {
    let src;
    if (typeof imageUrl === "string") {
        const urlParts = imageUrl.split("/");
        src = urlParts[urlParts.length - 1];
    }
    return <DynamicImage src={src} {...props} />;
};

export default DynamicUniLogo;
