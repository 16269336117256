// / action types

export const MERGE_AUTH_ME = "MERGE_AUTH_ME";
export const REPLACE_AUTH_ME = "REPLACE_AUTH_ME";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_USER_TRACKING_ID = "SET_USER_TRACKING_ID";
export const CLEAR_AUTH = "CLEAR_AUTH";
export const SET_INITIAL_UNI_AUTH_DATA = "SET_INITIAL_UNI_AUTH_DATA";

// / action creators

export function setToken(token) {
    return {
        type: SET_AUTH_TOKEN,
        token,
    };
}

export function setInitialUniversityAuthData(token, me) {
    return {
        type: SET_INITIAL_UNI_AUTH_DATA,
        token,
        me,
    };
}

export function setUserTrackingId(userTrackingId) {
    return {
        type: SET_USER_TRACKING_ID,
        userTrackingId,
    };
}

export function setMe(me) {
    return {
        type: MERGE_AUTH_ME,
        me,
    };
}

export function replaceMe(me) {
    return {
        type: REPLACE_AUTH_ME,
        me,
    };
}

export function clearAuth() {
    return {
        type: CLEAR_AUTH,
    };
}
