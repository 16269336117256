import React from "react";
import get from "lodash/get";
import {Link} from "react-router-dom";
import classNames from "classnames";
import styled from "styled-components";
import {useTranslation} from "@unibuddy/intl";
import ButtonLink from "@unibuddy/machop/Experimental/General/components/ButtonLink/ButtonLink";
import Avatar from "@unibuddy/machop/Experimental/General/components/Avatar/Avatar";
import {getMentorDynamicImageSrc} from "@unibuddy/machop/Mentor/components/MentorDynamicImage/MentorDynamicImage";
import DynamicUniLogo from "@unibuddy/machop/Buddies/components/DynamicUniLogo/DynamicUniLogo";
import Flag from "@unibuddy/machop/Buddies/components/Flag/Flag";
import Stack from "../../../Experimental/Layout/components/Stack/Stack";
import TextBlock from "../../../Experimental/Typography/components/TextBlock/TextBlock";
import s from "./BuddyCard.pcss";

export default function BuddyCard({
    children,
    className,
    elevation,
    left = null,
    right = null,
    bodyClassName = "",
    useWidgetCardStyling = false,
}) {
    const customClassNames = {};
    if (elevation !== undefined) {
        customClassNames[s[`elevation${elevation}`]] = true;
    }

    return (
        <div data-test-id="buddy-card" className={classNames(s.card, className, customClassNames)}>
            <BuddyCard.Header useWidgetCardStyling={useWidgetCardStyling}>
                <div>{left}</div>
                <div>{right}</div>
            </BuddyCard.Header>
            <div className={classNames(s.body, bodyClassName)}>{children}</div>
        </div>
    );
}

const Header = styled.div`
    align-items: center;
    border-radius: ${({useWidgetCardStyling}) =>
        useWidgetCardStyling ? "6px 6px 0 0" : "4px 4px 0 0"};
    background-color: var(--theme-primary);
    color: var(--solitude);
    display: flex;
    height: ${({useWidgetCardStyling}) => (useWidgetCardStyling ? "3rem" : "2rem")};
    flex-shrink: 0;
    justify-content: space-between;
    margin-top: -1px;
    margin-left: -1px;
    margin-right: -1px;
    padding: 0.5rem 1rem;
    width: calc(100% + 2px);
`;

BuddyCard.Header = ({useWidgetCardStyling, children}) => {
    return <Header useWidgetCardStyling={useWidgetCardStyling}>{children}</Header>;
};

BuddyCard.Avatar = ({mentor}) => {
    const secFlag = get(mentor, "mentorFields.secondaryFlag.code", "");
    return (
        <div className={s.avatarContainer}>
            {mentor.country && secFlag ? (
                <Flag
                    aria-label={`Country of origin ${mentor.country.name}`}
                    className={classNames(s.flag, s.leftFlag)}
                    countryCode={mentor.country.code}
                    primaryFlag={get(mentor, "mentorFields.primaryFlag.code", "")}
                    position={"left"}
                />
            ) : null}
            <Avatar
                className={s.avatar}
                src={getMentorDynamicImageSrc(mentor.profilePhoto)}
                alt=""
                dynamic
                width={118}
            />
            {mentor.country ? (
                <Flag
                    aria-label={`Country of origin ${mentor.country.name}`}
                    className={s.flag}
                    countryCode={mentor.country.code}
                    primaryFlag={!secFlag && get(mentor, "mentorFields.primaryFlag.code", "")}
                    secondaryFlag={get(mentor, "mentorFields.secondaryFlag.code", "")}
                />
            ) : null}
        </div>
    );
};

BuddyCard.Name = props => {
    return (
        <h2 title={props.children} className={s.name}>
            {props.children}
        </h2>
    );
};

BuddyCard.CTA = props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {t} = useTranslation("mentorCard");
    return (
        <ButtonLink
            data-test-id="buddy-card-chat-with"
            to={props.to}
            color="light"
            round
            className={classNames(s.cta, props.className)}
            onClick={props.onClick}
        >
            {t("chatWith", `Chat with ${props.mentor.firstName}`, {
                            name: props.mentor.firstName,
                        })}
        </ButtonLink>
    );
};

BuddyCard.Section = props => {
    return (
        <div className={s.section}>
            <h3 className={s.sectionTitle}>{props.title}</h3>
            <p className={s.sectionBody}>{props.children}</p>
        </div>
    );
};

BuddyCard.ReadMore = props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {t} = useTranslation("mentorCard");
    return (
        <Link
            to={props.to}
            className={classNames(s.readMore, props.className)}
        >
            {t("readMore", `Read more about ${props.mentor.firstName}`, {
                            name: props.mentor.firstName,
                        })}
        </Link>
    );
};

BuddyCard.Separator = () => {
    return <hr className={s.separator} />;
};

BuddyCard.Break = () => {
    return <br />;
};

BuddyCard.Split = props => {
    return <div className={s.split}>{props.children}</div>;
};

BuddyCard.Left = props => {
    return <div className={s.left}>{props.children}</div>;
};

BuddyCard.Right = props => {
    return <div className={s.right}>{props.children}</div>;
};

BuddyCard.Degree = props => {
    const name = get(props, "mentor.degree.name", "");
    const hasLongName = name.length > 35;
    return (
        <div className={s.degree} title={hasLongName ? name : undefined}>
            {name && (hasLongName ? `${name.substring(0, 35)}...` : name)}
        </div>
    );
};

BuddyCard.Subtitle = ({subtitle}) => {
    const hasLongName = subtitle.length > 35;
    return (
        <div className={s.subtitle} title={hasLongName ? subtitle : undefined}>
            {subtitle && (hasLongName ? `${subtitle.substring(0, 35)}...` : subtitle)}
        </div>
    );
};

BuddyCard.Logo = ({mentor}) => {
    return (
        <DynamicUniLogo
            alt={mentor.university.name}
            className={s.logo}
            imageUrl={mentor.university.logo}
            options={{height: 60}}
            height={60}
        />
    );
};

BuddyCard.FullContent = ({children}) => {
    return <Stack space="large">{children}</Stack>;
};

const IconContainer = styled.span`
    display: inline-block;
    font-size: 22px;
    margin-right: 5px;
`;

BuddyCard.SmallSection = ({title, children}) => {
    return (
        <Stack space="small">
            <TextBlock transform="uppercase" tone="secondary" size="small">
                {title}
            </TextBlock>
            <TextBlock>{children}</TextBlock>
        </Stack>
    );
};

BuddyCard.LargeSection = ({title, icon, children}) => {
    return (
        <Stack space="medium">
            <TextBlock color="primary" transform="uppercase" weight="600">
                <IconContainer>{icon}</IconContainer> {title}
            </TextBlock>
            {children}
        </Stack>
    );
};

BuddyCard.joinByName = (arr, joinSymbol = ", ") => arr.map(item => item.name).join(joinSymbol);
