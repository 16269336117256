import React, {useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import classNames from "classnames";
import {config} from "../../../../ConfigManager/ConfigManager";
import DynamicImage from "../../../../General/components/DynamicImage/DynamicImage";
import s from "./Avatar.pcss";

export const sizes = {
    xs: 30,
    sm: 40,
    md: 45,
    lg: 65,
    xl: 80,
};

const Placeholder = styled.div`
    display: block;
    font-size: 0;
    width: ${({size}) => `${size}px`};
    height: ${({size}) => `${size}px`};
    background-color: var(--deep-solitude);
    border-radius: 50%;
`;

function getAvatarDynamicOptions(width, dynamic) {
    return dynamic === true
        ? {width, height: width, smart: true}
        : {width, height: width, smart: true, ...dynamic};
}
/**
 * Renders a user avatar. It always maintains aspect ratio of 1x1
 * since that is a requirement for a round border,
 * which this component has by default.
 *
 * @export
 * @param {*} {src, alt, size, width, dynamic}
 * @returns
 */
export default function Avatar({src, alt, size, width, dynamic, className, ...props}) {
    const [hasError, setHasError] = useState(false);
    const source = src || config.DEFAULT_PROFILE_PHOTO;
    const sizePX = sizes[size] !== undefined ? sizes[size] : sizes.md;
    const classNameMerged = classNames(s.image, className);
    const onError = () => {
        setHasError(true);
    };
    const finalSize = width !== null ? width : sizePX;
    const sharedProps = {
        ...props,
        alt,
        src: source,
        className: classNameMerged,
        width: finalSize,
        height: finalSize,
        style: {
            width: `${finalSize}px`,
            height: `${finalSize}px`,
            minHeight: `${finalSize}px`,
            minWidth: `${finalSize}px`,
            maxHeight: `${finalSize}px`,
            maxWidth: `${finalSize}px`,
        },
    };

    if (hasError) {
        return <Placeholder size={finalSize} className={className} />;
    }

    if (dynamic === false) {
        // eslint-disable-next-line jsx-a11y/img-has-alt
        return <img {...sharedProps} />;
    }

    return (
        <DynamicImage
            {...sharedProps}
            onError={onError}
            options={getAvatarDynamicOptions(width !== null ? width : sizePX, dynamic)}
        />
    );
}

Avatar.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    size: PropTypes.oneOf(Object.keys(sizes)),
    width: PropTypes.number,
    dynamic: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

Avatar.defaultProps = {
    size: "md",
    width: null,
    dynamic: false,
};
