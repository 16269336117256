import React from "react";
import {
    Typeahead as RBTypeahead,
    AsyncTypeahead as RBAsyncTypeahead,
} from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./tokenizer.pcss";

export const Typeahead = props => <RBTypeahead {...props} />;

export const AsyncTypeahead = props => <RBAsyncTypeahead {...props} />;
