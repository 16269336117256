import {useQuery} from "@apollo/client";
import React, {forwardRef} from "react";
import classNames from "classnames";
import ChevronUp from "react-icons/lib/io/chevron-up";
import ChevronDown from "react-icons/lib/io/chevron-down";
import VisuallyHidden from "@unibuddy/machop/Experimental/A11y/components/VisuallyHidden/VisuallyHidden";
import Button from "@unibuddy/machop/Experimental/General/components/Button/Button";
import Spinner from "@unibuddy/machop/Experimental/General/components/Spinner/Spinner";
import s from "./Filter.pcss";

const Badge = ({children, label}) => {
    return (
        <span className={s.badge} aria-hidden={!children}>
            <VisuallyHidden>{label}</VisuallyHidden> {children}
        </span>
    );
};

export const FilterButton = forwardRef(
    ({activeFilter, hasFilters, value, name, setFilter, ...props}, ref) => {
        const isOpen = activeFilter === value;
        const numberOfActiveFilters = hasFilters.filter(Boolean).length;

        return (
            <Button
                {...props}
                className={classNames(s.filterButton, {
                    [s.isUsed]: !!numberOfActiveFilters,
                    [s.isBtnOpened]: isOpen,
                })}
                ref={ref}
                onClick={() => (isOpen ? setFilter(null) : setFilter(value))}
            >
                {numberOfActiveFilters > 0 ? (
                    <Badge label="Number of active filters">{numberOfActiveFilters}</Badge>
                ) : null}
                {name} <span className={s.btnIcon}>{isOpen ? <ChevronUp /> : <ChevronDown />}</span>
            </Button>
        );
    },
);

export const Filter = ({id, children, className, as}) =>
    React.createElement(
        as,
        {
            className: classNames(s.filter, className),
            "data-test-id": id,
        },
        children,
    );

Filter.defaultProps = {
    as: "div",
};

export const FilterTitle = ({id, children, className, as}) =>
    React.createElement(
        as,
        {
            htmlFor: id,
            "data-test-id": "filter-title",
            className: classNames(s.filterTitle, className),
        },
        children,
    );

FilterTitle.defaultProps = {
    as: "label",
};

export const FilterBody = ({children, className}) => (
    <div className={classNames(s.filterBody, className)}>{children}</div>
);

export const FilterQuery = ({query, variables, children}) => {
    const {loading, error, data} = useQuery(query, {
        variables,
    });

    if (loading) {
        return (
            <EmptyFilter data-test-id="buddies-list-loading" aria-label="Loading">
                <Spinner color="#E4E8EB" aria-hidden />
            </EmptyFilter>
        );
    }
    if (error) {
        console.error(error);
        return (
            <EmptyFilter data-test-id="buddies-list-loading">
                Error fetching filters
            </EmptyFilter>
        );
    }

    return children({data});
};

export const EmptyFilter = ({children, ...props}) => (
    <div className={s.emptyList} {...props}>
        <div className={s.emptyText}>{children}</div>
    </div>
);
