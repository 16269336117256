/**
 * This is the single source of truth for analytics events
 * Each event is assigned an object consisting of two properties
 * @property {string} name - the name of the analytics event
 * @property {[strings]} requiredFields- the name of the properties that must be specified
 * when recording the analytics event
 */

export const sharedEvents = {
    productOpened: {
        name: "Product Opened",
        requiredFields: ["name", "universitySlug"],
    },
    productLeft: {
        name: "Product Left",
        requiredFields: ["name", "universitySlug"],
    },
    // Business events
    buddyViewed: {
        name: "Buddy Viewed",
        requiredFields: ["id"],
    },
    buddiesListViewed: {
        name: "Buddies List Viewed",
    },
    buddiesListViewedMore: {
        name: "Buddies List Viewed More",
        requiredFields: ["pageNumber"],
    },
    buddiesFiltered: {
        name: "Buddies Filtered",
        requiredFields: ["filterKey", "filterValue"],
    },
    buddyShared: {
        name: "Buddy Shared",
        requiredFields: ["id"],
    },
    blogViewed: {
        name: "Blog Viewed",
        requiredFields: ["id"],
    },
    blogShared: {
        name: "Blog Shared",
        requiredFields: ["id"],
    },
    conversationStarted: {
        name: "Conversation Started",
    },
    messageSent: {
        name: "Message Sent",
        requiredFields: ["conversationId", "opponentId"],
    },
    accountCreated: {
        name: "Prospect Account Created",
        requiredFields: ["accountRole"],
    },
    prospectSignedUp: {
        name: "Prospect Signed Up",
    },
    //QSS Events
    qssReceived: {
        name: "Qss Received",
        requiredFields: ["accountRole"],
    },
    qssDismissed: {
        name: "Qss Closed",
        requiredFields: ["accountRole"],
    },
    qssOpened: {
        name: "Qss Opened",
        requiredFields: ["accountRole"],
    },
    qssItemSelected: {
        name: "Qss Item Selected",
        requiredFields: ["rank", "questionText", "topic", "accountRole"],
    },
    qssReminderShown: {
        name: "Qss Reminder Shown",
        requiredFields: ["accountRole"],
    },
    // UI Events
    mentorCardChatNowClicked: {
        name: "Mentor Card Chat Now Clicked",
        requiredFields: ["buddyId", "feature"],
    },
    mentorCardSeeMoreStudentsClicked: {
        name: "Mentor Card See More Students Clicked",
        requiredFields: ["feature"],
    },
    feedbackFormSubmitted: {
        name: "Feedback Form Submitted",
        requiredFields: ["accountRole", "eventRating", "hasComments"],
    },
    feedbackFormSkipped: {
        name: "Feedback Form Skipped",
        requiredFields: ["accountRole"],
    },
    itemClicked: {
        name: `Item Clicked`,
        requiredFields: ["prefix"],
    },
    pageViewed: {
        name: "Page Viewed",
        requiredFields: ["prefix"],
    },
    prospectAccountVerified: {
        name: "Prospect Account Verified",
        requiredFields: [],
    },
    uniSwitcherOpened: {
        name: "UniSwitcher Opened",
        requiredFields: ["product", "originalUni"],
    },
    uniSwitcherUniSelected: {
        name: "UniSwitcher Uni Selected",
        requiredFields: ["product", "originalUni", "universitySlug"],
    },
    ageSignUpPageViewed: {
        name: "Age Sign Up Page Viewed",
        requiredFields: ["product", "universitySlug"],
    },
    continueToSignUpClickedOnAgePage: {
        name: "Continue To Sign Up  Button Clicked On Age Page",
        requiredFields: ["product", "universitySlug", "ageSuccess"],
    },
    loginClickedOnAgePage: {
        name: "Login Button Clicked On Age Page",
        requiredFields: ["product", "universitySlug"],
    },
    adminIntervention: {
        name: "Admin Intervention",
        requiredFields: ["conversationId", "messageCode"],
    },
};

export const itemNames = {
    buddyProfileChatNow: "Buddy Profile Chat Now",
    buddyCardChatNow: "Buddy Card Chat Now",
    chatRules: "Chat Rules",
};

export const pageNames = {
    buddiesList: "Buddies List",
    blogList: "Blog List",
    prospectSignUpStageOne: "Prospect Signup Stage 1",
    startChat: "Start Chat",
};

/**
 * Unibuddy Products
 * Embedded University Widget, Carousel, University Buddy Cards, External Buddy Cards
 * Social Feed, PWA University Widget, Live Events, Popcard, Ucas, Chat Bubble,
 * Engagement Portal
 */
export const productSpec = {
    freshersApp: {
        NAME: "Freshers App",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    ambassadorApp: {
        NAME: "Ambassador App",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    ambassadorDashboard: {
        NAME: "Ambassador Dashboard",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedUniversityWidget: {
        NAME: "Embedded University Widget",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    pwaUniversityWidget: {
        NAME: "PWA University Widget",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedUniversityCarousel: {
        NAME: "Embedded University Carousel",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedUniversityBuddyCards: {
        NAME: "Embedded University Buddy Cards",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedExternalBuddyCards: {
        NAME: "Embedded External Buddy Cards",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedExternalBuddyCardsCarousel: {
        NAME: "Embedded External Buddy Cards Carousel",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedUniversitySocialFeed: {
        NAME: "Embedded University Social Feed",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    liveEvents: {
        NAME: "Live Events",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
            LIVE_EVENT_SLUG: "liveEventSlug",
        },
        events: {
            messageSent: {
                name: "Message Sent",
            },
        },
    },
    engagementPortal: {
        NAME: "Engagement Portal",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    openDay: {
        NAME: "Open Day",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    ucas: {
        NAME: "UCAS Widget",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    discover: {
        NAME: "Discover Widget",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            DISCOVER_SLUG: "discoverSlug",
            PRODUCT: "product",
        },
    },
    universityPopcard: {
        NAME: "University Popcard",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    universityDashboard: {
        NAME: "University Dashboard",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    chatBubble: {
        NAME: "Chat Bubble",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
};

/**
 * Unibuddy Source tracking params
 * ub_medium, ub_source, ub_campaign, ub_content
 */
export const sourceTracking = {
    sourceTrackingParams: {
        NAME: "sourceTrackingParams",
        properties: {
            UB_MEDIUM: "ub_medium",
            UB_SOURCE: "ub_source",
            UB_CAMPAIGN: "ub_campaign",
            UB_CONTENT: "ub_content",
        },
    },
};
