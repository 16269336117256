const spaceMap = {
    none: "0px",
    gutter: "24px",
    xxsmall: "4px",
    xsmall: "8px",
    small: "12px",
    medium: "20px",
    large: "32px",
    xlarge: "48px",
    xxlarge: "96px",
};

export default spaceMap;
