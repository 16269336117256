import React from "react";
import s from "./RequiredFormIndicator.pcss";

const RequiredFormIndicator = () => (
    <span aria-hidden="true" className={s.required} data-test-id="required-indicator">
        *
    </span>
);

export default RequiredFormIndicator;
