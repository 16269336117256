import React from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import omit from "lodash/omit";
import classNames from "classnames";
import inputStyles from "../TextInput/TextInput.pcss";
import s from "./Select.pcss";

const Select = React.forwardRef(
    ({className, hasError, children, formatValue, inline, ...props}, ref) => (
        <select
            {...omit(props, ["setFieldValue"])}
            className={classNames(
                inputStyles.input,
                s.select,
                {
                    [inputStyles.error]: hasError,
                    [s.placeholderVisible]: props.placeholder && !props.value,
                    [inputStyles.inline]: inline,
                },
                className,
            )}
            onChange={event => {
                props.onChange(formatValue(event));
            }}
            ref={ref}
        >
            {props.placeholder && <option value="">{props.placeholder}</option>}
            {children}
        </select>
    ),
);

Select.propTypes = {
    hasError: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    formatValue: PropTypes.func,
    inline: PropTypes.bool,
};

Select.defaultProps = {
    formatValue: event => event,
    hasError: false,
    onChange: noop,
    className: "",
    inline: false,
};

export default Select;
