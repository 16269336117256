import React from "react";
import {useTranslation} from "@unibuddy/intl";
import PropTypes from "prop-types";
import {datePropTypes, getShortName, getTimeSince, UNITS} from "../../../Utils/DateUtils";

const LastSeen = props => {
    const {t} = useTranslation("mentorCard");

    const {date, now, className} = props;
    if (date) {
        const {value, units} = getTimeSince(date, now || new Date());
        if (units === UNITS.DAYS) {
            return null;
        }
        let lastSeenText;
        if (value > 1)
            lastSeenText = t(
                `${units.toLowerCase()}Active`,
                `Active ${value}${getShortName(units)} ago`,
                {time: value},
            );
        else
            lastSeenText = t(
                `${units.toLowerCase()}ActiveSingular`,
                `Active ${value}${getShortName(units)} ago`,
                {time: value},
            );

        return <span className={className}>{lastSeenText}</span>;
    }
    return null;
};

LastSeen.propTypes = {
    date: datePropTypes,
    now: datePropTypes,
    className: PropTypes.string,
};

LastSeen.defaultProps = {
    now: undefined,
    date: undefined,
    className: "",
};

export default LastSeen;
