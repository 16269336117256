import React from "react";
import {setMonth, endOfMonth, addYears, isBefore, format} from "date-fns";
import Select from "../../../Experimental/Forms/components/Select/Select";

const YEARS_OFFSETS = [0, 1, 2, 3, 4, 5, 6];

export const dateOfEntryMap = yearOffset => month =>
 addYears(endOfMonth(setMonth(new Date(), month - 1)), yearOffset);

const dateOptionMap = date => (
    <option key={format(date, "yyyy-MM")} value={format(date, "yyyy-MM")}>
        {format(date, "MMMM yyyy")}
    </option>
);

const yearOptionMap = yearOffset => (
    <option key={yearOffset} value={format(addYears(new Date(), yearOffset), "yyyy")}>
        {format(addYears(new Date(), yearOffset), "yyyy")}
    </option>
);

export const yearOptions = () => YEARS_OFFSETS.map(yearOptionMap);

export const selectOptions = (entryMonths, showOnlyYears) => {
    if (showOnlyYears) {
        return yearOptions();
    }

    return YEARS_OFFSETS.map(yearOffset => {
        /* we calculate before not to displayRW
            * empty groups
            */
        const hybridOptions = entryMonths
            .map(dateOfEntryMap(yearOffset))
            .filter(date => isBefore(new Date(), date))
            .map(dateOptionMap);
        return (
            !!hybridOptions.length && (
                <optgroup
                    key={yearOffset}
                    label={
                        format(addYears(new Date(), yearOffset), "yyyy")}
                >
                    {hybridOptions}
                </optgroup>
            )
        );
    });
};

const DateOfEntrySelect = ({entryMonths, isUcas, ...props}) => {
    return <Select {...props}>{selectOptions(entryMonths, isUcas)}</Select>;
};

export default DateOfEntrySelect;
