export const LIST_ITEM_HEIGHT = 48;

export const getScrollTopWhenItemIsAbove = index => index * LIST_ITEM_HEIGHT;
export const getScrollTopWhenItemIsBelow = (index, listHeight) =>
    (index + 1) * LIST_ITEM_HEIGHT - listHeight;

export function scrollSelectedInToView(listRef, filter, filteredOptions) {
    if (listRef && listRef.current && filter) {
        const index = filteredOptions.findIndex(option => {
            if (filter.id === option.id) {
                return true;
            }
            return false;
        });
        scrollInToView(index, listRef);
    }
}

export function scrollHighlightedInToView(listRef, realHighlighted) {
    if (listRef && listRef.current && realHighlighted > -1) {
        const index = realHighlighted;
        scrollInToView(index, listRef);
    }
}

function scrollInToView(index, listRef) {
    if (index > -1) {
        const scrollTop = listRef.current.scrollTop;
        const listHeight = listRef.current.clientHeight;
        const notInViewTop = scrollTop > index * LIST_ITEM_HEIGHT;
        const notInViewBottom = listHeight + scrollTop < (index + 1) * LIST_ITEM_HEIGHT;
        if (notInViewTop) {
            listRef.current.scrollTop = getScrollTopWhenItemIsAbove(index);
        } else if (notInViewBottom) {
            listRef.current.scrollTop = getScrollTopWhenItemIsBelow(index, listHeight);
        }
    }
}
