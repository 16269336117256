import React from 'react';
import {useLocation} from 'react-router';
import {useQuery} from '@apollo/client';

import {Box, Spinner} from '@unibuddy/patron';
import meQuery from '@unibuddy/machop/Auth/queries/meQuery';
import {config} from '@unibuddy/machop/ConfigManager/ConfigManager';
import useAnalytics from '@unibuddy/machop/AnalyticsNew/hooks/useAnalytics';


import {useAuth} from "../AuthProvider/AuthProvider";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";

function useUrlQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SSOCallBack = () => {
    const query = useUrlQuery();
    const code = query.get('code');
    const error = query.get('error');
    const {authState, setAuthState} = useAuth();
    const {marketplace} = useMarketplaceConfig();
    const callbackUrl = `${window.location.origin}/${marketplace.slug}/callback`;
    const {data: meData} = useQuery(meQuery);

    const {
        events: {prospectReturnFromPopup},
    } = useAnalytics();

    // We got an error code, send the error to the parent iframe
    React.useEffect(() => {
        if (error) {
            window.opener.postMessage(JSON.stringify({error}));
            window.close();
        }
    }, [error]);

    // We get the FA-token, have to call ACVL to get user token
    React.useEffect(() => {
        if (code && marketplace.faAppId && !(authState && authState.accessToken)) {
            const urlencoded = new URLSearchParams();
            urlencoded.append("code", code);
            urlencoded.append("client_id", marketplace.faAppId);
            urlencoded.append("redirect_url", callbackUrl);
            urlencoded.append("context_type", "marketplace");
            urlencoded.append("context_id", marketplace.id);

            fetch(`${config.GATEWAY_URL}/acvl/auth-code`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                },
                body: urlencoded,
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.token) {
                        // We need to set the scheme as this is needed to differentiate
                        // the SSO and non-SSO flow in ApiProvider
                        setAuthState({
                            accessToken: json.token,
                            scheme: json.scheme,
                        });
                    }
                });
        }
    }, [marketplace.faAppId, code, callbackUrl, marketplace.id, setAuthState, authState]);

    // Call the analytics event if the user is coming back from
    // an Identity Provider
    // TODO: this needs to be called as a prop(to make sso generic)
    React.useEffect(() => {
        if (error || code) {
            prospectReturnFromPopup();
        }
    }, [error, code, prospectReturnFromPopup]);

    // If the authState exists(which means acvl returned with token),
    // then call meQuery and set it in authState
    React.useEffect(() => {
        if (authState && !authState.me && meData) {
            window.opener.postMessage(JSON.stringify({
                ...authState,
                ...meData,
            }));
            window.close();
        }
    }, [authState, meData]);

    // When the ACVL layer comes back with a logged in user(ie, after the login)
    // we should write his details on to the localStorage and close the screen(popup)

    return (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
            <Spinner />
        </Box>
    );
};

export default SSOCallBack;
