import {useContext} from "react";
import {itemNames, pageNames} from "../constants/productSpecification";
import AnalyticsContext from "../AnalyticsProvider/AnalyticsContext";

export default function useAnalytics() {
    const context = useContext(AnalyticsContext);
    const analytics = {
        setUser: context.setUser,
        unsetUser: context.unsetUser,
        events: context.events,
        trackEvent: context.trackEvent,
        itemNames,
        pageNames,
        aliasVisitor: context.aliasVisitor,
        setAnalyticsValue: context.setAnalyticsValue,
    };
    return analytics;
}
