import React, {useRef} from "react";
import classNames from "classnames";
import CloseIcon from "@unibuddy/machop/Experimental/Icons/CloseIcon";
import CheckMarkIcon from "react-icons/lib/io/ios-checkmark-empty";
import Button from "@unibuddy/machop/Experimental/General/components/Button/Button";
import useFocusTrap from "@unibuddy/machop/Experimental/hooks/useFocusTrap";
import s from "./FilterContainer.pcss";

const FilterContainer = ({
    children,
    className,
    isOpen,
    mobileTitle,
    onDismiss,
    onReset,
    resetKeys,
}) => {
    const containerRef = useRef(null);
    const closeRef = useRef(null);

    useFocusTrap({enabled: isOpen, element: containerRef, fallbackFocus: closeRef});

    if (!isOpen) return null;

    return (
        <div
            ref={containerRef}
            className={classNames(s.filterContainer, className, {
                [s.isModalOpen]: isOpen,
            })}
        >
            <Button
                aria-label="Close filter"
                ref={closeRef}
                onClick={() => {
                    onReset(resetKeys);
                    onDismiss();
                }}
                className={s.closeBtn}
                iconOnly
                round
                color="light"
            >
                <CloseIcon aria-hidden />
            </Button>
            <h2 className={s.mobileTitle}>{mobileTitle}</h2>
            <div className={s.body}>{children}</div>
            <Button
                aria-label="Apply filter"
                onClick={onDismiss}
                className={s.applyBtn}
                iconOnly
                round
                color="light"
            >
                <CheckMarkIcon aria-hidden />
            </Button>
        </div>
    );
};

export default FilterContainer;
