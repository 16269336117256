import React, {Component} from "react";
import PropTypes from "prop-types";
import compose from "lodash/flowRight";
import {connect, Field as FormikField} from "formik";
import {withForm} from "../Form/Form";
import FormField from "../FormField/FormField";
import LabelText from "../LabelText/LabelText";

class FormGroup extends Component {
    static propTypes = {
        label: PropTypes.node.isRequired,
        id: PropTypes.string,
        children: PropTypes.element.isRequired,
        onChange: PropTypes.func,
    };

    componentDidMount() {
        if (this.props.validation) {
            this.props.setFieldOverride(this.props.children.props.name, {
                schema: this.props.validation,
            });
        }

        if (this.props.initialValue !== undefined) {
            setTimeout(() => {
                this.props.formik.setFieldValue(
                    this.props.children.props.name,
                    this.props.initialValue,
                );
            }, 1);
        }
    }

    componentWillUnmount() {
        if (this.props.validation) {
            this.props.removeFieldOverride(this.props.children.props.name);
        }
    }

    render() {
        const input = this.props.children;
        return (
            <FormikField name={input.props.name}>
                {({field, form}) => {
                    const error = form.errors[field.name];
                    const touched = !!form.touched[field.name];
                    const showError = touched && error;
                    const onBlur = e => {
                        if (input.props.onBlur) input.props.onBlur(e);
                        if (field.onBlur) {
                            field.onBlur(e);
                        }
                    };
                    const onFocus = e => {
                        if (input.props.onFocus) input.props.onFocus(e);
                        if (field.onFocus) {
                            field.onFocus(e);
                        }
                    };
                    return (
                        <FormField
                            label={
                                <LabelText error={error} touched={touched} name={field.name}>
                                    {this.props.label}
                                </LabelText>
                            }
                            id={this.props.id}
                            className={this.props.className}
                            style={this.props.style}
                            required={input.props.required}
                            inline={this.props.inline}
                        >
                            {React.cloneElement(input, {
                                hasError: !!showError,
                                ...field,
                                onBlur,
                                onFocus,
                                setFieldValue: form.setFieldValue,
                                onChange:
                                    typeof this.props.onChange === "function"
                                        ? event => {
                                              field.onChange(event);
                                              this.props.onChange(event);
                                          }
                                        : field.onChange,
                            })}
                        </FormField>
                    );
                }}
            </FormikField>
        );
    }
}

export default compose(
    withForm,
    connect,
)(FormGroup);
