import {gql} from "@apollo/client";

const marketplaceUniversityCountries = gql`
    query MarketplaceUniversityCountries(
        $marketplaceSlug: String
        $universityId: String
        $degreeCategoryId: String
        $degreeLevel: String
        $countryId: String
    ) {
        marketplaceUniversityCountries(
            marketplaceSlug: $marketplaceSlug
            degreeCategoryId: $degreeCategoryId
            universityId: $universityId
            degreeLevel: $degreeLevel
            countryId: $countryId
        ) {
            id
            name
        }
    }
`;

export default marketplaceUniversityCountries;
