import get from "lodash/get";
import {config} from "@unibuddy/machop/ConfigManager/ConfigManager";

class Messenger {
    static status = {
        INITIALIZED: "initialized",
        FAILED: "failed",
        OFFLINE: "unavailable",
        CONNECTED: "connected",
    };

    static channelEvents = {
        SUBSCRIPTION_SUCCESS: "pusher:subscription_succeeded",
        SUBSCRIPTION_FAILED: "pusher:subscription_error",
    };

    static errorCodes = {
        LIMIT_EXCEEDED: 4004,
    };

    constructor(token, Pusher) {
        if (!token) {
            const msg = "Token missing";
            this.onError(msg);
            throw new Error(msg);
        }

        this.pusher = new Pusher(config.PUSHER_APP_KEY, {
            cluster: config.PUSHER_APP_CLUSTER,
            auth: {headers: {Authorization: `JWT ${token}`}},
            authEndpoint: `${config.API_URL}/pusher/auth`,
            encrypted: true,
        });

        this.connectionState = this.pusher.connection.state;

        this.pusher.connection.bind("error", error => {
            if (get(error, "error.data.code") === Messenger.errorCodes.LIMIT_EXCEEDED) {
                // TODO: Report error
                this.onError(">>> detected limit error");
            } else {
                this.onError(error);
            }
        });
    }

    onStatusChange(cb) {
        this.pusher.connection.bind("state_change", states => {
            // Expected shape of states: {previous: 'oldState', current: 'newState'}
            this.setConnectionState(states.current);
            cb(states);
        });
    }

    isConnected() {
        return this.connectionState === Messenger.status.CONNECTED;
    }

    subscribe = async chatGroupId => {
        // eslint-disable-next-line consistent-return
        return new Promise((resolve, reject) => {
            const channel = this.subscribeSync(chatGroupId);

            channel.bind(Messenger.channelEvents.SUBSCRIPTION_FAILED, status => {
                if (status === 408 || status === 503) {
                    // retry
                    resolve(this.subscribe(`private-${chatGroupId}`));
                } else {
                    reject(channel);
                }
            });

            channel.bind(Messenger.channelEvents.SUBSCRIPTION_SUCCESS, () => {
                resolve(channel);
            });
        });
    };

    subscribeSync(channelName) {
        if (!this.isConnected()) {
            const msg = "Connection to Pusher has to be established first!";
            this.onError(msg);
            throw new Error(msg);
        }

        // check if we have a subscription to this chat group
        const channels = get(this.pusher, "channels.channels");
        const subscribed = get(channels, `${channelName}.subscribed`, false);

        if (subscribed) {
            this.onError("Already subscribed");
            return get(channels, channelName);
        }

        const channel = this.pusher.subscribe(channelName);

        if (!channel) {
            const msg = "Problem calling subscribe on channel";
            this.onError(msg);
            throw new Error(msg);
        }

        return channel;
    }

    listenChatGroup = (chatGroupId, callback) => {
        const channel = this.subscribeSync(`private-${chatGroupId}`);
        return this.listenChannel(channel, "ChatMessage", callback);
    };

    listenChannel(channel, messageName, callback) {
        try {
            channel.bind(messageName, data => {
                callback(data);
            });

            return () => {
                channel.unbind(messageName);
            };
        } catch (err) {
            // TODO: report error
            console.log("caught error binding to channel", err);
        }
    }

    disconnect() {
        this.pusher.disconnect();
    }

    setConnectionState(state) {
        this.connectionState = state;
    }

    onError(error) {
        // TODO: Report error
        console.log(error);
    }
}

export default Messenger;
