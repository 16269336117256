import React from "react";
import find from "lodash/find";
import get from "lodash/get";
import {toTitleCase} from "@unibuddy/machop/Utils/StringUtils";
import useAnalytics from "@unibuddy/machop/AnalyticsNew/hooks/useAnalytics";
import ListFilter from "../ListFilter/ListFilter";
import marketplaceMentorDegreeCategories from "../../queries/marketplaceMentorDegreeCategories";
import {Filter, FilterBody, FilterQuery, FilterTitle} from "../Filter/Filter";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";

const SubjectFilter = ({filters, setFilters, isOpen}) => {
    const {
        events: {buddiesFiltered},
    } = useAnalytics();
    const {marketplace} = useMarketplaceConfig();
    return (
        <Filter id="subject-filter-wrapper">
            <FilterTitle id="subject-filter">Subject</FilterTitle>
            <FilterQuery
                query={marketplaceMentorDegreeCategories}
                variables={{
                    ...filters,
                    universitySlug: filters.showBuddiesFrom,
                    marketplaceSlug: marketplace.slug,
                    degreeCategoryRank: 3,
                }}
            >
                {({data}) => {
                    return (
                        <FilterBody>
                            <ListFilter
                                id="subject-filter"
                                isOpen={isOpen}
                                placeholder={"Choose a subject"}
                                options={data.marketplaceMentorDegreeCategories.map(x => ({
                                    ...x,
                                    name: toTitleCase(x.name),
                                }))}
                                filter={find(data.marketplaceMentorDegreeCategories, {
                                    id: filters.subject,
                                })}
                                setFilter={degreeCategory => {
                                    if (degreeCategory) {
                                        buddiesFiltered({
                                            filterKey: "subject",
                                            filterValue: degreeCategory.name,
                                            buddyType: "mentor",
                                        });
                                    }
                                    setFilters({
                                        ...filters,
                                        subject: get(degreeCategory, "id", undefined),
                                        subjectName: get(degreeCategory, "name", undefined),
                                        subCategory: undefined,
                                    });
                                }}
                            />
                        </FilterBody>
                    );
                }}
            </FilterQuery>
        </Filter>
    );
};

export default SubjectFilter;
