import React, {useRef, useEffect} from "react";
import styled from "styled-components";
import noop from "lodash/noop";
import classNames from "classnames";
import PropTypes from "prop-types";
import useFocusVisible from "../../../Utils/components/FocusVisibleManager/useFocusVisible";
import s from "./Button.pcss";

// some specific numbers that don't clash with other use cases
const PRESSED_OPACITY = "0.46";

const RightIconHolder = styled.span`
    display: inline-block;
    margin-left: 6px;
`;

const Button = React.forwardRef(
    (
        {
            children,
            color,
            block,
            full,
            round,
            iconOnly,
            elevation,
            link,
            size,
            clear,
            ghost,
            rightIcon,
            onFocus: onFocusProp,
            onBlur: onBlurProp,
            ...props
        },
        ref,
    ) => {
        const localRef = useRef();
        const {focusVisible, onFocus, onBlur} = useFocusVisible();
        const onPointerDown = () => {
            if (
                (!props.disabled && !localRef.current.style.opacity) ||
                localRef.current.style.opacity === "1"
            ) {
                localRef.current.style.opacity = PRESSED_OPACITY;
            }
        };
        useEffect(() => {
            const onPointerUp = () => {
                if (localRef.current.style.opacity === PRESSED_OPACITY) {
                    localRef.current.style.opacity = null;
                }
            };
            document.addEventListener("pointerup", onPointerUp);

            return () => {
                document.removeEventListener("pointerup", onPointerUp);
            };
        }, []);

        return (
            <button
                onPointerDown={onPointerDown}
                role="button"
                ref={r => {
                    localRef.current = r;
                    if (ref !== null && typeof ref === "object") {
                        ref.current = r;
                    } else if (typeof ref === "function") {
                        ref(r);
                    }
                }}
                {...props}
                onFocus={(...args) => {
                    onFocus(...args);
                    if (onFocusProp) {
                        onFocusProp(...args);
                    }
                }}
                onBlur={(...args) => {
                    onBlur(...args);
                    if (onBlurProp) {
                        onBlurProp(...args);
                    }
                }}
                className={classNames(s.button, props.className, {
                    [s.hasFocus]: focusVisible,
                    [s[color]]: true,
                    [s[size]]: true,
                    [s.block]: block,
                    [s.full]: full,
                    [s.clear]: clear || ghost,
                    [s.ghost]: ghost,
                    [s.round]: round,
                    [s.iconOnly]: iconOnly,
                    [s.elevation1]: elevation === 1,
                    [s.link]: link,
                })}
            >
                {children}
                {rightIcon && <RightIconHolder>{rightIcon}</RightIconHolder>}
            </button>
        );
    },
);

Button.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.oneOf(["default", "success", "primary", "danger", "light"]),
    size: PropTypes.oneOf(["md", "sm", "lg"]),
    round: PropTypes.bool,
    iconOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    link: PropTypes.bool,
    onClick: PropTypes.func,
    elevation: PropTypes.number,
    className: PropTypes.string,
};

Button.defaultProps = {
    id: null,
    type: "button",
    color: "default",
    size: "md",
    disabled: false,
    round: false,
    iconOnly: false,
    style: null,
    link: false,
    onClick: noop,
    elevation: 0,
    className: "",
};

export default Button;
