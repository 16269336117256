import matchSorter from "match-sorter";
import findIndex from "lodash/findIndex";

const reducer = (state, action) => {
    switch (action.type) {
        case "initialise":
            return getInitialState(action);
        case "setTouched":
            return {
                ...state,
                touched: action.payload,
            };
        case "deselectFilter":
            return {
                ...state,
                text: "",
                touched: false,
                filteredOptions: state.options,
            };
        case "selectFilter":
            return {
                ...state,
                text: action.payload ? action.payload.name : "",
                touched: false,
            };

        case "setHighlighted":
            return {
                ...state,
                highlighted: action.payload,
                realHighlighted:
                    action.payload > state.filteredOptions.length - 1 ? -1 : action.payload,
            };

        case "setText":
            return getSetTextState(state, action);

        default:
            return null;
    }
};

export function getInitialState(action) {
    const highlighted = action.payload.filter
        ? findIndex(action.payload.options, action.payload.filter)
        : -1;
    return {
        options: action.payload.options,
        text: action.payload.filter ? action.payload.filter.name : "",
        highlighted,
        touched: false,
        filteredOptions: action.payload.options,
        realHighlighted: highlighted,
    };
}

function getSetTextState(state, action) {
    const filteredOptions =
        state.touched && !state.filter
            ? filterOptions(state.options, action.payload)
            : state.options;
    return {
        ...state,
        realHighlighted: state.highlighted > filteredOptions.length ? -1 : state.highlighted,
        touched: true,
        text: action.payload,
        filteredOptions,
    };
}

export function filterOptions(options, text) {
    return matchSorter(options, text, {keys: ["name"], threshold: matchSorter.rankings.CONTAINS});
}

export default reducer;
