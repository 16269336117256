import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import colours from "../../../../Styling/colours";
import s from "./Spinner.pcss";

const Spinner = ({className, size, color, ...props}) => (
    <svg
        className={classNames(s.spinner, className)}
        width={size}
        height={size}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            className={s.path}
            stroke={colours.smartGrey}
            style={{stroke: color || "var(--smart-grey)"}}
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
        />
    </svg>
);

Spinner.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
};

Spinner.defaultProps = {
    className: "",
    size: 65,
};

export default Spinner;
