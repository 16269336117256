import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./ButtonGroup.pcss";

const ButtonGroup = ({children, className, round, justified, ...props}) => {
    const count = React.Children.count(children);

    return (
        <div
            className={classNames(s.container, s.elevation1, className, {
                [s.round]: round,
                [s.justified]: justified,
            })}
            {...props}
        >
            {justified
                ? React.Children.map(children, child =>
                      child
                          ? React.cloneElement(child, {
                                style: {
                                    flex: `1 0 ${100 / count}%`,
                                },
                                round,
                            })
                          : null,
                  )
                : children}
        </div>
    );
};

ButtonGroup.propTypes = {
    round: PropTypes.bool,
    justified: PropTypes.bool,
};

ButtonGroup.defaultProps = {
    round: false,
    justified: false,
};

export default ButtonGroup;
