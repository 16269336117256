import {gql} from "@apollo/client";

const marketplaceMentorCountries = gql`
    query MarketplaceMentorCountries(
        $marketplaceSlug: String
        $domesticRegion: String
        $universityId: String
        $degreeCategoryId: String
        $degreeLevel: String
        $universityCountry: String
        $universitySlug: String
    ) {
        marketplaceMentorCountries(
            marketplaceSlug: $marketplaceSlug
            domesticRegion: $domesticRegion
            degreeCategoryId: $degreeCategoryId
            universityId: $universityId
            degreeLevel: $degreeLevel
            universityCountry: $universityCountry
            universitySlug: $universitySlug
        ) {
            id
            name
        }
    }
`;

export default marketplaceMentorCountries;
