import * as Sentry from "@sentry/browser";
import {config} from "../../../ConfigManager/ConfigManager";

const regex = /(headless|jsdom)/gi;

/**
 * isHeadless checks for the presence of the 'headless' or 'jsdom' in the navigator.userAgent
 * so we can prevent sending errors in the test environment.
 * @returns {Boolean}
 */
const isHeadless = () =>
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.search &&
    navigator.userAgent.search(regex) !== -1;

const validateLevel = level => {
    // Valid levels are defined by Sentry so must be one of the following:
    const validLevels = ["fatal", "error", "warning", "info", "debug"];
    if (!validLevels.includes(level)) {
        console.warn(
            "errorReportingAdaptor: Argument data.level must only be one of 'fatal', 'error', 'warning', 'info' or 'debug'. Setting level to 'error' (default).",
        );
        level = "error";
    }
    return level;
};

export const getEnvName = env => {
    try {
        const envName = env || config.ENV_NAME;
        return envName ? envName.toUpperCase() : "DEV";
    } catch (e) {
        return "DEV";
    }
};

export const apps = {
    AMBASSADOR_DASHBOARD: "AMBASSADOR_DASHBOARD",
    LIVE_EVENTS: "LIVE_EVENTS",
    MOBILE_APP: "MOBILE_APP",
    TRAFFIC_DRIVERS: "TRAFFIC_DRIVERS",
    UCAS: "UCAS",
    DISCOVER: "DISCOVER",
    UNIVERSITY_DASHBOARD: "UNIVERSITY_DASHBOARD",
    WIDGETS_AND_PWA: "WIDGETS_AND_PWA",
};

// An array of strings or regexps that'll be used to ignore specific errors based on their type/message
// https://github.com/getsentry/sentry-javascript/blob/ab7ba810a97a2acae3dbd2c82b07e3972147bb97/packages/browser/examples/app.js#L38
const ignoreErrors = ["password", "AbortError: The operation was aborted."];

export const initErrorReportingService = ({app, env, customTags = {}}) => {
    if (process.env.NODE_ENV === "development") {
        return;
    }

    const SENTRY_DSN = {
        AMBASSADOR_DASHBOARD: "https://b673fc9126464f51965d6984791ef68c@sentry.io/2409445",
        LIVE_EVENTS: "https://e6ca1154083445c7b4457cf2e20fddec@sentry.io/2409456",
        MOBILE_APP: "https://e06b845555844cf6bfd2309e2a416333@sentry.io/2409512",
        TRAFFIC_DRIVERS: "https://8bbc3a01d1e84b9289c72c4b9adebdc5@sentry.io/2408916",
        UCAS: "https://524cb21aad01433eaca461b3680362bd@sentry.io/1873691",
        DISCOVER: "https://d7c2d6f6f4184db48a25adb3e76b2bc1@o338736.ingest.sentry.io/5274624",
        UNIVERSITY_DASHBOARD: "https://fe8a48648553493e80fccd5471085d3f@sentry.io/1877240",
        WIDGETS_AND_PWA: "https://5767558458fd41f2bd61f59eb83a0e52@sentry.io/1881111",
        DEFAULT: "https://mockdsn@domain/123",
    };

    /**
     * The config object passed to Sentry.init for react native MUST have a dsn property.
     * For Sentry browser, it can be omitted entirely.
     * In either case, when provided the dsn MUST be a valid format (even if its not a working dsn).
     * To prevent sending errors to Sentry when running tests we don't want to use a real dsn,
     * so we set the default to be a mock one.
     */
    const sentryConfig = {
        ignoreErrors,
        dsn: SENTRY_DSN.DEFAULT,
        environment: getEnvName(env),
        release: `${app}_${process.env.CIRCLE_SHA1}`,
    };

    if (!isHeadless()) {
        sentryConfig.dsn = SENTRY_DSN[app];
    }

    Sentry.init(sentryConfig);
    Sentry.setTags(customTags);
};

export const errorReportingAdaptor = {
    captureException: (error, {uuid = "", tags = {}, level = "error"}) => {
        const _level = validateLevel(level);
        try {
            Sentry.withScope(scope => {
                scope.setTags(tags);
                scope.setLevel(_level);
                scope.setUser({id: uuid});
                Sentry.captureException(error);
            });
        } catch (e) {
            console.error(e);
        }
    },
    captureMessage: (message, level) => {
        try {
            Sentry.captureMessage(message, level);
        } catch (e) {
            console.error(e);
        }
    },
};

export const configureRequestContext = requestId => {
    Sentry.configureScope(scope => {
        scope.setTag("request_id", requestId);
    });
};

export const configureSentryTags = tags => {
    Sentry.configureScope(scope => {
        scope.setTags(tags);
    });
};
