import get from "lodash/get";

export const ON_AUTH_CHANGE = "onAuthChange";

const onAuthChange = cb => {
    window.addEventListener("message", e => {
        if (get(e, "data.name") !== ON_AUTH_CHANGE) return;
        cb(e.data.data);
    });
    return () => window.removeEventListener("message", cb);
};

export default onAuthChange;
