import React from "react";
import find from "lodash/find";
import get from "lodash/get";
import {toTitleCase} from "@unibuddy/machop/Utils/StringUtils";
import useAnalytics from "@unibuddy/machop/AnalyticsNew/hooks/useAnalytics";
import ArrowRight from "@unibuddy/machop/Experimental/Icons/ArrowRight";
import ListFilter from "../ListFilter/ListFilter";
import marketplaceUniversityCountries from "../../queries/marketplaceUniversityCountries";
import {Filter, FilterBody, FilterQuery, FilterTitle} from "../Filter/Filter";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";
import s from "./UniversityCountryFilter.pcss";

const UniversityCountryFilter = ({filters, setFilters}) => {
    const {
        events: {buddiesFiltered},
    } = useAnalytics();
    const {marketplace} = useMarketplaceConfig();
    return (
        <Filter id="uni-country-filter-wrapper">
            <FilterTitle id="country-filter" className={s.title}>
                Country
                <ArrowRight className={s.arrow} />
                <span className={s.region}>Region</span>
            </FilterTitle>
            <FilterQuery
                query={marketplaceUniversityCountries}
                variables={{
                    ...filters,
                    marketplaceSlug: marketplace.slug,
                    degreeCategoryId: filters.subCategory || filters.subject || undefined,
                }}
            >
                {({data}) => {
                    return (
                        <FilterBody>
                            <ListFilter
                                id="country-filter"
                                placeholder={"Choose a country"}
                                options={data.marketplaceUniversityCountries.map(x => ({
                                    ...x,
                                    name: toTitleCase(x.name),
                                }))}
                                filter={find(data.marketplaceUniversityCountries, {
                                    id: filters.universityCountry,
                                })}
                                setFilter={country => {
                                    if (country) {
                                        buddiesFiltered({
                                            filterKey: "universityCountry",
                                            filterValue: country.name,
                                            buddyType: "mentor",
                                        });
                                    }
                                    setFilters({
                                        ...filters,
                                        degreeCategoryId:
                                            filters.subCategory || filters.subject || undefined,
                                        universityCountry: get(country, "id", undefined),
                                        universityCountryName: get(country, "name", undefined),
                                    });
                                }}
                            />
                        </FilterBody>
                    );
                }}
            </FilterQuery>
        </Filter>
    );
};

export default UniversityCountryFilter;
