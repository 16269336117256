import {useApolloClient, gql} from "@apollo/client";
import {useEffect, useState, useCallback} from "react";

export const logoutMutationQuery = gql`
    mutation LogoutMutation {
        logout {
            success
        }
    }
`;

export const useLazyLogout = clearLocalAuthFunction => {
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const logout = useCallback(
        async () => {
            try {
                setLoading(true);
                await client.mutate({mutation: logoutMutationQuery});
            } catch (e) {
                setError(e);
            } finally {
                if (clearLocalAuthFunction) {
                    await clearLocalAuthFunction();
                }
                await client.resetStore();
                setIsLoggedOut(true);
                setLoading(false);
            }
        },
        [client, clearLocalAuthFunction],
    );
    return {error, isLoggedOut, logout, loading};
};

const useLogout = clearLocalAuthFunction => {
    const {error, isLoggedOut, loading, logout} = useLazyLogout(clearLocalAuthFunction);
    useEffect(
        () => {
            logout();
        },
        [logout],
    );
    return {error, isLoggedOut, loading};
};

export default useLogout;
