import React from "react";
import styled from "styled-components";
import mediaQueries from "../../utils/mediaQueries";
import spaceMap from "../../utils/spaceMap";
import spacePropType from "../../utils/spacePropType";
import mapResponsiveProp from "../../utils/mapResponsiveProp";
import colorMap from "../../utils/colorMap";
import BackgroundColorProvider from "../../../Utils/components/BackgroundColorProvider/BackgroundColorProvider";

/**
 * The Box layout primitive adds padding around its children.
 * Read ../../README.md for more details and example use cases.
 *
 * @export
 * @param {React.Props} {props}
 * @param {String} {props.align}
 * @param {String} {props.space}
 * @param {import("react").ReactElement} {props.children}
 * @returns
 */
const Container = styled.div`
    ${({padding}) =>
        mediaQueries({
            padding: mapResponsiveProp(padding, spaceMap),
        })}
    ${({paddingX}) =>
        mediaQueries({
            paddingLeft: mapResponsiveProp(paddingX, spaceMap),
            paddingRight: mapResponsiveProp(paddingX, spaceMap),
        })}
    ${({paddingY}) =>
        mediaQueries({
            paddingTop: mapResponsiveProp(paddingY, spaceMap),
            paddingBottom: mapResponsiveProp(paddingY, spaceMap),
        })}
    ${({paddingTop, paddingBottom, paddingLeft, paddingRight, borderRadius, backgroundColor}) =>
        mediaQueries({
            paddingTop: mapResponsiveProp(paddingTop, spaceMap),
            paddingBottom: mapResponsiveProp(paddingBottom, spaceMap),
            paddingLeft: mapResponsiveProp(paddingLeft, spaceMap),
            paddingRight: mapResponsiveProp(paddingRight, spaceMap),
            borderRadius: mapResponsiveProp(borderRadius, spaceMap),
            backgroundColor: colorMap[backgroundColor] || backgroundColor,
        })}
`;

export default function Box({
    children,
    padding,
    paddingX,
    paddingY,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    borderRadius,
    backgroundColor,
    ...props
}) {
    return (
        <BackgroundColorProvider background={backgroundColor}>
            <Container
                padding={padding}
                paddingX={paddingX}
                paddingY={paddingY}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                backgroundColor={backgroundColor}
                borderRadius={borderRadius}
                data-test-id={props["data-test-id"]}
                className={props.className}
            >
                {children}
            </Container>
        </BackgroundColorProvider>
    );
}

Box.propTypes = {
    padding: spacePropType,
    paddingX: spacePropType,
    paddingY: spacePropType,
    paddingTop: spacePropType,
    paddingBottom: spacePropType,
    paddingLeft: spacePropType,
    paddingRight: spacePropType,
};
