import React from "react";
import PoweredByLogo from "@unibuddy/machop/Experimental/General/components/PoweredByLogo/PoweredByLogo";
import s from "./PoweredByUnibuddy.pcss";

const PoweredByUnibuddy = () => (
    <a
        href="https://unibuddy.com"
        aria-label="Powered by Unibuddy"
        target="_blank"
        rel="noopener noreferrer"
        className={s.poweredByUnibuddy}
    >
        <PoweredByLogo />
    </a>
);

export default PoweredByUnibuddy;
