import {gql} from "@apollo/client";

const marketplaceMentorUniversities = gql`
    query MarketplaceMentorUniversities(
        $marketplaceSlug: String
        $degreeCategoryId: String
        $degreeLevel: String
        $countryId: String
        $domesticRegion: String
        $universityCountry: String
    ) {
        marketplaceMentorUniversities(
            marketplaceSlug: $marketplaceSlug
            degreeCategoryId: $degreeCategoryId
            degreeLevel: $degreeLevel
            countryId: $countryId
            domesticRegion: $domesticRegion
            universityCountry: $universityCountry
        ) {
            id
            name
        }
    }
`;

export default marketplaceMentorUniversities;
