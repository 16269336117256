import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import s from "./PillButton.pcss";

const PillButton = ({invert, large, className, children, ...props}) => (
    <button
        {...props}
        className={classNames(
            s.button,
            {
                [s.invert]: invert,
                [s.large]: large,
            },
            className,
        )}
    >
        {children}
    </button>
);

PillButton.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    invert: PropTypes.bool,
};

PillButton.defaultProps = {
    className: "",
    invert: false,
};

export default PillButton;
