import React from "react";
import classNames from "classnames";
import {useBuddiesFilters} from "@unibuddy/machop/Buddies/components/BuddiesFiltersProvider/BuddiesFiltersProvider";
import "./Nav.css";

export default function Nav(props) {
    const {filters} = useBuddiesFilters();
    const isCarousel = filters.showBuddiesInCarousel === "true";
    return (
        <nav className={classNames({Navigation: true, NavigationCarousel: isCarousel})}>
            {props.children}
        </nav>
    );
}
