import {useQuery, NetworkStatus} from "@apollo/client";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import get from "lodash/get";
import LoadingButton from "@unibuddy/machop/Experimental/General/components/LoadingButton/LoadingButton";
import useAnalytics from "@unibuddy/machop/AnalyticsNew/hooks/useAnalytics";
import {
    useBuddiesFilters,
    useFetchNamesForFilterIds,
    useSyncUrlOnce,
} from "@unibuddy/machop/Buddies/components/BuddiesFiltersProvider/BuddiesFiltersProvider";
import {
    Body,
    Container,
    Header,
} from "@unibuddy/machop/Register/components/VerifyLayout/Layout";
import MentorCarousel from "@unibuddy/machop/Widgets/MentorCarousel/components/MentorCarousel/MentorCarousel";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import {useWindowTitle} from "../../../utils";
import buddiesQuery from "../../queries/buddiesQuery";
import BuddyListCard from "../BuddyListCard/BuddyListCard";
import BuddiesFilterSection from "../BuddiesFilterSection/BuddiesFilterSection";
import s from "./Buddies.pcss";
import useScrollWindowToTop from "../../../shared/functions/useScrollWindowToTop";
import {useMarketplaceConfig} from "../../../MarketplaceConfigProvider";
import FilterDependentComponent from "../../../shared/components/FilterDependentComponent/FilterDependentComponent";
import PoweredByUnibuddy from "../../../shared/components/PoweredByUnibuddy/PoweredByUnibuddy";
import FilterIndicator from "../FilterIndicator/FilterIndicator";

// import {LOCAL_STORAGE_KEYS} from "../../../constants";

const BuddyCardContainer = ({isCarousel, ...props}) => {
    return (
        <li
            {...props}
            className={classNames({
                [s.cardContainer]: true,
                [s.carouselCardContainer]: isCarousel,
            })}
        >
            {props.children}
        </li>
    );
};

const TopBar = (props) => {
    return (
        <div className={s.topBar} data-test-id={"filter-top-bar"}>
            {props.children}
        </div>
    );
};

const BuddiesList = ({limit, filters, buddiesListViewedMore}) => {
    const {marketplace} = useMarketplaceConfig();
    const [skip, setSkip] = useState(0);
    const isCarousel = filters.showBuddiesInCarousel === "true";
    const featureIsEnabled = useBooleanFeatureFlag(
        "discover-no-ambassador-message",
        false,
    );

    const variables = {
        skip: 0,
        limit,
        ...filters,
        universitySlug: filters.showBuddiesFrom,
        marketplaceSlug: marketplace.slug,
        degreeCategoryId: filters.subCategory || filters.subject || undefined,
    };
    const {loading, error, data, fetchMore, networkStatus} = useQuery(
        buddiesQuery,
        {
            variables,
            notifyOnNetworkStatusChange: true,
        },
    );

    if (loading && networkStatus !== NetworkStatus.fetchMore) {
        return (
            <div
                className={s.emptyContainer}
                data-test-id="buddies-list-loading"
            >
                Loading...
            </div>
        );
    }

    if (error) {
        console.log(error);
        return "Error";
    }

    if (!data.marketplaceMentorList.mentors.length) {
        if (featureIsEnabled) {
            window.parent.postMessage(
                {
                    type: "onResize",
                    height: 0,
                },
                "*",
            );
        }

        return (
            <div className={s.emptyContainer}>
                <div className={s.emptyContent}>
                    Sorry, no ambassador is matching your filters.
                </div>
            </div>
        );
    }

    const hasMore =
        networkStatus === NetworkStatus.fetchMore ||
        !(data.marketplaceMentorList.mentors.length < skip + limit);
    const seeMore = () => {
        setSkip(data.marketplaceMentorList.mentors.length);
        buddiesListViewedMore({
            pageNumber: Math.ceil(
                data.marketplaceMentorList.mentors.length / limit,
            ),
        });
        fetchMore({
            variables: {
                ...filters,
                mentorSeed: data.marketplaceMentorList.mentorSeed,
                universitySeed: data.marketplaceMentorList.universitySeed,
                skip: data.marketplaceMentorList.mentors.length,
            },
        });
    };
    return (
        <FilterDependentComponent
            filterKey="showBuddiesInCarousel"
            hideComponentWhenConditionTrue
            alternative={
                <div>
                    <FilterIndicator
                        filters={filters}
                        totalMentors={get(
                            data,
                            "marketplaceMentorList.totalMentors",
                            "",
                        )}
                    />
                    <MentorCarousel
                        largeScreenWidth={"1280px"}
                        loading={loading}
                        onSeeMore={seeMore}
                        showSeeMore={hasMore}
                        mentors={data.marketplaceMentorList.mentors}
                        renderMentorCard={(mentor) => (
                            <BuddyCardContainer
                                isCarousel={isCarousel}
                                data-test-id="buddy-card-container"
                                key={mentor.id}
                            >
                                <BuddyListCard mentor={mentor} />
                            </BuddyCardContainer>
                        )}
                    />
                </div>
            }
        >
            <ul className={s.list} data-test-id="buddies-list">
                {data.marketplaceMentorList.mentors.map((mentor, index) => {
                    return (
                        <BuddyCardContainer
                            data-test-id="buddy-card-container"
                            key={mentor.id}
                        >
                            <BuddyListCard
                                mentor={mentor}
                                position={index + 1}
                            />
                        </BuddyCardContainer>
                    );
                })}
            </ul>
            <div className={s.loadMoreButton}>
                <LoadingButton
                    block
                    size="lg"
                    data-test-id="load-more-buddies"
                    loading={networkStatus === NetworkStatus.fetchMore}
                    className={classNames({[s.invisible]: !hasMore})}
                    aria-hidden={!hasMore}
                    onClick={seeMore}
                >
                    See more
                </LoadingButton>
            </div>
        </FilterDependentComponent>
    );
};

export default function Buddies({limit}) {
    const {marketplace} = useMarketplaceConfig();
    const title = get(marketplace, "widgetTitle", "Chat to uni students");
    const description = get(
        marketplace,
        "widgetDescription",
        "Choose a uni, degree subject or select by their home country, and find out what life’s really like at uni",
    );
    const filterLabels = get(marketplace, "customFilters", []);
    useWindowTitle(title);
    useScrollWindowToTop();
    const {filters, setFilters} = useBuddiesFilters();
    useSyncUrlOnce(filters);
    useFetchNamesForFilterIds(filters, setFilters);

    const {
        events: {buddiesListViewed, buddiesListViewedMore},
    } = useAnalytics();
    useEffect(() => {
        buddiesListViewed();
    }, [buddiesListViewed]);

    return (
        <Container id="buddies-page" dataTestId={"buddies-page"}>
            <FilterDependentComponent
                filterKey="showBuddiesInCarousel"
                hideComponentWhenConditionTrue
            >
                <Header>
                    <div>
                        <h1 data-test-id="title" className={s.title}>
                            {title}
                        </h1>
                        <p data-test-id="description" className={s.brag}>
                            {description}
                        </p>
                        <div>
                            <PoweredByUnibuddy />
                        </div>
                    </div>
                </Header>
            </FilterDependentComponent>

            <Body>
                {filters.hideFilters ||
                (filters.hideDegreeFilter &&
                    filters.hideInstitutionFilter &&
                    filters.hideRegionFilter) ? null : (
                    <TopBar>
                        <BuddiesFilterSection
                            filters={filters}
                            setFilters={setFilters}
                            labels={filterLabels}
                        />
                    </TopBar>
                )}

                <BuddiesList
                    limit={limit}
                    filters={filters}
                    buddiesListViewedMore={buddiesListViewedMore}
                />
            </Body>
        </Container>
    );
}

Buddies.propTypes = {
    limit: PropTypes.number,
};

Buddies.defaultProps = {
    limit: 18,
};
