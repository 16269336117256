import {gql} from "@apollo/client";

const filerNameQuery = gql`
query FilterNameQuery($degreeCategoryId: String, $countryId: String, $slug: String, $includeCountry: Boolean!, $includeUniversity: Boolean!, $includeDegreeCategory: Boolean!) {
    degreeCategory(id: $degreeCategoryId) @include(if: $includeDegreeCategory) {
      name
    }
    country(id: $countryId) @include(if: $includeCountry) {
      name
    }
    university(slug: $slug) @include(if: $includeUniversity){
      name
    }
  }
`;

export default filerNameQuery;
